import * as React from 'react';
import { Button } from 'react-bootstrap';

interface ButtonWithFeedbackProps {
    className: string
    text: string;
    onPress: () => Promise<any>
}
let disable = false
const ButtonWithFeedback = ({ className, text, onPress }: ButtonWithFeedbackProps) => {
    const onClick = async () => {
        if (!disable) {
            disable = true
            try {
                await onPress()
            }
            finally {
                disable = false
            }
        }
    }
    const style = disable ? styles.disable : {}
    return React.useMemo(() => (
        <Button disabled={disable} className={`bt ${className}`} style={style} onClick={onClick}>
            { disable && <div className="spinner-border" role="status" style={{ marginRight: 10, width: '1rem', height: '1rem' }} />}
            {text}
        </Button>
    ), [disable]
    );
};

export default ButtonWithFeedback;

const styles = {
    disable: {
        backgroundColor: 'lightgrey'
    }
}
