import * as React from 'react';
import { Dispatch } from 'redux';
import RemoteActions from '../../remote/remote-actions';
import { getQueryVariable } from '../../utils/utils';
import { Redirect } from 'react-router-dom';


interface ResetPasswordScreenProps {

}

const styles = {
    form: {
        padding: 22,
    },
    login: {
        marginLeft: 'auto',
        marginRight: 'auto',
    }
}

const ResetPasswordScreen = (props: ResetPasswordScreenProps) => {
    const [data, setData] = React.useState<any>({ email: '', password: '', password2: '', token: '' })
    const [loading, setLoading] = React.useState<Boolean>(false)
    const [error, setError] = React.useState<string | null>(null)
    const [passReset, setPassReset] = React.useState<Boolean>(false)

    React.useEffect(() => {
        const g = getQueryVariable('g')
        const email = getQueryVariable('email')
        setData({ ...data, token: g, email })
    }, [])


    const handleChange = (key: 'password' | 'password2', value: string) => {
        if (key === 'password2') {
            if (value !== data.password) {
                setError('Passwords not match')
            } else {
                setError(null)
            }
            return
        }

        let state = data
        state[key] = value
        const newState = { ...data, ...state }
        setData(newState)
    }

    const resetPassword = () => (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4">
                </div>
                <div className="col-md-4">
                    <div role="div" style={styles.form}>
                        {error && <span className="badge badge-danger">{error}</span>}

                        <div className="form-group">
                            <label>
                                Email
					        </label>
                            <input disabled type="email" className="form-control" id="email" value={data.email} />
                        </div>

                        <div className="form-group">
                            <label>
                                Password
					        </label>
                            <input type="password" className="form-control" id="exampleInputPassword1" onChange={(event: any) => handleChange('password', event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>
                                Repeat Password
					        </label>
                            <input type="password" className="form-control" id="exampleInputPassword2" onChange={(event: any) => handleChange('password2', event.target.value)} />
                        </div>


                        <div>
                            <div className="text-center">
                                <button disabled={data.password === '' || error !== null} type="submit" className="btn btn-primary" style={{ width: '100%' }} onClick={async () => {
                                    try {
                                        setLoading(true)
                                        await RemoteActions.passwordRecoveryReset(data)
                                        setLoading(false)
                                        setPassReset(true)
                                    } catch (error) {
                                        setLoading(false)
                                        setError(error.toString())
                                    }
                                }}>
                                    Reset Password
				            </button>
                            </div>

                            {loading && <div className="d-flex justify-content-center">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>}

                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    {passReset && (
                        <Redirect to={{
                            pathname: "/login",
                        }} />
                    )}
                </div>
            </div>
        </div>
    );

    return resetPassword()
};


export default (ResetPasswordScreen);

