import * as React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ReactSVG } from 'react-svg'

import { lang, selctedLang } from '../../lang';
import { colorFree, colorRemove, themeColor, weekendColor } from '../../utils/calendar-utils';
import membership from '../../redux/reduceres/membership';
import { SplitCircle } from '../calendar/slot-item';
import { split } from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import userEvent from '@testing-library/user-event';
import { Membership, QuestionAnswer, Reservation, User } from '../../models/models';
import { Icons } from '../generic/bottom-navigation';
import { UserRoles } from '../../utils/utils';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import { isCheckInOutFill } from '../../utils/reservation-utils';
import RemoteActions from '../../remote/remote-actions';



interface SlotReservedButtonProps {
    user: User;
    Slot: any;
    DaySlots: any[];
    onDeletePress?: (slot: any) => void;
    onAddToWatingListPress?: (slot: any) => void;
    onCheckInPress?: (slot: any, linkedReservation: Reservation[]) => void;
    onCheckOutPress?: (slot: any, linkedReservation: Reservation[]) => void;
    onEditPress?: (slot: any) => void;
    answers: QuestionAnswer[];
}

const SlotReservedButton = ({ Slot, DaySlots, user, onDeletePress, onAddToWatingListPress, onCheckInPress, onCheckOutPress, onEditPress, answers }: SlotReservedButtonProps) => {
    const [expand, setExpand] = React.useState(false)
    const dispatch = useDispatch()
    React.useEffect(() => {
        RemoteActions.getAnswersForReservation(dispatch, Slot.reservation.id)
    }, [])

    const getLinkedReservations = (): Reservation[] => {
        const index = DaySlots.findIndex((slot: any) => slot.reservation && Slot.reservation.id === slot.reservation.id)
        const reservations: any[] = []

        for (let i = 0; i < DaySlots.length; i++) {
            const slot = DaySlots[i];
            if (slot.reservation && Slot.reservation.user.id === slot.reservation.user.id) {
                if (i === index || i === index - 1 || i === index + 1) {
                    reservations.push(slot.reservation)
                }
            }
        }

        return reservations
    }

    const [linkedReservations] = React.useState<Reservation[]>(getLinkedReservations())

    const Render = () => {

        const index = linkedReservations.findIndex((reservation: any) => reservation && Slot.reservation.id === reservation.id)

        const containerStyle: any = Slot.reservation?.free ? { borderLeftColor: colorFree, borderLeftWidth: 7, borderLeftStyle: 'solid' } : {}
        const backgroundColor = Slot.dayType === 'WEEK_END' ? { backgroundColor: weekendColor, borderColor: 'lightgray' } : { backgroundColor: 'transparent' }

        const WaitingOpacity = Slot.onWaitingList ? { opacity: 0.3 } : {}

        const reservation: Reservation = Slot.reservation
        const slotStartHour = +reservation.slot.split(':')[0]
        const date = reservation.date.split('-')
        const slotDate = new Date(+date[0], +date[1], +date[2], slotStartHour)

        const now = new Date()

        const reservationDate = new Date(+date[0], +date[1], +date[2])
        reservationDate.setHours(+Slot.reservation.slot.split(':')[0])

        const dateString = moment(reservationDate).format("YYYY MM DD")

        const SlotIcon = Icons[Slot.slot.name]
        return (
            <div className="card slotBtShadow" style={{ ...containerStyle, ...backgroundColor, marginLeft: '4%', marginRight: '6%', }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10, marginRight: 10, height: 40 }} onClick={() => setExpand(!expand)}>
                    <div style={{}}>{SlotIcon}</div>
                    <div style={{ marginLeft: 15, fontSize: 13 }}>{`${Slot.reservation?.user.firstName} ${Slot.reservation?.user.lastName}`}</div>
                    {Slot.onWaitingList && <div style={{ display: 'flex', position: 'absolute', right: 5 }}>{Icons.Waiting}</div>}
                    {Slot.isSplit && <div style={{ position: 'absolute', right: 15 }}><SplitCircle key='' width={10} backgroundColor={themeColor} /></div>}
                </div>
                {expand && (
                    <div style={{ marginLeft: 52 }}>
                        <Row>
                            <Col style={{ display: 'flex' }}>
                                <div style={{ fontSize: 12, fontWeight: 'bold', marginRight: 5 }}>
                                    {lang[selctedLang].slot}
                                </div>
                                <div style={{ fontSize: 12 }}>
                                    {Slot.reservation?.slot}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ display: 'flex' }}>
                                <div style={{ fontSize: 12, fontWeight: 'bold', marginRight: 5 }}>
                                    {lang[selctedLang].vessel}
                                </div>
                                <div style={{ fontSize: 12 }}>
                                    {Slot.reservation?.vessel.name}
                                </div>
                            </Col>
                        </Row>


                        <Row>
                            <Col style={{ display: 'flex' }}>
                                <div style={{ fontSize: 12, fontWeight: 'bold', marginRight: 5 }}>
                                    {lang[selctedLang].date}
                                </div>
                                <div style={{ fontSize: 12 }}>
                                    {dateString}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ display: 'flex' }}>
                                <div style={{ fontSize: 12, fontWeight: 'bold', marginRight: 5 }}>
                                    {lang[selctedLang].membership}
                                </div>
                                <div style={{ fontSize: 12 }}>
                                    {Slot.reservation?.membership.title}
                                </div>
                            </Col>
                        </Row>


                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginTop: 15, padding: 15 }}>

                            {user.id === Slot.reservation.user.id && reservationDate.getTime() <= now.getTime() && (index === 0) && <div style={{ fontSize: 13, marginRight: 10, opacity: isCheckInOutFill(answers, 'checkIn', linkedReservations) ? 0.4 : 1.0 }} onClick={() => onCheckInPress!(Slot, linkedReservations)}>{Icons.CheckIn}</div>}

                            {user.id === Slot.reservation.user.id && reservationDate.getTime() <= now.getTime() && (index === linkedReservations.length - 1) && <div style={{ fontSize: 13, marginRight: 10, opacity: isCheckInOutFill(answers, 'checkOut', linkedReservations) ? 0.4 : 1.0 }} onClick={() => onCheckOutPress!(Slot, linkedReservations)}>{Icons.CheckOut}</div>}

                            {now.getTime() < slotDate.getTime() && user.id !== Slot.reservation.user.id && <div style={{ fontSize: 13, marginRight: 10, ...WaitingOpacity }} onClick={() => { // waitting list

                                if (!Slot.onWaitingList) {
                                    onAddToWatingListPress!(Slot)
                                }

                            }}>{Icons.AddWaiting}</div>}

                            {((user.role === UserRoles.ADMIN) || (user.memberships!.filter((membership: Membership) => membership.id === Slot.reservation?.membership.id).length > 0)) && <div style={{ fontSize: 20, marginRight: 10 }} onClick={() => onEditPress!(Slot)}><FontAwesomeIcon icon={faCog} color={themeColor} /></div>}

                            {(user.id === Slot.reservation.user.id || user.role === UserRoles.ADMIN) && <div style={{ fontSize: 13 }} onClick={() => onDeletePress!(Slot)}>{Icons.Remove}</div>}

                        </div>
                    </div>
                )}
            </div>
        )
    }

    return Render()
};

// <FormScreen reservation={reservation} type={'checkIn'} dispatch={dispatch} answers={answers} />

const mapStateToProps = (state: any) => {
    return {
        user: state.login.user,
        answers: state.answer.answer,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(SlotReservedButton);

