import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { User, Membership, BookingRules, Slot, Vessel, Reservation, QuestionAnswer, WaitingList, SplitSlot, Holiday } from '../../models/models';
import { Row, Container, Col } from 'react-bootstrap';
import { getWeekday, weekDay, isHoliday, themeColor } from '../../utils/calendar-utils';
import { getSlotsData, isValidReservation, sortSlots, UserRoles } from '../../utils/utils';
import SlotItem, { SplitCircle } from '../../components/calendar/slot-item';
import RemoteActions from '../../remote/remote-actions';
import { lang, selctedLang } from '../../lang';
import CalendarMobile from '../calendar-mobile';
import Calendar from '../calendar';
import Drawer from "react-bottom-drawer";
import SlotAvailableButton from '../../components/drawer/SlotAvailableButton';
import SlotReservedButton from '../../components/drawer/SlotReservedButton';
import ReservationModal from '../../components/modal/ReservationModal';
import RemoveModal from '../../components/modal/RemoveModal';
import WaitingListModal from '../../components/modal/WaitingListModal';
import FormScreen from '../../screens/check-form/form-screen';
import { BrowserView, MobileView } from 'react-device-detect';
import { addScreenNotifier } from '../../utils/ScreenNotifier';
import { Redirect } from 'react-router-dom';


interface ReservationScreenProps {
    reservations: Reservation[];
    user: User;
    memberships: Membership[];
    date?: Date;
    answers: QuestionAnswer[];
    waitingList: WaitingList[];
    splitSlots: SplitSlot[];
    holidays: Holiday[],
    dispatch: Dispatch;
}

const SELECTED_MEMBERSHIP = 'SELECTED_MEMBERSHIP'

class ReservationScreen extends React.Component<ReservationScreenProps, { [k: string]: any }> {

    constructor(props: ReservationScreenProps) {
        super(props)

        this.state = {
            membership: undefined,
            isVisible: false,
            reservationModal: false,
            removeModal: false,
            waitingModal: false,
            renderView: 'default',
            daySlots: [],
            selectedSlot: null,
            linkedReservations: []
        }
    }

    currentYear = new Date().getFullYear()

    componentDidMount() {
        //RemoteActions.getReservation(this.props.dispatch)
        RemoteActions.getSplitSlot(this.props.dispatch)
        RemoteActions.refreshUserMemberships(this.props.dispatch)
        // RemoteActions.getAnswer(this.props.dispatch)
        RemoteActions.getWaitingLists(this.props.dispatch)
        RemoteActions.getHoliday(this.props.dispatch, this.currentYear)
        RemoteActions.getSkipperAccount(this.props.dispatch)

        addScreenNotifier(this)

    }

    onScreenChange = async (screen: string) => {
        return new Promise<void>((resolve) => {
            this.setState({ isVisible: false }, () => resolve())
        })
    }

    RenderCheckIn = () => (<FormScreen reservation={this.state.linkedReservations} type={'checkIn'} dispatch={this.props.dispatch} answers={this.props.answers} onCancel={() => this.setState({ renderView: 'default' })} />)

    RenderCheckOut = () => (<FormScreen reservation={this.state.linkedReservations} type={'checkOut'} dispatch={this.props.dispatch} answers={this.props.answers} onCancel={() => this.setState({ renderView: 'default' })} />)

    getSelectedMembership = () => {
        const selectedMembership = localStorage.getItem(SELECTED_MEMBERSHIP)

        if (selectedMembership) {
            const stored = JSON.parse(selectedMembership)
            const membership = this.props.memberships!.find((membership: Membership) => membership.id === stored.id)
            if (membership)
                return membership!
        }

        if (this.props.memberships.length > 0) {
            return this.props.memberships[0]
        } else {
            return undefined
        }
    }


    updateSlots = () => {
        const dateParts = this.state.selectedSlot.date.split('-')

        const _slots = getSlotsData(this.props.user, this.props.date!, +dateParts[2], +dateParts[1], +dateParts[0], this.state.selectedSlot.vessel, this.props.reservations, this.props.holidays, this.props.splitSlots, this.props.waitingList)
        let slots = _slots.flatMap((element: any) => {
            element.isValidReservation = isValidReservation(element.slot, +dateParts[0], +dateParts[1], +dateParts[2], this.props.holidays, this.props.reservations, this.state.selectedSlot.vessel, this.state.membership, this.props.date!, this.props.user);
            return element
        })
        this.setState({ daySlots: slots })
    }

    private vessel: any

    onVesselSelected = (vessel: Vessel) => {
        this.vessel = vessel
    }


    RenderCalendar = () => {

        if (this.props.memberships && this.props.memberships.length && !this.state.membership) {
            this.setState({ membership: this.getSelectedMembership() })
        }



        return (
            <Container>
                {!this.props.user && <Redirect to={'/login'} />}

                {this.state.membership && this.props.memberships && this.props.memberships.length &&
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 10 }}>
                        <div style={{ textAlign: 'start', marginRight: 10 }}>{lang[selctedLang].membership}</div>
                        <div>
                            <select id="combo" onChange={(event) => {
                                this.setState({ membership: JSON.parse(event.target.value) })
                                localStorage.setItem(SELECTED_MEMBERSHIP, event.target.value)
                            }}>
                                {this.props.memberships!.map((element: Membership, index: number) => {
                                    const isSelected = this.state.membership.id === element.id
                                    return isSelected ?
                                        <option selected key={`${index}`} value={JSON.stringify(element)}>
                                            {element.title}
                                        </option>
                                        : <option key={`${index}`} value={JSON.stringify(element)}>
                                            {element.title}
                                        </option>

                                })}
                            </select>

                        </div>
                    </div>}

                {!this.state.membership &&
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: '30%' }}>
                        <Row>
                            <h3>{lang[selctedLang].loading}</h3>
                        </Row>

                        <Row style={{ marginLeft: 25 }}>
                            <div className="spinner-border" role="status">
                                <span className="sr-only">{lang[selctedLang].loading}...</span>
                            </div>
                        </Row>
                    </div>}


                {this.state.membership &&

                    <Row>
                        <Col>


                            {this.props.user.role === UserRoles.ADMIN &&
                                <>
                                    <BrowserView>
                                        <Calendar
                                            membership={this.state.membership}
                                            user={this.props.user}
                                            getSlots={(day: number, month: number, year: number, bookingRules: BookingRules, vessel: Vessel, reservations: Reservation[]) => {
                                                if (!bookingRules)
                                                    return

                                                if (this.currentYear !== year) {
                                                    this.currentYear = year

                                                    RemoteActions.getHoliday(this.props.dispatch, this.currentYear)
                                                }

                                                const dayDate = new Date(year, month, day)
                                                const dayOfWeek = getWeekday((dayDate).getDay())

                                                const isTodayHoliday = isHoliday(day, month, year, this.props.holidays)
                                                const dayType = (dayOfWeek === weekDay[0] || dayOfWeek === weekDay[6] || isTodayHoliday) ? 'WEEK_END' : 'WORKING_DAY'
                                                const slotTypes = {
                                                    WORKING_DAY: sortSlots(bookingRules.beforeWeekend),
                                                    WEEK_END: sortSlots(bookingRules.ofWeekend)
                                                }

                                                return (

                                                    slotTypes[dayType].flatMap((slot: Slot) => {

                                                        const isSlotSplit: SplitSlot[] = this.props.splitSlots.filter((splitSlot: SplitSlot) => slot.range === splitSlot.slot && splitSlot.date === `${year}-${month}-${day}` && vessel.id === splitSlot.vessel.id)

                                                        if (isSlotSplit.length) {
                                                            const splitSlot: Slot[] = [
                                                                {
                                                                    id: isSlotSplit[0].id,
                                                                    name: slot.name,
                                                                    range: isSlotSplit[0].firstSlotRange,
                                                                    original: slot.range,
                                                                },
                                                                {
                                                                    id: isSlotSplit[0].id,
                                                                    name: slot.name,
                                                                    range: isSlotSplit[0].secondSlotRange,
                                                                    original: slot.range,
                                                                }
                                                            ]


                                                            const isReserved = reservations!.filter((reservation: Reservation) => (
                                                                reservation.date === `${year}-${month}-${day}` &&
                                                                (reservation.slot === isSlotSplit[0].firstSlotRange || reservation.slot === isSlotSplit[0].secondSlotRange) &&
                                                                reservation.vessel.id === vessel.id &&
                                                                !reservation.disable
                                                            ))

                                                            return splitSlot.flatMap((_slot: Slot, index: number) => (
                                                                <SlotItem
                                                                    key={`${slot.id}-${year}-${month}-${day}-${index}`}
                                                                    user={this.props.user}
                                                                    vessel={vessel!}
                                                                    membership={this.state.membership}
                                                                    slot={_slot}
                                                                    day={day}
                                                                    month={month}
                                                                    year={year}
                                                                    holidays={this.props.holidays}
                                                                    reservations={reservations}
                                                                    bookingRules={bookingRules}
                                                                    answers={this.props.answers}
                                                                    waitingList={this.props.waitingList}
                                                                    canRemoveSplit={!isReserved.length}
                                                                />
                                                            ))
                                                        }


                                                        return (
                                                            <SlotItem
                                                                key={`${slot.id}-${year}-${month}-${day}`}
                                                                user={this.props.user}
                                                                vessel={vessel!}
                                                                membership={this.state.membership}
                                                                slot={slot}
                                                                day={day}
                                                                month={month}
                                                                year={year}
                                                                holidays={this.props.holidays}
                                                                reservations={reservations}
                                                                bookingRules={bookingRules}
                                                                answers={this.props.answers}
                                                                waitingList={this.props.waitingList}
                                                            />
                                                        )
                                                    }

                                                    )
                                                )
                                            }}
                                        />
                                    </BrowserView>
                                    <MobileView>
                                        <CalendarMobile
                                            onDayPress={(slots: any[]) => {
                                                this.setState({ daySlots: slots, isVisible: true })
                                            }
                                            }
                                            membership={this.state.membership}
                                            user={this.props.user}
                                            onVesselSelected={this.onVesselSelected}
                                        />
                                    </MobileView>
                                </>
                            }

                            {this.props.user.role !== UserRoles.ADMIN &&
                                <CalendarMobile
                                    onDayPress={(slots: any[]) => {
                                        this.setState({ daySlots: slots, isVisible: true })
                                    }
                                    }
                                    membership={this.state.membership}
                                    user={this.props.user}
                                    onVesselSelected={this.onVesselSelected}
                                />
                            }

                        </Col>

                    </Row>

                }

                {this.state.membership &&
                    <div style={{ display: 'flex', flexDirection: 'row', color: 'grey', justifyContent: 'center', top: -20, position: 'relative', fontSize: 12 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
                            <div style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: 'transparent', marginRight: 2, borderColor: 'grey', borderWidth: 1, borderStyle: 'solid' }} />
                            <div>{lang[selctedLang].vacant}</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
                            <div style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: 'grey', marginRight: 2 }} />
                            <div>{lang[selctedLang].reserved}</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
                            <SplitCircle key={``} width={10} backgroundColor={'grey'} />
                            <div>{lang[selctedLang].half}</div>
                        </div>
                    </div>

                }

                {this.state.selectedSlot &&
                    <ReservationModal
                        user={this.props.user}
                        show={this.state.reservationModal}
                        slotData={this.state.selectedSlot}
                        vessel={this.state.selectedSlot.vessel}
                        membership={this.state.membership}
                        date={this.state.selectedSlot.date}
                        isSplit={this.state.selectedSlot.isSplit}
                        isFree={this.state.selectedSlot.isFree}
                        isValidReservation={this.state.selectedSlot.isValidReservation}
                        handleClose={() => {
                            this.updateSlots()
                            this.setState({ reservationModal: false })
                        }}
                    />}


                {this.state.selectedSlot &&
                    <RemoveModal
                        show={this.state.removeModal}
                        slotData={this.state.selectedSlot}
                        handleClose={() => {
                            this.updateSlots()
                            this.setState({ removeModal: false })
                        }}
                    />
                }

                {this.state.selectedSlot &&
                    <WaitingListModal
                        show={this.state.waitingModal}
                        membership={this.state.membership}
                        slotData={this.state.selectedSlot}
                        handleClose={() => {
                            this.updateSlots()
                            this.setState({ waitingModal: false })
                        }}
                    />
                }


            </Container >
        );
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
    }

    render() {
        let renderView: any;

        if (this.state.renderView === 'default') {
            renderView = this.RenderCalendar()
        }
        else if (this.state.renderView === 'checkIn') {
            renderView = this.RenderCheckIn()
        }
        else if (this.state.renderView === 'checkOut') {
            renderView = this.RenderCheckOut()
        }

        const availableSlots = this.state.daySlots.map((element: any) => {
            if (!element.reservation) {
                return (
                    <SlotAvailableButton key={JSON.stringify(element)} data={element} isFree={element.isFree} onPress={(slotData: any) => {
                        this.setState({ selectedSlot: slotData, reservationModal: true })
                    }} />
                )
            }
        })
        const reservedSlots = this.state.daySlots.map((element: any) => {
            if (element.reservation) {
                return (
                    <Row key={JSON.stringify(element)} style={{ marginTop: 10 }}>
                        <Col>
                            <SlotReservedButton
                                Slot={element}
                                DaySlots={this.state.daySlots}
                                onAddToWatingListPress={(slotData: any) => {
                                    this.setState({ selectedSlot: slotData, waitingModal: true })
                                }}
                                onDeletePress={(slotData: any) => {
                                    this.setState({ selectedSlot: slotData, removeModal: true })
                                }}
                                onCheckInPress={(slotData: any, linkedReservations: Reservation[]) => {
                                    this.setState({ selectedSlot: slotData, renderView: 'checkIn', linkedReservations })
                                }}
                                onCheckOutPress={(slotData: any, linkedReservations: Reservation[]) => {

                                    this.setState({ selectedSlot: slotData, renderView: 'checkOut', linkedReservations })
                                }}
                                onEditPress={(slot: any) => {
                                    this.setState({ selectedSlot: slot, reservationModal: true })
                                }}
                            />
                        </Col>
                    </Row>
                )
            }
        })

        return (
            <>
                {renderView}

                <Drawer
                    duration={250}
                    hideScrollbars={true}
                    onClose={() => this.setState({ isVisible: false })}
                    isVisible={this.state.isVisible && this.state.renderView === 'default'}
                >
                    <div style={{ marginBottom: 100, paddingLeft: 10, paddingRight: 10 }}>

                        <div style={{ color: themeColor, fontSize: 15 }}>
                            {lang[selctedLang].details.toUpperCase()}
                        </div>

                        <div style={{ fontSize: 15, marginBottom: 15 }}>
                            {this.props.user.email}
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingTop: 10 }}>
                            {availableSlots}
                        </div>
                        <div style={{ flexDirection: 'column', paddingTop: 10 }}>
                            {reservedSlots}
                        </div>
                    </div>
                </Drawer>
            </>
        )
    }
};


/**
 * controlles login state
 * @param state 
 */
const mapStateToProps = (state: any) => {
    return {
        reservations: state.reservations.reservations,
        user: state.login.user,
        loggedIn: state.login.loggedIn,
        memberships: state.login.memberships,
        answers: state.answer.answer,
        waitingList: state.waitingList.waitingList,
        splitSlots: state.splitSlot.splitSlot,
        holidays: state.holiday.holiday,
        date: state.reservations.date,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(ReservationScreen);

