import { ACTIONS_SKIPPER_ACCOUNT } from "../actions/skipper-account"
import { SkipperAccount } from "../../models/models"

interface IState {
    skipperAccounts: SkipperAccount[],
    error: string
}

export const initialState: IState = { skipperAccounts: [], error: '' }

export const skipperAccounts = (state = initialState, action: any) => {
    switch (action.type) {
        case ACTIONS_SKIPPER_ACCOUNT.ADD_SKIPPER_ACCOUNT_SUCCESS:

            const exists = state.skipperAccounts.findIndex(x => x.id===action.data.id)
            if(exists > -1){
                state.skipperAccounts[exists] = action.data as SkipperAccount
            }else{
                state.skipperAccounts.push(action.data as SkipperAccount)
            }

            return {
                ...state,
            }
        case ACTIONS_SKIPPER_ACCOUNT.ADD_SKIPPER_ACCOUNT_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_SKIPPER_ACCOUNT.DELETE_SKIPPER_ACCOUNT_SUCCESS:
            const skipperAccounts = state.skipperAccounts.filter((skipperAccount: SkipperAccount)=> skipperAccount.id !== action.data.id)
            return {
                ...state,
                skipperAccounts
            }
        case ACTIONS_SKIPPER_ACCOUNT.DELETE_SKIPPER_ACCOUNT_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_SKIPPER_ACCOUNT.GET_SKIPPER_ACCOUNT_SUCCESS:
            return {
                ...state,
                skipperAccounts: action.data
            }
        case ACTIONS_SKIPPER_ACCOUNT.GET_SKIPPER_ACCOUNT_FAILURE:
            return {
                ...state,
                error: action.data
            }
        default:
            return state
    }
}

export default skipperAccounts