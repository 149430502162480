import { ACTIONS_SLOT } from "../actions/slots"
import { Slot } from "../../models/models"

interface IState {
    slots: Slot[],
    error: string
}

export const initialState: IState = { slots: [], error: '' }

export const slots = (state = initialState, action: any) => {
    switch (action.type) {
        case ACTIONS_SLOT.ADD_SLOTS_SUCCESS:

            const exists = state.slots.findIndex(x => x.id === action.data.id)
            if (exists > -1) {
                state.slots[exists] = action.data as Slot
            } else {
                state.slots.push(action.data as Slot)
            }

            return {
                ...state,
            }
        case ACTIONS_SLOT.ADD_SLOTS_FAILURE:
            return {
                ...state,
                error: action.data.sort()
            }
        case ACTIONS_SLOT.DELETE_SLOTS_SUCCESS:
            const slots = state.slots.filter((slot: Slot) => slot.id !== action.data.id)
            return {
                ...state,
                slots
            }
        case ACTIONS_SLOT.DELETE_SLOTS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_SLOT.GET_SLOTS_SUCCESS:
            return {
                ...state,
                slots: action.data
            }
        case ACTIONS_SLOT.GET_SLOTS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        default:
            return state
    }
}

export default slots