export enum ACTIONS_SKIPPER_AVAILABILITY {
    ADD_SKIPPER_AVAILABILITY_SUCCESS = 'ADD_SKIPPER_AVAILABILITY_SUCCESS',
    ADD_SKIPPER_AVAILABILITY_FAILURE = 'ADD_SKIPPER_AVAILABILITY_FAILURE',

    GET_SKIPPER_AVAILABILITY_SUCCESS = 'GET_SKIPPER_AVAILABILITY_SUCCESS',
    GET_SKIPPER_AVAILABILITY_FAILURE = 'GET_SKIPPER_AVAILABILITY_FAILURE',

    DELETE_SKIPPER_AVAILABILITY_SUCCESS = 'DELETE_SKIPPER_AVAILABILITY_SUCCESS',
    DELETE_SKIPPER_AVAILABILITY_FAILURE = 'DELETE_SKIPPER_AVAILABILITY_FAILURE',
}

export const ADD_SKIPPER_AVAILABILITY_SUCCESS = (data:any) => ({
    type: ACTIONS_SKIPPER_AVAILABILITY.ADD_SKIPPER_AVAILABILITY_SUCCESS,
    data
})

export const ADD_SKIPPER_AVAILABILITY_FAILURE = (e:any) => ({
    type: ACTIONS_SKIPPER_AVAILABILITY.ADD_SKIPPER_AVAILABILITY_FAILURE,
    error: e
})

export const DELETE_SKIPPER_AVAILABILITY_SUCCESS = (data:any) => ({
    type: ACTIONS_SKIPPER_AVAILABILITY.DELETE_SKIPPER_AVAILABILITY_SUCCESS,
    data
})

export const DELETE_SKIPPER_AVAILABILITY_FAILURE = (e:any) => ({
    type: ACTIONS_SKIPPER_AVAILABILITY.DELETE_SKIPPER_AVAILABILITY_FAILURE,
    error: e
})


export const GET_SKIPPER_AVAILABILITY_SUCCESS = (data:any) => ({
    type: ACTIONS_SKIPPER_AVAILABILITY.GET_SKIPPER_AVAILABILITY_SUCCESS,
    data
})

export const GET_SKIPPER_AVAILABILITY_FAILURE = (e:any) => ({
    type: ACTIONS_SKIPPER_AVAILABILITY.GET_SKIPPER_AVAILABILITY_FAILURE,
    error: e
})