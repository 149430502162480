import * as React from 'react';
import { RemoteActions } from '../../remote/remote-actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux'
import ItemsList from '../../components/generic/items-list';
import { Slot } from '../../models/models';
import DynamicForm from '../../components/generic/dynamic-form/dynamic-form';
import { Container, Row, Col } from 'react-bootstrap';




interface SlotsScreenProps {
    dispatch: Dispatch,
    slots: Slot[];
    refesh: string;
}

const slotNames: { [k: string]: string } = {
    Morning: 'Morning',
    Afternoon: 'Afternoon',
    Evening: 'Evening'
}

const SlotsScreen: React.FC<any> = ({ dispatch, slots, refesh }: SlotsScreenProps) => {

    React.useEffect(() => {
        RemoteActions.getSlots(dispatch)
    }, [])


    return (
        <div className="container-fluid">
            <h1>Slots</h1>
            <div style={{ opacity: 0.0 }}>{refesh}</div>



            <ItemsList
                style={{}}
                onSelectItem={() => { }}
                onEditItem={(slot: Slot) => {
                    return RemoteActions.setSlot(slot, dispatch)
                }}
                dataTemplate={{
                    name: slotNames.Morning,
                    range: ''
                }}
                onDeleteItem={(slot: Slot) => {
                    return RemoteActions.deleteSlot(slot.id, dispatch)
                }}
                listTemplate={(slot: Slot) => {
                    return (
                        <div>
                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Name</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${Object.keys(slotNames).find(key => slotNames[key] === slot.name)}`}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Range</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${slot.range}`}</span>
                                </div>
                            </div>
                        </div>
                    )
                }}
                newItemFormTemplate={({ item, editing, onChange }: { item: Slot, editing: boolean, onChange: any }) => {
                    const labels: Slot = {
                        name: 'Name',
                        range: 'Range'
                    }
                    return (
                        <Col>
                            <Row style={{ marginLeft: 10 }}>
                                <Col>
                                    <select id="combo" onChange={(event: any) => {
                                        item.name = event.target.value
                                        onChange(item)
                                    }}>
                                        <option value={slotNames.Morning}>Morning</option>
                                        <option value={slotNames.Afternoon}>Afternoon</option>
                                        <option value={slotNames.Evening}>Evening</option>
                                    </select>
                                </Col>
                            </Row>


                            <DynamicForm
                                exclude={{ name: '' }}
                                data={item}
                                labels={labels}
                                editable={editing}
                                onDataChange={(data: any) => {
                                    onChange(data)
                                }}
                            />
                        </Col>
                    )
                }}
                list={slots} />
        </div>
    );
};


const mapStateToProps = (state: any) => {
    const reducer = {
        slots: state.slots.slots,
        refesh: `${Date.now()}`,
    }
    return reducer
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(SlotsScreen);

const styles = ({
    container: {}
});
