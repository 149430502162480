import { ACTIONS_RESERVATION } from "../actions/reservation"
import { Reservation } from "../../models/models"
import _ from 'lodash'
import { sortReservations } from "../../utils/reservation-utils"
interface IState {
    reservations: Reservation[],
    date: Date;
    error: string
}

export const initialState: IState = { reservations: [], date: new Date(), error: '' }

export const reservations = (state = initialState, action: any) => {

    switch (action.type) {
        case ACTIONS_RESERVATION.ADD_RESERVATIONS_SUCCESS:

            const exists = state.reservations.findIndex(x => x.id === action.data.id)
            if (exists > -1) {
                state.reservations[exists] = action.data as Reservation
            } else {
                state.reservations.push(action.data as Reservation)
            }

            state.reservations = state.reservations.sort(sortReservations)

            return {
                ...state,
            }
        case ACTIONS_RESERVATION.ADD_RESERVATIONS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_RESERVATION.DELETE_RESERVATIONS_SUCCESS:
            const reservations = state.reservations.filter((reservation: Reservation) => reservation.id !== +action.data.id)
            state.reservations = state.reservations.sort(sortReservations)
            return {
                ...state,
                reservations
            }
        case ACTIONS_RESERVATION.DELETE_RESERVATIONS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_RESERVATION.GET_RESERVATIONS_SUCCESS:
            const jointReservations = [...state.reservations, ...action.data]
            const res = _.uniqBy(jointReservations, 'id');
            return {
                ...state,
                reservations: res,
                error: ''
            }
        case ACTIONS_RESERVATION.GET_RESERVATIONS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_RESERVATION.GET_SERVER_DATE_SUCCESS:
            const date = new Date(action.data.date)
            return date ? { ...state, date: date } : { ...state }

        case ACTIONS_RESERVATION.GET_SERVER_DATE_FAILURE:
            return {
                ...state,
                error: action.data
            }
        default:
            return state
    }
}

export default reservations