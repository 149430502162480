import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { LOGIN_FAILURE, LOGIN_SUCCESS } from '../../redux/actions/auth';
import RemoteActions from '../../remote/remote-actions';
import { UserRoles } from '../../utils/utils';
import { User } from '../../models/models';
import { Redirect } from 'react-router-dom';

interface SignupScreenProps {
    loggedIn: boolean;
    dispatch: Dispatch;
}

const styles = {
    form: {
        // borderWidth: 0.5,
        // borderColor: 'lightgrey',
        // borderStyle: 'solid',
        // borderRadius: 6,
        padding: 22,
    },
    login: {
        marginLeft: 'auto',
        marginRight: 'auto',
    }
}

const SignupScreen = (props: SignupScreenProps) => {
    const [user, setUser] = React.useState<User>({ email: '', password: '', firstName: '', lastName: '', role: UserRoles.MEMBER_CLUB })
    const [loading, setLoading] = React.useState<Boolean>(false)
    const [error, setError] = React.useState<string | null>(null)
    const [userAdded, setUserAdded] = React.useState<boolean>(false)


    const handleChange = (key: 'email' | 'password' | 'password2' | 'role' | 'firstName' | 'lastName', value: string) => {

        if (key !== 'password2') {
            let state = user
            state[key] = value
            const newState = { ...user, ...state }
            setUser(newState)
        } else {
            if (value !== user.password) {
                setError('Passwords not match')
            } else {
                setError(null)
            }
        }

    }

    const userIsAdded = () => (<Redirect to={'/reservation'} />)

    const SignUp = () => (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4">
                </div>
                <div className="col-md-4">
                    <div role="div" style={styles.form}>
                        {error && <span className="badge badge-danger">{error}</span>}

                        <div className="form-group">
                            <label>
                                First name
                            </label>
                            <input type="email" className="form-control" id="exampleInputEmail1" onChange={(event: any) => handleChange('firstName', event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>
                                Last name
                            </label>
                            <input type="email" className="form-control" id="exampleInputEmail1" onChange={(event: any) => handleChange('lastName', event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>
                                Email address
                            </label>
                            <input type="email" className="form-control" id="exampleInputEmail1" onChange={(event: any) => handleChange('email', event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>
                                Password
					        </label>
                            <input type="password" className="form-control" id="exampleInputPassword1" onChange={(event: any) => handleChange('password', event.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>
                                Repeat Password
					        </label>
                            <input type="password" className="form-control" id="exampleInputPassword2" onChange={(event: any) => handleChange('password2', event.target.value)} />
                        </div>

                        <div className="form-group" style={{ borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid', padding: 8, borderRadius: 4 }}>
                            <label>
                                User Role
					        </label>

                            <select id="combo" style={{ float: 'right' }} onChange={(event: any) => handleChange('role', event.target.value ? event.target.value : '')}>
                                <option value={UserRoles.MEMBER_CLUB}>Club Member</option>
                                <option value={UserRoles.TURISTIC_OPERATOR}>Turistic Operator</option>
                                <option value={UserRoles.CERTIFIED_CLIENT}>Certified Client</option>
                                <option value={UserRoles.SKIPPER}>Skipper</option>
                            </select>
                        </div>
                        <div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-primary" style={{ width: '100%' }} onClick={async () => {
                                    try {
                                        setLoading(true)
                                        await RemoteActions.signup(user, props.dispatch)
                                        setUserAdded(true)
                                    } catch (error) {
                                        setLoading(false)
                                        setError(error.toString())
                                    }
                                }}>
                                    Register
				            </button>
                            </div>

                            {loading && <div className="d-flex justify-content-center">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>}

                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                </div>
            </div>
        </div>
    );

    return userAdded ? userIsAdded() : SignUp()
};


/**
 * controlles login state
 * @param state 
 */
const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.login.loggedIn
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(SignupScreen);

