export enum ACTIONS_WAITING_LIST {
    ADD_WAITING_LIST_SUCCESS = 'ADD_WAITING_LIST_SUCCESS',
    ADD_WAITING_LIST_FAILURE = 'ADD_WAITING_LIST_FAILURE',

    GET_WAITING_LIST_SUCCESS = 'GET_WAITING_LIST_SUCCESS',
    GET_WAITING_LIST_FAILURE = 'GET_WAITING_LIST_FAILURE',

    DELETE_WAITING_LIST_SUCCESS = 'DELETE_WAITING_LIST_SUCCESS',
    DELETE_WAITING_LIST_FAILURE = 'DELETE_WAITING_LIST_FAILURE',
}

export const ADD_WAITING_LIST_SUCCESS = (data:any) => ({
    type: ACTIONS_WAITING_LIST.ADD_WAITING_LIST_SUCCESS,
    data
})

export const ADD_WAITING_LIST_FAILURE = (e:any) => ({
    type: ACTIONS_WAITING_LIST.ADD_WAITING_LIST_FAILURE,
    error: e
})

export const DELETE_WAITING_LIST_SUCCESS = (data:any) => ({
    type: ACTIONS_WAITING_LIST.DELETE_WAITING_LIST_SUCCESS,
    data
})

export const DELETE_WAITING_LIST_FAILURE = (e:any) => ({
    type: ACTIONS_WAITING_LIST.DELETE_WAITING_LIST_FAILURE,
    error: e
})


export const GET_WAITING_LIST_SUCCESS = (data:any) => ({
    type: ACTIONS_WAITING_LIST.GET_WAITING_LIST_SUCCESS,
    data
})

export const GET_WAITING_LIST_FAILURE = (e:any) => ({
    type: ACTIONS_WAITING_LIST.GET_WAITING_LIST_FAILURE,
    error: e
})