export enum ACTIONS_RESERVATION {
    ADD_RESERVATIONS_SUCCESS = 'ADD_RESERVATIONS_SUCCESS',
    ADD_RESERVATIONS_FAILURE = 'ADD_RESERVATIONS_FAILURE',

    GET_RESERVATIONS_SUCCESS = 'GET_RESERVATIONS_SUCCESS',
    GET_RESERVATIONS_FAILURE = 'GET_RESERVATIONS_FAILURE',

    DELETE_RESERVATIONS_SUCCESS = 'DELETE_RESERVATIONS_SUCCESS',
    DELETE_RESERVATIONS_FAILURE = 'DELETE_RESERVATIONS_FAILURE',

    GET_SERVER_DATE_SUCCESS = 'GET_SERVER_DATE_SUCCESS',
    GET_SERVER_DATE_FAILURE = 'GET_SERVER_DATE_FAILURE',
}

export const ADD_RESERVATIONS_SUCCESS = (data:any) => ({
    type: ACTIONS_RESERVATION.ADD_RESERVATIONS_SUCCESS,
    data
})

export const ADD_RESERVATIONS_FAILURE = (e:any) => ({
    type: ACTIONS_RESERVATION.ADD_RESERVATIONS_FAILURE,
    error: e
})

export const DELETE_RESERVATIONS_SUCCESS = (data:any) => ({
    type: ACTIONS_RESERVATION.DELETE_RESERVATIONS_SUCCESS,
    data
})

export const DELETE_RESERVATIONS_FAILURE = (e:any) => ({
    type: ACTIONS_RESERVATION.DELETE_RESERVATIONS_FAILURE,
    error: e
})


export const GET_RESERVATIONS_SUCCESS = (data:any) => ({
    type: ACTIONS_RESERVATION.GET_RESERVATIONS_SUCCESS,
    data
})

export const GET_RESERVATIONS_FAILURE = (e:any) => ({
    type: ACTIONS_RESERVATION.GET_RESERVATIONS_FAILURE,
    error: e
})


export const GET_SERVER_DATE_SUCCESS = (data:any) => ({
    type: ACTIONS_RESERVATION.GET_SERVER_DATE_SUCCESS,
    data
})

export const GET_SERVER_DATE_FAILURE = (e:any) => ({
    type: ACTIONS_RESERVATION.GET_SERVER_DATE_FAILURE,
    error: e
})