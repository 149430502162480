import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { lang, selctedLang } from '../../lang';
import { Holiday, Membership, Reservation, Slot, User, Vessel, WaitingList } from '../../models/models';
import RemoteActions from '../../remote/remote-actions';
import { months, themeColor } from '../../utils/calendar-utils';
import SplitSlots from '../split-slots/split-slots';
import SelectionModal from './Modal';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { isValidReservation, UserRoles } from '../../utils/utils';
import { Icons } from '../generic/bottom-navigation';
import ButtonWithFeedback from '../generic/buttonWithFeedback';

interface ReservationModalProps {
    reservations: Reservation[];
    systemDate?: Date;
    waitingList: WaitingList[];
    user: User;
    slotData: any;
    vessel: Vessel;
    membership: Membership;
    date: string;
    show: boolean;
    isSplit: boolean;
    isFree: boolean;
    isValidReservation: any;
    holidays: Holiday[];
    handleClose?: () => void;
    dispatch: Dispatch;
}

const ReservationModal = ({ dispatch, show, user, vessel, slotData, membership, date, isSplit, isFree, reservations, systemDate, holidays, handleClose }: ReservationModalProps) => {
    const [selectedUser, SetSelectedUser] = React.useState<User>(() => {
        if (slotData.reservation)
            return slotData.reservation.user
        else if (membership.users.some((u) => u.id === user.id) || user.role !== UserRoles.ADMIN && user.role !== UserRoles.SUPER_USER) {
            return user
        } else {
            return membership.users[0]
        }
    })

    const [freeSlot, SetFreeSlot] = React.useState<boolean>(isFree)

    const [divide, setDivide] = React.useState(false)
    let split = {
        selected: '',
        slot: '',
        firstSlotRange: '',
        secondSlotRange: '',
    }

    React.useEffect(() => {
        SetFreeSlot(isFree)
    }, [isFree])

    const Render = () => {
        const SlotIcon = Icons[slotData.slot.name]
        const dateParts = date.split('-')

        const year = dateParts[0]
        const month = dateParts[1]
        const day = dateParts[2]


        return (
            <div style={{}}>

                <SelectionModal handleClose={handleClose} show={show} Content={() => {
                    return (
                        <div style={{ display: 'flex', marginBottom: 8, flexDirection: 'column', padding: 5, paddingTop: 45 }}>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: -30,
                                    left: '50%',
                                }}
                            >
                                <div className="slotBtShadow" style={{
                                    position: 'relative',
                                    left: '-50%',
                                    backgroundColor: '#fff',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 35,
                                    width: 70,
                                    height: 70
                                }}>
                                    {SlotIcon}
                                </div>
                            </div>

                            <div style={{ color: themeColor, fontSize: 15 }}>
                                {lang[selctedLang].details.toUpperCase()}
                            </div>

                            <div style={{ fontSize: 15, marginBottom: 15 }}>
                                {selectedUser.email}
                            </div>

                            <select id="usercombo" style={{ marginTop: 10, marginBottom: 10 }} onChange={(event) => {
                                SetSelectedUser(JSON.parse(event.target.value))
                            }}>
                                {membership!.users.map((element: User, index: number) => {
                                    const isSelected = selectedUser.id === element.id
                                    return isSelected
                                        ?
                                        <option key={`${index}`} selected value={JSON.stringify(element)}>{`${element.firstName} ${element.lastName}`}</option>
                                        :
                                        <option key={`${index}`} value={JSON.stringify(element)}>{`${element.firstName} ${element.lastName}`}</option>
                                }
                                )}
                            </select>

                            {user.role === UserRoles.ADMIN &&

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                                    <input type="checkbox" checked={freeSlot} onChange={() => SetFreeSlot(!freeSlot)} />
                                    <div style={{ marginLeft: 10 }}>{lang[selctedLang].free}</div>
                                </div>
                            }


                            <Row>
                                <Col style={{ display: 'flex', marginBottom: 8 }}>
                                    <div style={{ fontSize: 12, fontWeight: 'bold', marginRight: 5 }}>
                                        {lang[selctedLang].slot}
                                    </div>
                                    <div style={{ fontSize: 12 }}>
                                        {slotData.slot.range}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ display: 'flex', marginBottom: 8 }}>
                                    <div style={{ fontSize: 12, fontWeight: 'bold', marginRight: 5 }}>
                                        {lang[selctedLang].vessel}
                                    </div>
                                    <div style={{ fontSize: 12 }}>
                                        {vessel.name}
                                    </div>
                                </Col>
                            </Row>


                            <Row>
                                <Col style={{ display: 'flex', marginBottom: 8 }}>
                                    <div style={{ fontSize: 12, fontWeight: 'bold', marginRight: 5 }}>
                                        {lang[selctedLang].date}
                                    </div>
                                    <div style={{ fontSize: 12 }}>
                                        {`${day} ${months[+month]} ${year}`}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ display: 'flex', marginBottom: 8 }}>
                                    <div style={{ fontSize: 12, fontWeight: 'bold', marginRight: 5 }}>
                                        {lang[selctedLang].membership}
                                    </div>
                                    <div style={{ fontSize: 12 }}>
                                        {membership.title}
                                    </div>
                                </Col>
                            </Row>

                            { !isSplit && <div style={{ fontSize: 12, marginTop: 10, marginBottom: 30 }}>

                                <div style={{ color: themeColor, textDecoration: 'underline' }} onClick={() => setDivide(!divide)}>
                                    {!divide ? lang[selctedLang].splitSlot : lang[selctedLang].dontSplitSlot}
                                </div>

                                {divide &&
                                    <div>
                                        <SplitSlots
                                            key={'12345'}
                                            slot={slotData.slot}
                                            onCancel={() => { }}
                                            onSplit={(data: any) => {
                                                split = data
                                            }}
                                        />
                                    </div>}
                            </div>
                            }

                            <ButtonWithFeedback
                                onPress={async () => {
                                    return new Promise<void>(async (resolve, reject) => {
                                        try {
                                            if (divide) {
                                                await splitSplot()
                                            }
                                            await makeReservation()
                                            handleClose!()
                                            resolve()
                                        } catch (error) {
                                            reject()
                                        }
                                    })
                                }}
                                text={lang[selctedLang].schedule}
                                className='btOK'
                            />


                            <div className='bt btCANCEL' onClick={handleClose}>
                                {lang[selctedLang].cancel}
                            </div>
                        </div>
                    )
                }} />
            </div>
        )
    };

    const splitSplot = () => {
        const dateParts = date.split('-')
        const year = dateParts[0]
        const month = dateParts[1]
        const day = dateParts[2]

        return RemoteActions.setSplitSlot({
            ...split,
            user: selectedUser,
            vessel,
            date: `${year}-${month}-${day}`,
        }, dispatch)
    }



    const makeReservation = () => {
        const dateParts = date.split('-')
        const _isValidReservation = isValidReservation(slotData.slot, +dateParts[0], +dateParts[1], +dateParts[2], holidays, reservations, vessel, membership, systemDate!, selectedUser);
        const reservation: Reservation = {
            id: slotData.reservation ? slotData.reservation.id : null,
            membership,
            user: selectedUser,
            vessel,
            slot: divide ? split.selected : slotData.slot.range,
            original: divide ? slotData.slot.range : undefined,
            date,
            skippers: [],
            borrow: _isValidReservation.borrow,
            free: freeSlot
        }

        if (slotData.slot.original) {
            reservation.original = slotData.slot.original
        }
        return RemoteActions.setReservation(reservation, dispatch)
    }

    return Render()
};
/**
 * controlles login state
 * @param state 
 */
const mapStateToProps = (state: any) => {
    return {
        reservations: state.reservations.reservations,
        waitingList: state.waitingList.waitingList,
        holidays: state.holiday.holiday,
        systemDate: state.reservations.date,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(ReservationModal);

