
import { CheckForm } from "../../../models/models"
import { ACTIONS_CHECK_FORM } from "../../actions/check-form/check-form"

interface IState {
    checkForm: CheckForm[],
    error: string
}

export const initialState: IState = { checkForm: [], error: '' }

export const checkForm = (state = initialState, action: any) => {
    switch (action.type) {
        case ACTIONS_CHECK_FORM.ADD_CHECK_FORM_SUCCESS:
            
            const exists = state.checkForm.findIndex(x => x.id===action.data.id)
            if(exists > -1){
                state.checkForm[exists] = action.data as CheckForm
            }else{
                state.checkForm.push(action.data as CheckForm)
            }

            return {
                ...state,
            }
        case ACTIONS_CHECK_FORM.ADD_CHECK_FORM_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_CHECK_FORM.DELETE_CHECK_FORM_SUCCESS:
            const checkForm = state.checkForm.filter((checkForm: CheckForm)=> checkForm.id !== action.data.id)
            return {
                ...state,
                checkForm
            }
        case ACTIONS_CHECK_FORM.DELETE_CHECK_FORM_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_CHECK_FORM.GET_CHECK_FORM_SUCCESS:
            return {
                ...state,
                checkForm: action.data
            }
        case ACTIONS_CHECK_FORM.GET_CHECK_FORM_FAILURE:
            return {
                ...state,
                error: action.data
            }
        default:
            return state
    }
}

export default checkForm