import * as React from 'react';
import { Slot, Reservation, QuestionAnswer, WaitingList } from '../../../../../models/models';
import { Button, Modal, Row, Col, Container } from 'react-bootstrap';
import { colorFree, months, themeColor } from '../../../../../utils/calendar-utils';
import { SlotItemProps, getStyle, AVAILABLE, RESERVED } from '../../slot-type-interface';
import RemoteActions from '../../../../../remote/remote-actions';
import { UserRoles } from '../../../../../utils/utils';
import { Link, Router, useHistory } from 'react-router-dom';
import membershipType from '../../../../../screens/membership/membership-type';
import { isValidToRemoveReservation } from '../../../../../utils/reservation-utils';
import ReservationDetailsScreen from '../../../../../screens/reservation/reservation-details-screen';
import FormScreen from '../../../../../screens/check-form/form-screen';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RenderSlot, SplitCircle } from '../../../slot-item';
import { lang, selctedLang } from '../../../../../lang';
import { Icons } from '../../../../generic/bottom-navigation';


export interface SlotReservedProps extends SlotItemProps {
    slotType: string;
    dayType: string;
    reservation: Reservation | undefined;
    answers: QuestionAnswer[];
    date: Date;
    waitingList: WaitingList[];
}

const SlotReservedAdmin = ({ dispatch, date, reservation, user, vessel, membership, bookingRules, reservations, day, month, year, slot, slotType, dayType, answers, waitingList }: SlotReservedProps) => {
    const [show, setShow] = React.useState(false);

    const [check, setCheck] = React.useState(false);
    const [waittingList, setWaittingList] = React.useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    React.useEffect(() => {
        const onWaitingList = waitingList.filter((waitingList: WaitingList) => waitingList.date === reservation!.date && waitingList.slot === reservation!.slot)
        setWaittingList(onWaitingList.length > 0)
    }, [])



    const history = useHistory();
    const goToDetails = () => {
        const state = { reservation, user, vessel, membership, bookingRules, reservations, day, month, year, slot, slotType, dayType }
        history.push({ pathname: '/reservationDetails', state })
    }

    const actions: { [k: string]: any } = {
        [UserRoles.ADMIN]: handleShow,
        [UserRoles.SUPER_USER]: handleShow,
    }
    const freeStyle = () => {
        if (reservation!.free) {
            return { borderLeftWidth: 7, borderLeftColor: colorFree, borderLeftStyle: 'solid' }
        }
        return {}
    }

    const SlotTemplate = ({ slot }: { slot: Slot }) => (
        <Button onClick={actions[user.role!]} key={`${slot.id}-${day}-${month}-${year}`} style={{ ...getStyle(slotType, dayType, RESERVED), color: 'white', justifyContent: 'center', alignItems: 'center', padding: 0, ...freeStyle() }} className="event-desc list-group-item list-group-item-action list-group-item-light">
            {waittingList && <div style={{ display: 'flex', position: 'absolute', right: 5 }}>{Icons.Waiting}</div>}
            {reservation!.original && <div style={{ position: 'absolute', right: 5, top: 5 }}><SplitCircle key='' width={10} backgroundColor={themeColor} /></div>}


            <div style={{ fontSize: 14 }}>
                {reservation?.membership.title}
            </div>
            <div >
                <div style={{ fontSize: 14 }}>
                    {`${reservation?.user.firstName} ${reservation?.user.lastName}`}
                </div>
            </div>
            <RenderSlot slot={slot} />
            {reservation!.checks && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                {reservation!.checks!.indexOf('checkIn') >= 0 && Icons.CheckInSM}
                {reservation!.checks!.indexOf('checkOut') >= 0 && Icons.CheckOutSM}
            </div>}
            {/* {!reservation?.skippers?.length &&
                (
                    <div style={{ backgroundColor: 'red', borderRadius: 4, width: 8, height: 8 }} />
                )
            } */}
        </Button>
    )

    const ModalComponent = () => (
        <Modal show={show} onHide={handleClose} animation={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{lang[selctedLang].details}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflow: 'scroll' }}>
                <ReservationDetailsScreen state={{ date, reservation, user, vessel, membership, bookingRules, reservations, day, month, year, slot, slotType, dayType, answers }} />
                {/* // !check
                    //     ? <ReservationDetailsScreen state={{ date, reservation, user, vessel, membership, bookingRules, reservations, day, month, year, slot, slotType, dayType, answers }} />
                    //     : <FormScreen reservation={reservation} type={'checkIn'} dispatch={dispatch} answers={answers} onCancel={()=>{}} /> */}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {lang[selctedLang].cancel}
                </Button>
            </Modal.Footer>
        </Modal>
    )


    return (
        <div>
            <SlotTemplate slot={slot} />
            <ModalComponent />
        </div>
    )

}

const mapStateToProps = (state: any) => {

    return {
        waitingList: state.waitingList.waitingList,
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(SlotReservedAdmin)