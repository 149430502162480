import { ACTIONS_SKIPPER_AVAILABILITY } from "../actions/skipper-availability"
import { SkipperAvailability } from "../../models/models"

interface IState {
    skipperAvailability: SkipperAvailability[],
    error: string
}

export const initialState: IState = { skipperAvailability: [], error: '' }

export const skipperAvailability = (state = initialState, action: any) => {
    switch (action.type) {
        case ACTIONS_SKIPPER_AVAILABILITY.ADD_SKIPPER_AVAILABILITY_SUCCESS:

            const exists = state.skipperAvailability.findIndex(x => x.id===action.data.id)
            if(exists > -1){
                state.skipperAvailability[exists] = action.data as SkipperAvailability
            }else{
                state.skipperAvailability.push(action.data as SkipperAvailability)
            }

            return {
                ...state,
            }
        case ACTIONS_SKIPPER_AVAILABILITY.ADD_SKIPPER_AVAILABILITY_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_SKIPPER_AVAILABILITY.DELETE_SKIPPER_AVAILABILITY_SUCCESS:
            const skipperAvailability = state.skipperAvailability.filter((skipperAvailability: SkipperAvailability)=> skipperAvailability.id !== action.data.id)
            return {
                ...state,
                skipperAvailability
            }
        case ACTIONS_SKIPPER_AVAILABILITY.DELETE_SKIPPER_AVAILABILITY_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_SKIPPER_AVAILABILITY.GET_SKIPPER_AVAILABILITY_SUCCESS:
            return {
                ...state,
                skipperAvailability: action.data
            }
        case ACTIONS_SKIPPER_AVAILABILITY.GET_SKIPPER_AVAILABILITY_FAILURE:
            return {
                ...state,
                error: action.data
            }
        default:
            return state
    }
}

export default skipperAvailability