import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight, faBookmark } from '@fortawesome/free-solid-svg-icons';
import { Vessel, BookingRules, Reservation, Membership, User, Holiday, SplitSlot, WaitingList, VesselBlock, Slot } from '../models/models';
import { months, daysInMonth, isHoliday, getPreviousMonth, getNextMonth } from '../utils/calendar-utils';
import RemoteActions from '../remote/remote-actions';

import { Col, Container, Row } from 'react-bootstrap';
import AvailableCredits, { CalendarScrollState } from '../components/calendar/available-credits';
import { getSlotsData, isValidReservation, UserRoles } from '../utils/utils';
import { SplitCircle } from '../components/calendar/slot-item';
import WaitingSvg from '../assets/img/waiting.svg';
import { lang, selctedLang } from '../lang';
import Clock from '../components/calendar/Clock';
import { clearTimer, setTimer } from '../utils/TimeoutUtils';
import { update } from 'lodash';



interface CalendarMobileProps {
    user: User;
    membership: Membership;
    reservations: Reservation[];
    dispatch: Dispatch;
    refesh: string;
    date: Date;
    holidays: Holiday[];
    waitingList: WaitingList[];
    splitSlots: SplitSlot[];
    vessels: Vessel[];
    onDayPress: (slots: any[]) => void;
    onVesselSelected: (vessel: Vessel) => void;
    siteDisable: boolean;
}

const SELECTED_VESSEL = 'SELECTED_VESSEL'

const CalendarMobile = ({ user, membership, reservations, dispatch, refesh, date, holidays, waitingList, splitSlots, onDayPress, vessels, onVesselSelected, siteDisable }: CalendarMobileProps) => {

    const getSelectedVessel = () => {
        const selectedVessel = localStorage.getItem(SELECTED_VESSEL)

        if (selectedVessel) {
            const stored = JSON.parse(selectedVessel!)
            const _vessel = membership.vessels!.find((_vessel: Vessel) => _vessel.id === stored.id)
            if (_vessel)
                return _vessel!
        }

        if (membership.vessels)
            return membership.vessels[0]
        else
            return undefined
    }

    const [viewDate, setViewDate] = React.useState({ month: date.getMonth(), year: date.getFullYear() })
    const [monthName, setMonthName] = React.useState(months[date.getMonth()])
    const [vessel, setVessel] = React.useState<Vessel | undefined>(getSelectedVessel())
    const [calendarState, setCalendarState] = React.useState<CalendarScrollState>()

    const [selected, setSelected] = React.useState('')

    React.useEffect(() => {
        setViewDate({ month: date.getMonth(), year: date.getFullYear() })
        setMonthName(months[date.getMonth()])
    }, [date])

    React.useEffect(() => {
        if (vessel)
            onVesselSelected(vessel)
    }, [vessel])

    React.useEffect(() => {

        const nowDate = new Date(+viewDate.year, +viewDate.month)
        if (membership) {
            if (membership.creationDate) {
                const startDateParts = membership.creationDate.split('-')
                const startDate = new Date(+startDateParts[0], +startDateParts[1], +startDateParts[2])
                if (nowDate.getTime() < startDate.getTime()) {
                    setViewDate({ month: +startDateParts[1], year: +startDateParts[0] })
                    setMonthName(months[+startDateParts[1] - 1])
                }
            }

            if (membership.endDate) {
                const endDateParts = membership.endDate.split('-')
                const endDate = new Date(+endDateParts[0], +endDateParts[1], +endDateParts[2])
                if (nowDate.getTime() > endDate.getTime()) {
                    setViewDate({ month: +endDateParts[1], year: +endDateParts[0] })
                    setMonthName(months[+endDateParts[1] - 1])
                }
            }

        }
    }, [membership])

    React.useEffect(() => {
        RemoteActions.getServerDate(dispatch)
        RemoteActions.getBookingRules(dispatch)
        RemoteActions.getVessel(dispatch)
    }, [])

    React.useEffect(() => {
        const _vessel = getSelectedVessel()
        setVessel(_vessel)
    }, [vessels])


    const [loading, setLoading] = React.useState(false)
    React.useEffect(() => {
        if (vessel && viewDate) {
            clearTimer()
            setLoading(true)
            setTimer(async () => {
                await RemoteActions.getReservationWithStartMonth(dispatch, `${viewDate.year}-${viewDate.month}`, `${vessel.bookingRules.id}`)
                setLoading(false)
            }, 1000)
        }
    }, [vessel, viewDate])

    const DrawCalendarHeader = () => {
        return (
            <div className="calendar-table__header">
                <div className="calendar-table__row">

                    <div className="calendar-table__col">S</div>
                    <div className="calendar-table__col">T</div>
                    <div className="calendar-table__col">Q</div>
                    <div className="calendar-table__col">Q</div>
                    <div className="calendar-table__col">S</div>
                    <div className="calendar-table__col" style={{ backgroundColor: '#D6D6D6', borderTopLeftRadius: 3, color: '#707070' }}>S</div>
                    <div className="calendar-table__col" style={{ backgroundColor: '#D6D6D6', borderTopRightRadius: 3, color: '#707070' }}>D</div>
                </div>
            </div>
        )
    }

    const checkIfBlock = (yyyy: number, mm: number, dd: number): boolean => {
        const date = new Date(`${yyyy}-${mm}-${dd}`)
        let isBlock = false
        if (vessel!.vesselBlock) {
            vessel!.vesselBlock.forEach((block: VesselBlock) => {
                const startParts = block.startDate.split('-')
                const startDate = new Date(`${startParts[2]}-${startParts[1]}-${startParts[0]}`)

                const endParts = block.endDate.split('-')
                const endDate = new Date(`${endParts[2]}-${endParts[1]}-${endParts[0]}`)

                if (date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime()) {
                    isBlock = true
                }
            })
        }
        return isBlock
    }

    const ShowCalendar = ({ month, year }: { month: number, year: number, bookingRules: BookingRules }) => {

        let firstDay = (new Date(year, month)).getDay();
        firstDay = firstDay === 0 ? 6 : firstDay - 1
        const _document = []

        let _date = 1;
        for (let i = 0; i < 6; i++) {
            let row = [];
            for (let j = 0; j < 7; j++) {

                const holiday = isHoliday(_date, month, year, holidays)
                const holidayStyle = holiday ? { backgroundColor: '#D6D6D6', borderColor: '#fff', borderStyle: 'solid', borderWidth: 0.5, borderRadius: '5%' } : {}
                const style = (j === 5 || j === 6) ? { backgroundColor: '#D6D6D6' } : {}

                if (i === 0 && j < firstDay) {
                    const prev = getPreviousMonth({ month: month + 1, year })

                    const cell = (
                        <div key={`${i}-${j}`} style={{ ...style, display: 'flex', flexDirection: 'column' }} className="calendar-table__col calendar-table__inactive">
                            <div className="calendar-table__item" >
                                <span>{`${(daysInMonth(prev.month, prev.year)) - (firstDay - j - 1)}`}</span>
                            </div>
                            <div key={`${i}-${j}-${0}`} style={{ minHeight: 10, opacity: 1.0 }}></div>
                        </div>
                    )

                    row.push(cell)
                }
                else if (_date > daysInMonth(month + 1, year)) {

                    const cell = (
                        <div key={`${i}-${j}`} style={{ ...style, display: 'flex', flexDirection: 'column' }} className="calendar-table__col calendar-table__inactive"  >
                            <div className="calendar-table__item">
                                <span>{`${-(daysInMonth(month + 1, year) - _date)}`}</span>
                            </div>
                            <div key={`${i}-${j}-${0}`} style={{ minHeight: 10, opacity: 1.0 }}></div>
                        </div>
                    )
                    row.push(cell)
                    _date++;
                }
                else {

                    // const cell = (
                    //     <td key={`${i}-${j}`} style={holiday ? { backgroundColor: 'rgba(223, 242, 192, 1)', color: 'black' } : {}}>
                    //         <div className="date" style={{ textAlign: 'right', fontWeight: 'bold' }}>{`${_date}`}</div>
                    //         {getSlots(_date, month, year, bookingRules, vessel!, reservations)}
                    //     </td>
                    // )

                    const isBlock = checkIfBlock(year, month + 1, _date)

                    const cellType = date.getDate() === _date ? "calendar-table__col calendar-table__today" : "calendar-table__col"

                    let styles = {
                        userReservation: { opacity: 0.0 },
                        waittinglist: { opacity: 0.0 }
                    }

                    const slots: any[] = getSlotsData(user, date, _date, month, year, vessel!, reservations, holidays, splitSlots, waitingList)
                    const asReservation = slots.filter((element: any) => element.reservation || element.isFree)

                    const width = 8;
                    const height = 8;

                    const originalSlots: string[] = []

                    let renderSlots = slots.flatMap((element: any, index: number) => {

                        element.isValidReservation = isValidReservation(element.slot, year, month, _date, holidays, reservations, vessel!, membership, date, user);

                        if (element.isSplit) {
                            if (originalSlots.indexOf(element.isSplit[0].original) >= 0) {
                                return []
                            }
                            originalSlots.push(element.isSplit[0].original)
                        }

                        if (element.reservation) {

                            if ((element.reservation as Reservation).membership.id === membership.id) {
                                styles = { ...styles, userReservation: { opacity: 1.0 } }
                            }

                            if (element.onWaitingList) {
                                styles = { ...styles, waittinglist: { opacity: 0.7 } }
                            }

                            if (element.reservation.free) {
                                if (element.reservation.isSplit) {
                                    return <SplitCircle key={`${i}-${j}-${index}`} width={width} backgroundColor={'#F5C744'} />
                                }
                                return <div key={`${i}-${j}-${index}`} style={{ width, height, borderRadius: 4, backgroundColor: '#F5C744', marginRight: 2, }} />
                            }

                            if (element.isSplit) {
                                return <SplitCircle key={`${i}-${j}-${index}`} width={width} backgroundColor={'#53AFB9'} />
                            }

                            return <div key={`${i}-${j}-${index}`} style={{ width, height, borderRadius: 4, backgroundColor: '#53AFB9', marginRight: 2 }} />
                        }

                        if (element.isFree) {
                            return <div key={`${i}-${j}-${index}`} style={{ width, height, borderRadius: 4, borderWidth: 1, borderStyle: 'solid', backgroundColor: 'transparent', borderColor: '#F5C744', marginRight: 2, }} />
                        }

                        if (asReservation.length) {
                            return <div key={`${i}-${j}-${index}`} style={{ width, height, borderRadius: 4, backgroundColor: 'transparent', marginRight: 2, borderColor: '#53AFB9', borderWidth: 1, borderStyle: 'solid' }} />
                        } else {
                            return <div key={`${i}-${j}-${index}`} style={{ width, height, marginRight: 2, opacity: 0 }} />
                        }

                    });

                    const isSelected = (selected === `${i}-${j}`) ? `calendar-table__item selectedCalendarItem` : `calendar-table__item`

                    const blockStyle = isBlock ? { color: '#bfbfbf' } : {}

                    const cell = (

                        <div key={`${i}-${j}`} className={cellType} style={{ ...style, ...holidayStyle, ...blockStyle, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} onClick={() => {
                            if (!isBlock) {
                                setSelected(`${i}-${j}`)
                                onDayPress(slots)
                            }
                        }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className={isSelected} style={{ width: 25, height: 25, marginBottom: 2, textAlign: 'center', ...blockStyle }}>
                                    {`${_date}`}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', minHeight: 10 }}>
                                    {renderSlots}
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', height: '100%', paddingTop: 5 }}>
                                <FontAwesomeIcon style={{ ...styles.userReservation, width: 13, height: 13 }} icon={faBookmark} color='#53AFB9' />
                                <img src={WaitingSvg} style={{ ...styles.waittinglist, width: 13, height: 13 }} />
                            </div>

                        </div>
                    )

                    row.push(cell)
                    _date++;
                }



            }
            _document.push((<div key={`${i}`} className="calendar-table__row">{row}</div>))
        }
        return (
            <div className="calendar-table__body">
                {_document}
            </div>
        )
    }


    return (
        <Container >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 10 }}>
                <div style={{ textAlign: 'start', marginRight: 10 }}>{lang[selctedLang].vessel}</div>
                <div>

                    <select id="combo" onChange={(event) => {
                        const _vessel: Vessel = JSON.parse(event.target.value)
                        setVessel(_vessel)
                        localStorage.setItem(SELECTED_VESSEL, event.target.value)
                    }}>
                        {membership.vessels.map((element: any, index: number) => {
                            const isSelected = vessel?.id === element.id
                            return isSelected
                                ?
                                <option key={`${index}`} selected value={JSON.stringify(element)}>{element.name}</option>
                                :
                                <option key={`${index}`} value={JSON.stringify(element)}>{element.name}</option>
                        })}
                    </select>
                </div>
            </div>


            {reservations && date &&
                <AvailableCredits
                    reservations={reservations}
                    month={viewDate.month}
                    year={viewDate.year}
                    bookingRules={vessel!.bookingRules}
                    membership={membership}
                    holidays={holidays}
                    onMonthUpdate={(update: CalendarScrollState) => { setCalendarState(update) }}
                />
            }


            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}>
                    <div className='my-auto'>
                        {(calendarState === CalendarScrollState.NONE || calendarState === CalendarScrollState.END_DATE) && <div style={{ float: 'left' }} onClick={() => {
                            const newData = getPreviousMonth(viewDate)
                            setMonthName(months[newData.month])
                            setViewDate(newData)
                        }}>
                            <FontAwesomeIcon icon={faChevronCircleLeft} size="2x" color='lightGrey' />
                        </div>}
                    </div>

                    <div>
                        <div style={{ textAlign: 'center', fontSize: 30, color: 'grey' }}>{`${monthName} ${viewDate.year}`}</div>
                    </div>

                    <div className='my-auto'>
                        {(calendarState === CalendarScrollState.NONE || calendarState === CalendarScrollState.CREATION_DATE) && <div style={{ float: 'right' }} onClick={() => {
                            const newData = getNextMonth(viewDate)
                            setMonthName(months[newData.month])
                            setViewDate(newData)
                        }}>
                            <FontAwesomeIcon icon={faChevronCircleRight} size="2x" color='lightGrey' />
                        </div>}
                    </div>
                </div>
            </div>

            <div style={{ flexDirection: 'row', justifyContent: 'center', fontWeight: 'bold', flex: 1 }}>
                <div>
                    {!siteDisable && !loading && date && <Clock date={date} slots={[...vessel!.bookingRules.beforeWeekend, ...vessel!.bookingRules.ofWeekend, ...splitSlots.flatMap((split: SplitSlot) => {
                        const slot1: Slot = {
                            name: '',
                            range: split.firstSlotRange
                        }
                        const slot2: Slot = {
                            name: '',
                            range: split.secondSlotRange
                        }
                        return [slot1, slot2]
                    })]}
                        onClockRechTime={() => RemoteActions.getServerDate(dispatch)}
                    />}

                    {loading &&
                        <div style={{ textAlign: 'center', width: '100%' }}>
                            <div className="spinner-border" role="status" >
                                <span className="sr-only">{lang[selctedLang].loading}...</span>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <Row style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="calendar-container__body">
                    <DrawCalendarHeader />
                    <ShowCalendar month={viewDate.month} year={viewDate.year} bookingRules={vessel!.bookingRules} />
                </div>
            </Row>

            {/* <ReservationModal
                user={user}
                show={true}
                slot={{ name: 'Morning', range: '20:00-08:00' }}
                vessel={vessel!}
                membership={membership}
                day={1}
                month={1}
                year={2020}
            /> */}

            <div style={{ opacity: 0.0 }}>{refesh}</div>
        </Container >
    );
};


/**
 * controlles login state
 * @param state
 */
const mapStateToProps = (state: any) => {
    return {
        reservations: state.reservations.reservations,
        refesh: `${Date.now()}`,
        date: state.reservations.date,
        holidays: state.holiday.holiday,
        waitingList: state.waitingList.waitingList,
        splitSlots: state.splitSlot.splitSlot,
        vessels: state.vessels.vessels,
        siteDisable: state.login.siteDisable
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarMobile);

