
import { QuestionGroup } from "../../../models/models"
import { ACTIONS_QUESTION_GROUP } from "../../actions/check-form/group"


interface IState {
    questionGroup: QuestionGroup[],
    error: string
}

export const initialState: IState = { questionGroup: [], error: '' }

export const questionGroup = (state = initialState, action: any) => {
    switch (action.type) {
        case ACTIONS_QUESTION_GROUP.ADD_QUESTION_GROUP_SUCCESS:
            
            const exists = state.questionGroup.findIndex(x => x.id===action.data.id)
            if(exists > -1){
                state.questionGroup[exists] = action.data as QuestionGroup
            }else{
                state.questionGroup.push(action.data as QuestionGroup)
            }

            return {
                ...state,
            }
        case ACTIONS_QUESTION_GROUP.ADD_QUESTION_GROUP_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_QUESTION_GROUP.DELETE_QUESTION_GROUP_SUCCESS:
            const questionGroup = state.questionGroup.filter((questionGroup: QuestionGroup)=> questionGroup.id !== action.data.id)
            return {
                ...state,
                questionGroup
            }
        case ACTIONS_QUESTION_GROUP.DELETE_QUESTION_GROUP_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_QUESTION_GROUP.GET_QUESTION_GROUP_SUCCESS:
            return {
                ...state,
                questionGroup: action.data
            }
        case ACTIONS_QUESTION_GROUP.GET_QUESTION_GROUP_FAILURE:
            return {
                ...state,
                error: action.data
            }
        default:
            return state
    }
}

export default questionGroup