import { ACTIONS_DL } from "../actions/dl"
import { DL } from "../../models/models"

interface IState {
    dls: DL[],
    error: string
}

export const initialState: IState = { dls: [], error: '' }

export const dls = (state = initialState, action: any) => {
    switch (action.type) {
        case ACTIONS_DL.ADD_DLS_SUCCESS:

            const exists = state.dls.findIndex(x => x.id===action.data.id)
            if(exists > -1){
                state.dls[exists] = action.data as DL
            }else{
                state.dls.push(action.data as DL)
            }

            return {
                ...state,
            }
        case ACTIONS_DL.ADD_DLS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_DL.DELETE_DLS_SUCCESS:
            const index = state.dls.findIndex(x => x.id===action.data.id)
            state.dls.splice(index, 1)
            return {
                ...state,
            }
        case ACTIONS_DL.DELETE_DLS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_DL.GET_DLS_SUCCESS:
            return {
                ...state,
                dls: action.data
            }
        case ACTIONS_DL.GET_DLS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        default:
            return state
    }
}

export default dls