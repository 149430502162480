import API, { REMOTE_SERVICES } from "./api"
import { API_PATH } from './api'
import { LOGIN_SUCCESS, LOGIN_FAILURE, SIGNUP_SUCCESS, SIGNUP_FAILURE, REFRESH_USER_MEMBERSHIPS_SUCCESS, REFRESH_USER_MEMBERSHIPS_FAILURE, DISABLE_SITE, DISABLE_SITE_FAILURE } from "../redux/actions/auth"
import { Slot, User, BookingRules, SkipperAccount, SkipperAvailability, Vessel, Holiday, Reservation, ReservationSkipper, Membership, MembershipType, WaitingList, Question, QuestionGroup, QuestionAnswer, CheckForm, Message, SplitSlot, DL, VesselBlock } from "../models/models"
import { ADD_SLOTS_SUCCESS, ADD_SLOTS_FAILURE, GET_SLOTS_SUCCESS, GET_SLOTS_FAILURE, DELETE_SLOTS_SUCCESS, DELETE_SLOTS_FAILURE } from "../redux/actions/slots"
import { ADD_MEMBERSHIPS_SUCCESS, ADD_MEMBERSHIPS_FAILURE, GET_MEMBERSHIPS_SUCCESS, GET_MEMBERSHIPS_FAILURE, DELETE_MEMBERSHIPS_SUCCESS, DELETE_MEMBERSHIPS_FAILURE } from "../redux/actions/membership"
import { ADD_BOOKING_RULES_SUCCESS, ADD_BOOKING_RULES_FAILURE, GET_BOOKING_RULES_SUCCESS, GET_BOOKING_RULES_FAILURE, DELETE_BOOKING_RULES_SUCCESS, DELETE_BOOKING_RULES_FAILURE, GET_BOOKING_RULES_CHECK_FORM_FAILURE, GET_BOOKING_RULES_CHECK_FORM_SUCCESS } from "../redux/actions/booking-rules"
import { ADD_SKIPPER_ACCOUNT_SUCCESS, ADD_SKIPPER_ACCOUNT_FAILURE, GET_SKIPPER_ACCOUNT_SUCCESS, GET_SKIPPER_ACCOUNT_FAILURE, DELETE_SKIPPER_ACCOUNT_SUCCESS, DELETE_SKIPPER_ACCOUNT_FAILURE } from "../redux/actions/skipper-account"
import { ADD_SKIPPER_AVAILABILITY_SUCCESS, ADD_SKIPPER_AVAILABILITY_FAILURE, GET_SKIPPER_AVAILABILITY_SUCCESS, GET_SKIPPER_AVAILABILITY_FAILURE, DELETE_SKIPPER_AVAILABILITY_SUCCESS, DELETE_SKIPPER_AVAILABILITY_FAILURE } from "../redux/actions/skipper-availability"
import { ADD_VESSELS_SUCCESS, ADD_VESSELS_FAILURE, GET_VESSELS_SUCCESS, GET_VESSELS_FAILURE, DELETE_VESSELS_SUCCESS, DELETE_VESSELS_FAILURE } from "../redux/actions/vessel"
import { ADD_HOLIDAYS_SUCCESS, ADD_HOLIDAYS_FAILURE, GET_HOLIDAYS_SUCCESS, GET_HOLIDAYS_FAILURE, DELETE_HOLIDAYS_SUCCESS, DELETE_HOLIDAYS_FAILURE } from "../redux/actions/holidays"
import { ADD_RESERVATIONS_SUCCESS, ADD_RESERVATIONS_FAILURE, GET_RESERVATIONS_SUCCESS, GET_RESERVATIONS_FAILURE, DELETE_RESERVATIONS_SUCCESS, DELETE_RESERVATIONS_FAILURE, GET_SERVER_DATE_SUCCESS, GET_SERVER_DATE_FAILURE } from "../redux/actions/reservation"
import { ADD_RESERVATION_SKIPPERS_SUCCESS, ADD_RESERVATION_SKIPPERS_FAILURE, GET_RESERVATION_SKIPPERS_SUCCESS, GET_RESERVATION_SKIPPERS_FAILURE, DELETE_RESERVATION_SKIPPERS_SUCCESS, DELETE_RESERVATION_SKIPPERS_FAILURE } from "../redux/actions/reservation-skipper"
import { ADD_MEMBERSHIP_TYPE_SUCCESS, ADD_MEMBERSHIP_TYPE_FAILURE, GET_MEMBERSHIP_TYPE_SUCCESS, GET_MEMBERSHIP_TYPE_FAILURE, DELETE_MEMBERSHIP_TYPE_SUCCESS, DELETE_MEMBERSHIP_TYPE_FAILURE } from "../redux/actions/membership-type"
import { ADD_USERS_SUCCESS, ADD_USERS_FAILURE, GET_USERS_SUCCESS, GET_USERS_FAILURE, DELETE_USERS_SUCCESS, DELETE_USERS_FAILURE } from "../redux/actions/users"
import { ADD_WAITING_LIST_SUCCESS, ADD_WAITING_LIST_FAILURE, GET_WAITING_LIST_SUCCESS, GET_WAITING_LIST_FAILURE, DELETE_WAITING_LIST_SUCCESS, DELETE_WAITING_LIST_FAILURE } from "../redux/actions/waiting-list"
import { ADD_QUESTION_SUCCESS, ADD_QUESTION_FAILURE, GET_QUESTION_SUCCESS, GET_QUESTION_FAILURE, DELETE_QUESTION_SUCCESS, DELETE_QUESTION_FAILURE } from "../redux/actions/check-form/question"
import { ADD_QUESTION_GROUP_SUCCESS, ADD_QUESTION_GROUP_FAILURE, GET_QUESTION_GROUP_SUCCESS, GET_QUESTION_GROUP_FAILURE, DELETE_QUESTION_GROUP_SUCCESS, DELETE_QUESTION_GROUP_FAILURE } from "../redux/actions/check-form/group"
import { ADD_ANSWER_SUCCESS, ADD_ANSWER_FAILURE, GET_ANSWER_SUCCESS, GET_ANSWER_FAILURE, DELETE_ANSWER_SUCCESS, DELETE_ANSWER_FAILURE } from "../redux/actions/check-form/answer"
import { ADD_CHECK_FORM_SUCCESS, ADD_CHECK_FORM_FAILURE, GET_CHECK_FORM_SUCCESS, GET_CHECK_FORM_FAILURE, DELETE_CHECK_FORM_SUCCESS, DELETE_CHECK_FORM_FAILURE } from "../redux/actions/check-form/check-form"
import { ADD_MESSAGES_SUCCESS, ADD_MESSAGES_FAILURE, GET_MESSAGES_SUCCESS, GET_MESSAGES_FAILURE, DELETE_MESSAGES_SUCCESS, DELETE_MESSAGES_FAILURE } from "../redux/actions/messages"
import { ADD_SPLIT_SLOTS_SUCCESS, ADD_SPLIT_SLOTS_FAILURE, GET_SPLIT_SLOTS_SUCCESS, GET_SPLIT_SLOTS_FAILURE, DELETE_SPLIT_SLOTS_SUCCESS, DELETE_SPLIT_SLOTS_FAILURE } from "../redux/actions/split-slot"
import { ADD_DLS_FAILURE, ADD_DLS_SUCCESS, DELETE_DLS_FAILURE, DELETE_DLS_SUCCESS, GET_DLS_FAILURE, GET_DLS_SUCCESS } from "../redux/actions/dl"
import axios from 'axios'
var md5 = require('md5');



export class RemoteActions {

    public static login = async (user: User, dispatch: any) => {

        let _user = { ...user }
        _user.password = md5(user.password)
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.LOGIN, _user, {})
                localStorage.setItem('user', JSON.stringify(resp.data))
                dispatch(LOGIN_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(LOGIN_FAILURE(e))
                reject(e)
            }
        })

    }

    public static disableSite = async (data: { disable: boolean }, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.DISABLE_SITE, data, {})
                dispatch(DISABLE_SITE(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(DISABLE_SITE_FAILURE(e))
                reject(e)
            }
        })

    }



    public static passwordRecovery = async (data: any, dispatch: any) => {

        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.PASSWORD_RECOVERY, data, {})
                resolve()
            }
            catch (e) {
                reject(e)
            }
        })

    }


    public static passwordRecoveryReset = async (data: any) => {
        let _data = { ...data }
        _data.password = md5(data.password)
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.RESET_PASSWORD, _data, {})
                resolve()
            }
            catch (e) {
                reject(e)
            }
        })

    }



    public static signup = async (user: User, dispatch: any) => {
        let _user = { ...user }
        _user.password = md5(user.password)
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.SIGNUP, _user, {})
                dispatch(SIGNUP_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(SIGNUP_FAILURE(e))
                reject(e)
            }
        })

    }


    public static resetPassword = async (user: any, dispatch: any) => {
        let _user = { ...user }
        _user.newPassword = md5(user.newPassword)
        _user.oldPassword = md5(user.oldPassword)

        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.RESET_PASS, _user, {})
                localStorage.setItem('user', JSON.stringify(resp.data))
                dispatch(LOGIN_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(LOGIN_FAILURE(e))
                reject(e)
            }
        })

    }


    public static refreshUserMemberships = async (dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.REFRESH_USER_MEMBERSHIPS)
                dispatch(REFRESH_USER_MEMBERSHIPS_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(REFRESH_USER_MEMBERSHIPS_FAILURE(e))
                reject(e)
            }
        })

    }



    public static setUser = async (user: User, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.USERS, user, {})
                dispatch(ADD_USERS_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                reject(e)
                dispatch(ADD_USERS_FAILURE(e))
            }
        })
    }


    public static getUsers = async (dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.USERS)
                dispatch(GET_USERS_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(GET_USERS_FAILURE(e))
                reject(e)
            }
        })

    }

    public static deleteUser = async (id: any, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await API.makeDelete(API_PATH.USERS, id)
                dispatch(DELETE_USERS_SUCCESS({ id }))
                resolve()
            }
            catch (e) {
                await dispatch(DELETE_USERS_FAILURE(e))
                reject(e)
            }
        })
    }


    public static setSlot = async (message: Slot, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.SLOT, message, {})
                dispatch(ADD_SLOTS_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                reject(e)
                dispatch(ADD_SLOTS_FAILURE(e))
            }
        })
    }


    public static getSlots = async (dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.SLOT)
                dispatch(GET_SLOTS_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(GET_SLOTS_FAILURE(e))
                reject(e)
            }
        })

    }

    public static deleteSlot = async (id: any, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await API.makeDelete(API_PATH.SLOT, id)
                dispatch(DELETE_SLOTS_SUCCESS({ id }))
                resolve()
            }
            catch (e) {
                await dispatch(DELETE_SLOTS_FAILURE(e))
                reject(e)
            }
        })
    }







    public static setMessage = async (slot: Message, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.MESSAGE, slot, {})
                dispatch(ADD_MESSAGES_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                reject(e)
                dispatch(ADD_MESSAGES_FAILURE(e))
            }
        })
    }


    public static getMessages = async (dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.MESSAGE)
                dispatch(GET_MESSAGES_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(LOGIN_FAILURE(e))
                dispatch(GET_MESSAGES_FAILURE(e))
                reject(e)
            }
        })

    }

    public static deleteMessage = async (message: Message, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await API.makeDelete(API_PATH.MESSAGE, message.id)
                dispatch(DELETE_MESSAGES_SUCCESS(message))
                resolve()
            }
            catch (e) {
                await dispatch(DELETE_MESSAGES_FAILURE(e))
                reject(e)
            }
        })
    }


    static uploadFile = async (data: any, callback: any) => {
        return new Promise((resolve, reject) => {
            var headers: any = {
                'Content-Type': 'multipart/form-data',
                'accept': 'application/json'
            }

            axios.post(`${REMOTE_SERVICES.HOST}${REMOTE_SERVICES.PORT}${API_PATH.IMAGE}`,
                data,
                {
                    headers: headers,
                    onUploadProgress: progressEvent => {
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        if (callback)
                            callback(percentCompleted)
                    }
                }
            )
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }



    public static setWaitingList = async (waitingList: WaitingList, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.WAITING_LIST, waitingList, {})
                dispatch(ADD_WAITING_LIST_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                reject(e)
                dispatch(ADD_WAITING_LIST_FAILURE(e))
            }
        })
    }


    public static getWaitingLists = async (dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.WAITING_LIST)
                dispatch(GET_WAITING_LIST_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(GET_WAITING_LIST_FAILURE(e))
                reject(e)
            }
        })

    }

    public static deleteWaitingList = async (id: any, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await API.makeDelete(API_PATH.WAITING_LIST, id)
                dispatch(DELETE_WAITING_LIST_SUCCESS({ id }))
                resolve()
            }
            catch (e) {
                await dispatch(DELETE_WAITING_LIST_FAILURE(e))
                reject(e)
            }
        })
    }




    public static setMembership = async (membership: Membership, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.MEMBERSHIP, membership, {})
                dispatch(ADD_MEMBERSHIPS_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(ADD_MEMBERSHIPS_FAILURE(e))
                reject(e)
            }
        })
    }


    public static getMemberships = async (dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.MEMBERSHIP)
                dispatch(GET_MEMBERSHIPS_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(GET_MEMBERSHIPS_FAILURE(e))
                reject(e)
            }
        })

    }

    public static deleteMembership = async (id: any, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await API.makeDelete(API_PATH.MEMBERSHIP, id)
                dispatch(DELETE_MEMBERSHIPS_SUCCESS({ id }))
                resolve()
            }
            catch (e) {
                dispatch(DELETE_MEMBERSHIPS_FAILURE(e))
                reject(e)
            }
        })
    }

    public static setMembershipType = async (membership: MembershipType, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.MEMBERSHIP_TYPE, membership, {})
                dispatch(ADD_MEMBERSHIP_TYPE_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(ADD_MEMBERSHIP_TYPE_FAILURE(e))
                reject(e)
            }
        })
    }


    public static getMembershipTypes = async (dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.MEMBERSHIP_TYPE)
                dispatch(GET_MEMBERSHIP_TYPE_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(GET_MEMBERSHIP_TYPE_FAILURE(e))
                reject(e)
            }
        })

    }

    public static deleteMembershipType = async (id: any, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await API.makeDelete(API_PATH.MEMBERSHIP_TYPE, id)
                dispatch(DELETE_MEMBERSHIP_TYPE_SUCCESS({ id }))
                resolve()
            }
            catch (e) {
                dispatch(DELETE_MEMBERSHIP_TYPE_FAILURE(e))
                reject(e)
            }
        })
    }


    public static setBookingRules = async (account: BookingRules, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.BOOKING_RULES, account, {})
                dispatch(ADD_BOOKING_RULES_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(ADD_BOOKING_RULES_FAILURE(e))
                reject(e)
            }
        })
    }


    public static getBookingRules = async (dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.BOOKING_RULES)
                dispatch(GET_BOOKING_RULES_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(GET_BOOKING_RULES_FAILURE(e))
                reject(e)
            }
        })
    }

    public static getBookingRulesCheckForms = async (dispatch: any, id: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.BOOKING_RULES_CHECK_FORM, id)
                dispatch(GET_BOOKING_RULES_CHECK_FORM_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(GET_BOOKING_RULES_CHECK_FORM_FAILURE(e))
                reject(e)
            }
        })
    }

    public static deleteBookingRules = async (id: any, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await API.makeDelete(API_PATH.BOOKING_RULES, id)
                dispatch(DELETE_BOOKING_RULES_SUCCESS({ id }))
                resolve()
            }
            catch (e) {
                dispatch(DELETE_BOOKING_RULES_FAILURE(e))
                reject(e)
            }
        })
    }


    public static setSkipperAccount = async (account: User, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.SKIPPER_ACCOUNT, account, {})
                dispatch(ADD_SKIPPER_ACCOUNT_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(ADD_SKIPPER_ACCOUNT_FAILURE(e))
                reject(e)
            }
        })
    }


    public static getSkipperAccount = async (dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.SKIPPER_ACCOUNT)
                dispatch(GET_SKIPPER_ACCOUNT_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(GET_SKIPPER_ACCOUNT_FAILURE(e))
                reject(e)
            }
        })

    }

    public static deleteSkipperAccount = async (id: any, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await API.makeDelete(API_PATH.SKIPPER_ACCOUNT, id)
                dispatch(DELETE_SKIPPER_ACCOUNT_SUCCESS({ id }))
                resolve()
            }
            catch (e) {
                dispatch(DELETE_SKIPPER_ACCOUNT_FAILURE(e))
                reject(e)
            }
        })
    }

    public static setSkipperAvailability = async (account: SkipperAvailability, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.SKIPPER_AVAILABILITY, account, {})
                dispatch(ADD_SKIPPER_AVAILABILITY_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(ADD_SKIPPER_AVAILABILITY_FAILURE(e))
                reject(e)
            }
        })
    }


    public static getSkipperAvailability = async (dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.SKIPPER_AVAILABILITY)
                dispatch(GET_SKIPPER_AVAILABILITY_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(GET_SKIPPER_AVAILABILITY_FAILURE(e))
                reject(e)
            }
        })

    }

    public static deleteSkipperAvailability = async (id: any, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await API.makeDelete(API_PATH.SKIPPER_AVAILABILITY, id)
                dispatch(DELETE_SKIPPER_AVAILABILITY_SUCCESS({ id }))
                resolve()
            }
            catch (e) {
                dispatch(DELETE_SKIPPER_AVAILABILITY_FAILURE(e))
                reject(e)
            }
        })
    }

    public static setVessel = async (account: Vessel, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.VESSEL, account, {})
                dispatch(ADD_VESSELS_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                reject(e)
                dispatch(ADD_VESSELS_FAILURE(e))
            }
        })
    }


    public static getVessel = async (dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.VESSEL)
                dispatch(GET_VESSELS_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(GET_VESSELS_FAILURE(e))
                dispatch(LOGIN_FAILURE(e))
                reject(e)
            }
        })

    }

    public static deleteVessel = async (id: any, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await API.makeDelete(API_PATH.VESSEL, id)
                dispatch(DELETE_VESSELS_SUCCESS({ id }))
                resolve()
            }
            catch (e) {
                dispatch(DELETE_VESSELS_FAILURE(e))
                reject(e)
            }
        })
    }


    public static setHoliday = async (account: Holiday, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.HOLIDAYS, account, {})
                dispatch(ADD_HOLIDAYS_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(ADD_HOLIDAYS_FAILURE(e))
                reject(e)
            }
        })
    }


    public static getHoliday = async (dispatch: any, year: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.HOLIDAYS, year)
                dispatch(GET_HOLIDAYS_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(GET_HOLIDAYS_FAILURE(e))
                reject(e)
            }
        })

    }

    public static deleteHoliday = async (id: any, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await API.makeDelete(API_PATH.HOLIDAYS, id)
                dispatch(DELETE_HOLIDAYS_SUCCESS({ id }))
                resolve()
            }
            catch (e) {
                dispatch(DELETE_HOLIDAYS_FAILURE(e))
                reject(e)
            }
        })
    }


    public static setReservation = async (account: Reservation, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.RESERVATION, account, {})
                dispatch(ADD_RESERVATIONS_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(ADD_RESERVATIONS_FAILURE(e))
                reject(e)
            }
        })
    }


    public static getReservation = async (dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.RESERVATION)
                dispatch(GET_RESERVATIONS_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(LOGIN_FAILURE(e))
                dispatch(GET_RESERVATIONS_FAILURE(e))
                reject(e)
            }
        })

    }

    public static getReservationWithStartMonth = async (dispatch: any, date: string, rules: string) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(`${API_PATH.RESERVATION}/${date}/${rules}`)
                dispatch(GET_RESERVATIONS_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(LOGIN_FAILURE(e))
                dispatch(GET_RESERVATIONS_FAILURE(e))
                reject(e)
            }
        })

    }

    public static deleteReservation = async (id: any, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await API.makeDelete(API_PATH.RESERVATION, id)
                dispatch(DELETE_RESERVATIONS_SUCCESS({ id }))
                resolve()
            }
            catch (e) {
                dispatch(DELETE_RESERVATIONS_FAILURE(e))
                reject(e)
            }
        })
    }


    public static setSplitSlot = async (splitSlot: SplitSlot, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.SPLIT_SLOT, splitSlot, {})
                dispatch(ADD_SPLIT_SLOTS_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(ADD_SPLIT_SLOTS_FAILURE(e))
                reject(e)
            }
        })
    }

    public static getSplitSlot = async (dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.SPLIT_SLOT)
                dispatch(GET_SPLIT_SLOTS_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(GET_SPLIT_SLOTS_FAILURE(e))
                reject(e)
            }
        })

    }

    public static deleteSplitSlot = async (id: any, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await API.makeDelete(API_PATH.SPLIT_SLOT, id)
                dispatch(DELETE_SPLIT_SLOTS_SUCCESS({ id }))
                resolve()
            }
            catch (e) {
                dispatch(DELETE_SPLIT_SLOTS_FAILURE(e))
                reject(e)
            }
        })
    }



    public static getServerDate = async (dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.SERVER_DATE)
                dispatch(GET_SERVER_DATE_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(GET_SERVER_DATE_FAILURE(e))
                reject(e)
            }
        })

    }


    //#region ReservationSkipper
    public static setReservationSkipper = async (account: ReservationSkipper, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.SKIPPER_RESERVATION, account, {})
                dispatch(ADD_RESERVATION_SKIPPERS_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(ADD_RESERVATION_SKIPPERS_FAILURE(e))
                reject(e)
            }
        })
    }


    public static getReservationSkipper = async (dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.SKIPPER_RESERVATION)
                dispatch(GET_RESERVATION_SKIPPERS_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(GET_RESERVATION_SKIPPERS_FAILURE(e))
                reject(e)
            }
        })

    }

    public static deleteReservationSkipper = async (id: any, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await API.makeDelete(API_PATH.SKIPPER_RESERVATION, id)
                dispatch(DELETE_RESERVATION_SKIPPERS_SUCCESS({ id }))
                resolve()
            }
            catch (e) {
                dispatch(DELETE_RESERVATION_SKIPPERS_FAILURE(e))
                reject(e)
            }
        })
    }
    //#endregion


    // check form 
    // QUESTION = '/reservations-management/question',
    // QUESTION_GROUP = '/reservations-management/questionGroup',
    // ANSWER = '/reservations-management/answer',
    // CHECK_FORM = '/reservations-management/checkForm',

    public static sendForm = async (data: { reservations: Reservation[], type: 'checkIn' | 'checkOut' }, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.SEND_FORM, data, {})
                resolve()
            }
            catch (e) {
                reject(e)
            }
        })
    }

    public static setQuestion = async (question: Question, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.QUESTION, question, {})
                dispatch(ADD_QUESTION_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(ADD_QUESTION_FAILURE(e))
                reject(e)
            }
        })
    }


    public static getQuestion = async (dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.QUESTION)
                dispatch(GET_QUESTION_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(GET_QUESTION_FAILURE(e))
                reject(e)
            }
        })

    }

    public static deleteQuestion = async (id: any, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await API.makeDelete(API_PATH.QUESTION, id)
                dispatch(DELETE_QUESTION_SUCCESS({ id }))
                resolve()
            }
            catch (e) {
                dispatch(DELETE_QUESTION_FAILURE(e))
                reject(e)
            }
        })
    }

    public static setQuestionGroup = async (question: QuestionGroup, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.QUESTION_GROUP, question, {})
                dispatch(ADD_QUESTION_GROUP_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(ADD_QUESTION_GROUP_FAILURE(e))
                reject(e)
            }
        })
    }


    public static getQuestionGroup = async (dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.QUESTION_GROUP)
                dispatch(GET_QUESTION_GROUP_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(GET_QUESTION_GROUP_FAILURE(e))
                reject(e)
            }
        })

    }

    public static deleteQuestionGroup = async (id: any, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await API.makeDelete(API_PATH.QUESTION_GROUP, id)
                dispatch(DELETE_QUESTION_GROUP_SUCCESS({ id }))
                resolve()
            }
            catch (e) {
                dispatch(DELETE_QUESTION_GROUP_FAILURE(e))
                reject(e)
            }
        })
    }

    public static setAnswer = async (questionAnswers: QuestionAnswer, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.QUESTION_ANSWER, questionAnswers, {})
                dispatch(ADD_ANSWER_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(ADD_ANSWER_FAILURE(e))
                reject(e)
            }
        })
    }


    public static getAnswer = async (dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.QUESTION_ANSWER)
                dispatch(GET_ANSWER_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(GET_ANSWER_FAILURE(e))
                reject(e)
            }
        })

    }

    public static getAnswersForReservation = async (dispatch: any, id: number) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(`${API_PATH.QUESTION_ANSWER_FOR_RESERVATION}/${id}`)
                dispatch(GET_ANSWER_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(GET_ANSWER_FAILURE(e))
                reject(e)
            }
        })

    }

    public static deleteAnswer = async (id: any, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await API.makeDelete(API_PATH.QUESTION_ANSWER, id)
                dispatch(DELETE_ANSWER_SUCCESS({ id }))
                resolve()
            }
            catch (e) {
                dispatch(DELETE_ANSWER_FAILURE(e))
                reject(e)
            }
        })
    }

    public static setCheckForm = async (checkForm: CheckForm, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.CHECK_FORM, checkForm, {})
                dispatch(ADD_CHECK_FORM_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(ADD_CHECK_FORM_FAILURE(e))
                reject(e)
            }
        })
    }


    public static getCheckForm = async (dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.CHECK_FORM)
                dispatch(GET_CHECK_FORM_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(GET_CHECK_FORM_FAILURE(e))
                reject(e)
            }
        })

    }

    public static deleteCheckForm = async (id: any, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await API.makeDelete(API_PATH.CHECK_FORM, id)
                dispatch(DELETE_CHECK_FORM_SUCCESS({ id }))
                resolve()
            }
            catch (e) {
                dispatch(DELETE_CHECK_FORM_FAILURE(e))
                reject(e)
            }
        })
    }


    public static setDL = async (dl: DL, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makePost(API_PATH.DL, dl, {})
                dispatch(ADD_DLS_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(ADD_DLS_FAILURE(e))
                reject(e)
            }
        })
    }


    public static getDL = async (dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const resp = await API.makeGet(API_PATH.DL)
                dispatch(GET_DLS_SUCCESS(resp.data))
                resolve()
            }
            catch (e) {
                dispatch(GET_DLS_FAILURE(e))
                reject(e)
            }
        })

    }

    public static deleteDL = async (id: any, dispatch: any) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await API.makeDelete(API_PATH.DL, id)
                dispatch(DELETE_DLS_SUCCESS({ id }))
                resolve()
            }
            catch (e) {
                dispatch(DELETE_DLS_FAILURE(e))
                reject(e)
            }
        })
    }


}


export default RemoteActions