export enum ACTIONS_MEMBERSHIP {
    ADD_MEMBERSHIPS_SUCCESS = 'ADD_MEMBERSHIPS_SUCCESS',
    ADD_MEMBERSHIPS_FAILURE = 'ADD_MEMBERSHIPS_FAILURE',

    GET_MEMBERSHIPS_SUCCESS = 'GET_MEMBERSHIPS_SUCCESS',
    GET_MEMBERSHIPS_FAILURE = 'GET_MEMBERSHIPS_FAILURE',

    DELETE_MEMBERSHIPS_SUCCESS = 'DELETE_MEMBERSHIPS_SUCCESS',
    DELETE_MEMBERSHIPS_FAILURE = 'DELETE_MEMBERSHIPS_FAILURE',
}

export const ADD_MEMBERSHIPS_SUCCESS = (data:any) => ({
    type: ACTIONS_MEMBERSHIP.ADD_MEMBERSHIPS_SUCCESS,
    data
})

export const ADD_MEMBERSHIPS_FAILURE = (e:any) => ({
    type: ACTIONS_MEMBERSHIP.ADD_MEMBERSHIPS_FAILURE,
    error: e
})

export const DELETE_MEMBERSHIPS_SUCCESS = (data:any) => ({
    type: ACTIONS_MEMBERSHIP.DELETE_MEMBERSHIPS_SUCCESS,
    data
})

export const DELETE_MEMBERSHIPS_FAILURE = (e:any) => ({
    type: ACTIONS_MEMBERSHIP.DELETE_MEMBERSHIPS_FAILURE,
    error: e
})


export const GET_MEMBERSHIPS_SUCCESS = (data:any) => ({
    type: ACTIONS_MEMBERSHIP.GET_MEMBERSHIPS_SUCCESS,
    data
})

export const GET_MEMBERSHIPS_FAILURE = (e:any) => ({
    type: ACTIONS_MEMBERSHIP.GET_MEMBERSHIPS_FAILURE,
    error: e
})