import * as React from 'react';
import { Modal } from 'react-bootstrap';

interface SelectionModalProps {
    show: boolean;
    handleClose?: () => void;
    Content: any;
}

const SelectionModal = ({ show, handleClose, Content }: SelectionModalProps) => {

    return <Modal
        show={show}
        onHide={handleClose}
        animation={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={true}
        dialogClassName="modal"
    >
        <Modal.Body>
            <Content/>
        </Modal.Body>

    </Modal>
};

export default SelectionModal;

