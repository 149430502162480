export enum ACTIONS_MESSAGES {
    ADD_MESSAGES_SUCCESS = 'ADD_MESSAGES_SUCCESS',
    ADD_MESSAGES_FAILURE = 'ADD_MESSAGES_FAILURE',

    GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS',
    GET_MESSAGES_FAILURE = 'GET_MESSAGES_FAILURE',

    DELETE_MESSAGES_SUCCESS = 'DELETE_MESSAGES_SUCCESS',
    DELETE_MESSAGES_FAILURE = 'DELETE_MESSAGES_FAILURE',
}

export const ADD_MESSAGES_SUCCESS = (data:any) => ({
    type: ACTIONS_MESSAGES.ADD_MESSAGES_SUCCESS,
    data
})

export const ADD_MESSAGES_FAILURE = (e:any) => ({
    type: ACTIONS_MESSAGES.ADD_MESSAGES_FAILURE,
    error: e
})

export const DELETE_MESSAGES_SUCCESS = (data:any) => ({
    type: ACTIONS_MESSAGES.DELETE_MESSAGES_SUCCESS,
    data
})

export const DELETE_MESSAGES_FAILURE = (e:any) => ({
    type: ACTIONS_MESSAGES.DELETE_MESSAGES_FAILURE,
    error: e
})


export const GET_MESSAGES_SUCCESS = (data:any) => ({
    type: ACTIONS_MESSAGES.GET_MESSAGES_SUCCESS,
    data
})

export const GET_MESSAGES_FAILURE = (e:any) => ({
    type: ACTIONS_MESSAGES.GET_MESSAGES_FAILURE,
    error: e
})