import { User } from "../../models/models"
import { ACTIONS_AUTH } from "../actions/auth"
const user = JSON.parse(localStorage.getItem('user') as string)
const siteDisable = JSON.parse(localStorage.getItem('siteDisable') as string)
export const initialState: { loggedIn: boolean, user: User, memberships: [], siteDisable: boolean } = { loggedIn: false, user: user ? user : null, memberships: [], siteDisable }

export const login = (state = initialState, action: any) => {
    switch (action.type) {
        case ACTIONS_AUTH.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.data,
                siteDisable: action.data.disable_site
            }
        case ACTIONS_AUTH.LOGIN_FAILURE:
            return {
                loggedIn: false,
                user: null,
                error: action.error.response.data
            }
        case ACTIONS_AUTH.DISABLE_SITE:
            localStorage.setItem('siteDisable', JSON.stringify(action.data.disable))
            return {
                ...state,
                siteDisable: action.data.disable
            }
        case ACTIONS_AUTH.DISABLE_SITE_FAILURE:
            return {
                ...state
            }
        case ACTIONS_AUTH.LOGOUT:
            return {
                loggedIn: false,
                user: null,
            }

        case ACTIONS_AUTH.SIGNUP_SUCCESS:
            return {
                ...state
            }
        case ACTIONS_AUTH.SIGNUP_FAILURE:
            return {
                ...state,
                error: action.error
            }

        case ACTIONS_AUTH.REFRESH_USER_MEMBERSHIPS_SUCCESS:
            return {
                ...state,
                memberships: action.data.memberships,
            }
        case ACTIONS_AUTH.REFRESH_USER_MEMBERSHIPS_FAILURE:
            return {
                ...state,
                error: action.error
            }
        default:
            return state
    }
}

export default login