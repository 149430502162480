
import { Question } from "../../../models/models"
import { ACTIONS_QUESTION } from "../../actions/check-form/question"

interface IState {
    question: Question[],
    error: string
}

export const initialState: IState = { question: [], error: '' }

export const question = (state = initialState, action: any) => {
    switch (action.type) {
        case ACTIONS_QUESTION.ADD_QUESTION_SUCCESS:
            
            const exists = state.question.findIndex(x => x.id===action.data.id)
            if(exists > -1){
                state.question[exists] = action.data as Question
            }else{
                state.question.push(action.data as Question)
            }

            return {
                ...state,
            }
        case ACTIONS_QUESTION.ADD_QUESTION_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_QUESTION.DELETE_QUESTION_SUCCESS:
            const question = state.question.filter((question: Question)=> question.id !== action.data.id)
            return {
                ...state,
                question
            }
        case ACTIONS_QUESTION.DELETE_QUESTION_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_QUESTION.GET_QUESTION_SUCCESS:
            return {
                ...state,
                question: action.data
            }
        case ACTIONS_QUESTION.GET_QUESTION_FAILURE:
            return {
                ...state,
                error: action.data
            }
        default:
            return state
    }
}

export default question