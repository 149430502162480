

export enum ACTIONS_MEMBERSHIP_TYPE {
    ADD_MEMBERSHIP_TYPE_SUCCESS = 'ADD_MEMBERSHIP_TYPE_SUCCESS',
    ADD_MEMBERSHIP_TYPE_FAILURE = 'ADD_MEMBERSHIP_TYPE_FAILURE',

    GET_MEMBERSHIP_TYPE_SUCCESS = 'GET_MEMBERSHIP_TYPE_SUCCESS',
    GET_MEMBERSHIP_TYPE_FAILURE = 'GET_MEMBERSHIP_TYPE_FAILURE',

    DELETE_MEMBERSHIP_TYPE_SUCCESS = 'DELETE_MEMBERSHIP_TYPE_SUCCESS',
    DELETE_MEMBERSHIP_TYPE_FAILURE = 'DELETE_MEMBERSHIP_TYPE_FAILURE',
}

export const ADD_MEMBERSHIP_TYPE_SUCCESS = (data:any) => ({
    type: ACTIONS_MEMBERSHIP_TYPE.ADD_MEMBERSHIP_TYPE_SUCCESS,
    data
})

export const ADD_MEMBERSHIP_TYPE_FAILURE = (e:any) => ({
    type: ACTIONS_MEMBERSHIP_TYPE.ADD_MEMBERSHIP_TYPE_FAILURE,
    error: e
})

export const DELETE_MEMBERSHIP_TYPE_SUCCESS = (data:any) => ({
    type: ACTIONS_MEMBERSHIP_TYPE.DELETE_MEMBERSHIP_TYPE_SUCCESS,
    data
})

export const DELETE_MEMBERSHIP_TYPE_FAILURE = (e:any) => ({
    type: ACTIONS_MEMBERSHIP_TYPE.DELETE_MEMBERSHIP_TYPE_FAILURE,
    error: e
})


export const GET_MEMBERSHIP_TYPE_SUCCESS = (data:any) => ({
    type: ACTIONS_MEMBERSHIP_TYPE.GET_MEMBERSHIP_TYPE_SUCCESS,
    data
})

export const GET_MEMBERSHIP_TYPE_FAILURE = (e:any) => ({
    type: ACTIONS_MEMBERSHIP_TYPE.GET_MEMBERSHIP_TYPE_FAILURE,
    error: e
})