import * as React from 'react';
import { DynamicFormStyles } from '../dynamic-form';

export const TypeString = ({ label, value, _key, editable, onChange }: any) => {

    const handleChange = (event: any) => {
        onChange({ label: _key, value: event.target.value });
    }

    return (
        <div className="row" key={`${label}_row`} style={{ margin: 5 }}>
            <div className="col-md-4">
                <span className="badge badge-default">{label}</span>
            </div>
            <div className="col-md-6">
                {editable ? <input key={`${label}`} style={DynamicFormStyles.input} type="text" value={value} onChange={handleChange} /> : <span className="badge badge-default">{value}</span>}
            </div>
            <div className="col-md-2">
            </div>
        </div>
    )
}