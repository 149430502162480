export enum ACTIONS_BOOKING_RULES {
    ADD_BOOKING_RULES_SUCCESS = 'USERS_ADD_BOOKING_RULES_SUCCESS',
    ADD_BOOKING_RULES_FAILURE = 'USERS_ADD_BOOKING_RULES_FAILURE',

    GET_BOOKING_RULES_SUCCESS = 'USERS_GET_BOOKING_RULES_SUCCESS',
    GET_BOOKING_RULES_FAILURE = 'USERS_GET_BOOKING_RULES_FAILURE',

    GET_BOOKING_RULES_CHECK_FORM_SUCCESS = 'GET_BOOKING_RULES_CHECK_FORM_SUCCESS',
    GET_BOOKING_RULES_CHECK_FORM_FAILURE = 'GET_BOOKING_RULES_CHECK_FORM_FAILURE',

    DELETE_BOOKING_RULES_SUCCESS = 'USERS_DELETE_BOOKING_RULES_SUCCESS',
    DELETE_BOOKING_RULES_FAILURE = 'USERS_DELETE_BOOKING_RULES_FAILURE',
}

export const ADD_BOOKING_RULES_SUCCESS = (data:any) => ({
    type: ACTIONS_BOOKING_RULES.ADD_BOOKING_RULES_SUCCESS,
    data
})

export const ADD_BOOKING_RULES_FAILURE = (e:any) => ({
    type: ACTIONS_BOOKING_RULES.ADD_BOOKING_RULES_FAILURE,
    error: e
})


export const DELETE_BOOKING_RULES_SUCCESS = (data:any) => ({
    type: ACTIONS_BOOKING_RULES.DELETE_BOOKING_RULES_SUCCESS,
    data
})

export const DELETE_BOOKING_RULES_FAILURE = (e:any) => ({
    type: ACTIONS_BOOKING_RULES.DELETE_BOOKING_RULES_FAILURE,
    error: e
})


export const GET_BOOKING_RULES_SUCCESS = (data:any) => ({
    type: ACTIONS_BOOKING_RULES.GET_BOOKING_RULES_SUCCESS,
    data
})

export const GET_BOOKING_RULES_FAILURE = (e:any) => ({
    type: ACTIONS_BOOKING_RULES.GET_BOOKING_RULES_FAILURE,
    error: e
})

export const GET_BOOKING_RULES_CHECK_FORM_SUCCESS = (data:any) => ({
    type: ACTIONS_BOOKING_RULES.GET_BOOKING_RULES_CHECK_FORM_SUCCESS,
    data
})

export const GET_BOOKING_RULES_CHECK_FORM_FAILURE = (e:any) => ({
    type: ACTIONS_BOOKING_RULES.GET_BOOKING_RULES_FAILURE,
    error: e
})

