import * as React from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Slot, SplitSlot } from '../../models/models';
import { pad } from '../../utils/utils';
import { lang, selctedLang } from '../../lang';
import { themeColor } from '../../utils/calendar-utils';

interface SplitSlotsProps {
    slot: Slot;
    onCancel: () => void;
    onSplit: (splitSlot: any) => void;
}

const SplitSlots = (props: SplitSlotsProps) => {

    const [state, setState] = React.useState({
        firstSlotRange: '',
        secondSlotRange: '',
        dateparts: 0,
        value: 0,
        selectedIndex: 0
    })

    React.useEffect(() => {
        const rangeParts = props.slot.range.split('-')
        updateSlots(rangeParts[0], rangeParts[1], 0)
    }, [])

    const updateSlots = (slotStart: string, slotEnd: string, value: number) => {

        const startParts = slotStart.split(':')
        const endParts = slotEnd.split(':')

        const startDate = new Date()
        startDate.setHours(parseInt(startParts[0]), parseInt(startParts[1]), 0)
        const endStartDate = new Date(startDate.valueOf())
        endStartDate.setHours(endStartDate.getHours() + value + 1)


        const startEndDate = new Date(endStartDate.valueOf())
        startEndDate.setHours(startEndDate.getHours() + 1)

        const endDate = new Date()
        endDate.setHours(parseInt(endParts[0]), parseInt(endParts[1]), 0)

        if (endDate.getTime() < startEndDate.getTime()) {
            endDate.setDate(endDate.getDate() + 1)
        }


        const dateHours = ((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60)).toFixed(1)
        let _state = {
            ...state,
            firstSlotRange: `${pad(startDate.getHours(), 2)}:${pad(startDate.getMinutes(), 2)}-${pad(endStartDate.getHours(), 2)}:${pad(endStartDate.getMinutes(), 2)}`,
            secondSlotRange: `${pad(startEndDate.getHours(), 2)}:${pad(startEndDate.getMinutes(), 2)}-${pad(endDate.getHours(), 2)}:${pad(endDate.getMinutes(), 2)}`,
            value
        }

        if (!state.dateparts) {
            _state.dateparts = (+dateHours - 3)
        }

        setState(_state)

        const splits = [_state.firstSlotRange, _state.secondSlotRange]
        props.onSplit({
            selected: splits[state.selectedIndex],
            slot: props.slot.range,
            firstSlotRange: _state.firstSlotRange,
            secondSlotRange: _state.secondSlotRange,
        })
    }


    const updateSelected = (selectedIndex: number) => {
        setState({ ...state, selectedIndex })

        const splits = [state.firstSlotRange, state.secondSlotRange]
        props.onSplit({
            selected: splits[state.selectedIndex],
            slot: props.slot.range,
            firstSlotRange: state.firstSlotRange,
            secondSlotRange: state.secondSlotRange,
        })
    }


    return (
        <div style={{ marginBottom: 15 }}>

            <div>
                <Row style={{ marginBottom: 15, marginTop: 15 }}>
                    <Col style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5, marginBottom: 5, color: 'black', textDecoration: 'none' }} onClick={() => updateSelected(0)}>
                        <input checked={state.selectedIndex === 0} type="radio" value={state.firstSlotRange} name="split" onChange={() => { }} />
                        <div style={{ marginLeft: 5 }}>
                            {state.firstSlotRange}
                        </div>
                    </Col>
                </Row>

                <Row style={{ marginBottom: 15, marginTop: 15 }} >
                    <Col style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 15, color: 'black', textDecoration: 'none' }} onClick={() => updateSelected(1)}>
                        <input checked={state.selectedIndex === 1} type="radio" value={state.secondSlotRange} name="split" onChange={() => { }} />
                        <div style={{ marginLeft: 5 }}>
                            {state.secondSlotRange}
                        </div>
                    </Col>
                </Row>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <RangeSlider
                    color={themeColor}
                    className='slider'
                    style={{ width: '100%' }}
                    value={state.value}
                    min={0}
                    max={state.dateparts}
                    size={'lg'}
                    tooltip={'off'}
                    onChange={(changeEvent: any) => {
                        const rangeParts = props.slot.range.split('-')
                        updateSlots(rangeParts[0], rangeParts[1], +changeEvent.target.value)
                    }}
                />
            </div>
        </div>
    );
};

export default SplitSlots;

const styles = ({
    container: {}
});
