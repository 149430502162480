import { Reservation, BookingRules, Membership, Holiday } from "../../models/models";
import React from "react";
import { avaliableCredits, DayType } from "../../utils/reservation-utils";
import { Row, Col, Table, Container } from "react-bootstrap";
import membershipType from "../../screens/membership/membership-type";
import bookingRules from "../membership/booking-rules";
import { lang, selctedLang } from "../../lang";

interface BriefProps {
    reservations: Reservation[], year: number, month: number, bookingRules: BookingRules, membership: Membership, holidays: Holiday[]
}

class Brief extends React.Component<BriefProps, { [k: string]: any }> {
    constructor(props: any) {
        super(props)
    }

    GetAvailablecreditsTable = ({ _availableSlots }: { _availableSlots: any[] }) => {
        let weekendSum = 0
        let workingdaySum = 0

        let weekendString = '('
        let workingdayString = '('

        for (let index = 0; index < _availableSlots.length; index++) {
            const element = _availableSlots[index];
            if (index > 0) {
                weekendString = weekendString + '+'
                workingdayString = workingdayString + '+'
            }
            weekendString = weekendString + element[DayType.WEEK_END]
            workingdayString = workingdayString + element[DayType.WORKING_DAY]

            weekendSum = weekendSum + +element[DayType.WEEK_END]
            workingdaySum = workingdaySum + +element[DayType.WORKING_DAY]
        }

        weekendString = weekendString + ')'
        workingdayString = workingdayString + ')'

        const currentMonthIndex = _availableSlots.findIndex((element: any) => element.date === `${this.props.year}-${this.props.month}`)

        const _reservations = this.props.reservations?.filter((reservation: Reservation) => {
            const dateParts = reservation.date.split('-')
            if (
                reservation.membership.id === this.props.membership.id &&
                this.props.year === +dateParts[0] &&
                this.props.month === +dateParts[1] &&
                reservation.free
            )
                return reservation
        })

        const workingDay = _reservations.filter((reservation: Reservation) => {

        })

        return (

            <Table bordered className="table-striped" id="Available_credits_for_this_month">
                <thead>
                    <tr>
                        <th style={{ textAlign: 'center', backgroundColor: '#343a40', color: 'white' }} colSpan={6}>{lang[selctedLang].usedCreditsThisMonth}</th>
                    </tr>
                    <tr>
                        <th rowSpan={2}></th>
                        <th rowSpan={2}>{lang[selctedLang].currentMonth}</th>
                        <th colSpan={2}>{lang[selctedLang].borrowCredits}</th>
                        <th rowSpan={2}>{lang[selctedLang].free}</th>
                        <th rowSpan={2}>{lang[selctedLang].total}</th>
                    </tr>
                    <tr>
                        <th>{lang[selctedLang].previousMonth}</th>
                        <th>{lang[selctedLang].nextMonth}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{lang[selctedLang].workingdaySlots}</td>
                        <td>{this.props.membership.membershipType.weekSlots - _availableSlots[currentMonthIndex].WORKING_DAY}</td>
                        <td>{currentMonthIndex ? this.props.membership.membershipType.weekSlots - _availableSlots[currentMonthIndex - 1].WORKING_DAY : 0}</td>
                        <td>{this.props.membership.membershipType.weekSlots - _availableSlots[currentMonthIndex + 1].WORKING_DAY}</td>
                        <td>0</td>
                        <td>{((this.props.membership.membershipType.weekSlots - _availableSlots[currentMonthIndex].WORKING_DAY) + (currentMonthIndex ? (this.props.membership.membershipType.weekSlots - _availableSlots[currentMonthIndex - 1].WORKING_DAY) : 0) + (this.props.membership.membershipType.weekSlots - _availableSlots[currentMonthIndex + 1].WORKING_DAY))}</td>
                    </tr>
                    <tr>
                        <td>{lang[selctedLang].weekendSlots}</td>
                        <td>{this.props.membership.membershipType.weekendSlots - _availableSlots[currentMonthIndex].WEEK_END}</td>
                        <td>{currentMonthIndex ? this.props.membership.membershipType.weekendSlots - _availableSlots[currentMonthIndex - 1].WEEK_END : 0}</td>
                        <td>{this.props.membership.membershipType.weekendSlots - _availableSlots[currentMonthIndex + 1].WEEK_END}</td>
                        <td>0</td>
                        <td>{((this.props.membership.membershipType.weekendSlots - _availableSlots[currentMonthIndex].WEEK_END) + (currentMonthIndex ? (this.props.membership.membershipType.weekendSlots - _availableSlots[currentMonthIndex - 1].WEEK_END) : 0) + (this.props.membership.membershipType.weekendSlots - _availableSlots[currentMonthIndex + 1].WEEK_END))}</td>
                    </tr>

                    <tr>
                        <td>{lang[selctedLang].total}</td>
                        <td>{(this.props.membership.membershipType.weekSlots - _availableSlots[currentMonthIndex].WORKING_DAY) + (this.props.membership.membershipType.weekendSlots - _availableSlots[currentMonthIndex].WEEK_END)}</td>
                        <td>{currentMonthIndex ? (this.props.membership.membershipType.weekSlots - _availableSlots[currentMonthIndex - 1].WORKING_DAY) + (this.props.membership.membershipType.weekendSlots - _availableSlots[currentMonthIndex - 1].WEEK_END) : 0}</td>
                        <td>{(this.props.membership.membershipType.weekSlots - _availableSlots[currentMonthIndex + 1].WORKING_DAY) + (this.props.membership.membershipType.weekendSlots - _availableSlots[currentMonthIndex + 1].WEEK_END)}</td>
                        <td>0</td>
                        <td>
                            {
                                ((this.props.membership.membershipType.weekSlots - _availableSlots[currentMonthIndex].WORKING_DAY) + (this.props.membership.membershipType.weekendSlots - _availableSlots[currentMonthIndex].WEEK_END)) +
                                (currentMonthIndex ? (this.props.membership.membershipType.weekSlots - _availableSlots[currentMonthIndex - 1].WORKING_DAY) + (this.props.membership.membershipType.weekendSlots - _availableSlots[currentMonthIndex - 1].WEEK_END) : 0) +
                                ((this.props.membership.membershipType.weekSlots - _availableSlots[currentMonthIndex + 1].WORKING_DAY) + (this.props.membership.membershipType.weekendSlots - _availableSlots[currentMonthIndex + 1].WEEK_END))
                            }
                        </td>
                    </tr>
                </tbody>
            </Table>

        )
    }

    render() {
        const { reservations, year, month, bookingRules, membership, holidays } = this.props
        const _avaliable: any[] = avaliableCredits(reservations, year, month, bookingRules, membership, holidays)
        return (
            <Row>
                <Col><this.GetAvailablecreditsTable _availableSlots={_avaliable} /></Col>
            </Row>
        )
    }
}


export default Brief;