export enum ACTIONS_QUESTION_GROUP {
    ADD_QUESTION_GROUP_SUCCESS = 'ADD_QUESTION_GROUP_SUCCESS',
    ADD_QUESTION_GROUP_FAILURE = 'ADD_QUESTION_GROUP_FAILURE',

    GET_QUESTION_GROUP_SUCCESS = 'GET_QUESTION_GROUP_SUCCESS',
    GET_QUESTION_GROUP_FAILURE = 'GET_QUESTION_GROUP_FAILURE',

    DELETE_QUESTION_GROUP_SUCCESS = 'DELETE_QUESTION_GROUP_SUCCESS',
    DELETE_QUESTION_GROUP_FAILURE = 'DELETE_QUESTION_GROUP_FAILURE',
}

export const ADD_QUESTION_GROUP_SUCCESS = (data:any) => ({
    type: ACTIONS_QUESTION_GROUP.ADD_QUESTION_GROUP_SUCCESS,
    data
})

export const ADD_QUESTION_GROUP_FAILURE = (e:any) => ({
    type: ACTIONS_QUESTION_GROUP.ADD_QUESTION_GROUP_FAILURE,
    error: e
})

export const DELETE_QUESTION_GROUP_SUCCESS = (data:any) => ({
    type: ACTIONS_QUESTION_GROUP.DELETE_QUESTION_GROUP_SUCCESS,
    data
})

export const DELETE_QUESTION_GROUP_FAILURE = (e:any) => ({
    type: ACTIONS_QUESTION_GROUP.DELETE_QUESTION_GROUP_FAILURE,
    error: e
})


export const GET_QUESTION_GROUP_SUCCESS = (data:any) => ({
    type: ACTIONS_QUESTION_GROUP.GET_QUESTION_GROUP_SUCCESS,
    data
})

export const GET_QUESTION_GROUP_FAILURE = (e:any) => ({
    type: ACTIONS_QUESTION_GROUP.GET_QUESTION_GROUP_FAILURE,
    error: e
})