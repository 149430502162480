import * as React from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faPlusCircle, faSave, faBan } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button } from 'react-bootstrap';
import { months } from '../../utils/calendar-utils';
import membership from '../../redux/reduceres/membership';
import SplitSlots from '../split-slots/split-slots';
import { getStyle, AVAILABLE } from '../calendar/slot-types/slot-type-interface';
import RemoteActions from '../../remote/remote-actions';
interface ItemsListProps {
    style?: any;
    list: any[];
    onSelectItem: (item: any) => void;
    onEditItem: (item: any) => Promise<any>;
    onDeleteItem: (item: any) => Promise<any>;
    listTemplate: (item: any) => any;
    newItemFormTemplate: ({ item, editing, onChange }: { item: any, editing: boolean, onChange: any }) => JSX.Element;
    dataTemplate: any;
    disableNew?: boolean;
    disableUpdate?: boolean;
    disableDelete?: boolean;
}


interface ItemsListState {
    selected: object | null;
    editing: boolean;
    error: string | null;
    showDialog: boolean;
}


class ItemsList extends React.Component<ItemsListProps, ItemsListState> {
    constructor(props: any) {
        super(props)
        this.state = {
            selected: null,
            editing: false,
            error: null,
            showDialog: false,
        }
    }

    ListItems = (list: any[]) => {
        return list.map((element: any) => {
            return (
                <button key={`${element.id}`} style={{ float: 'left' }} className="list-group-item list-group-item-action list-group-item-light" onClick={() => {
                    this.props.onSelectItem(element)
                    this.setState({ selected: element })
                }}>
                    {this.props.listTemplate(element)}
                </button>
            )
        })
    }

    ListMenu = () => (
        <div>

            {!this.props.disableNew && <button style={{ float: 'right', margin: 4 }} type="button" className="btn btn-primary" onClick={() => {
                this.setState({ selected: this.props.dataTemplate })
                this.setState({ editing: !this.state.editing })
            }} >
                <FontAwesomeIcon icon={faPlusCircle} />
            </button>}

            {!this.props.disableUpdate && this.state.selected && <button style={{ float: 'right', margin: 4 }} type="button" className="btn btn-secondary" onClick={() => {
                this.setState({ editing: !this.state.editing })
            }} >
                <FontAwesomeIcon icon={faEdit} />
            </button>}
            {!this.props.disableDelete && this.state.selected && <button style={{ float: 'right', margin: 4 }} type="button" className="btn btn-danger" onClick={() => this.setState({ showDialog: true })} >
                <FontAwesomeIcon icon={faTrash} />
            </button>}
            {this.ListItems(this.props.list)}
        </div>
    )


    ModalComponent = () => (
        <Modal show={this.state.showDialog} animation={true} onHide={() => this.setState({ showDialog: false })}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Delete confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Delete this item?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => this.setState({ showDialog: false })}>
                    Close
                    </Button>
                <Button variant="primary" onClick={() => {
                    this.props.onDeleteItem(this.state.selected)
                    this.setState({ showDialog: false })
                }}>
                    Delete
                    </Button>
            </Modal.Footer>
        </Modal>
    )

    updateSelected = (item: any) => {
        const selected = ({ ...this.state.selected, ...item })
        this.setState({ selected })
    }

    EditItemForm = () => {
        const NewItemFormTemplate = this.props.newItemFormTemplate
        return (
            <div>
                <NewItemFormTemplate item={this.state.selected} editing={this.state.editing} onChange={this.updateSelected} />

                <button style={{ margin: 20, float: "right" }} type="button" className="btn btn-primary" onClick={async () => {
                    try {
                        await this.props.onEditItem({ ...this.state.selected })
                        this.setState({ editing: !this.state.editing })
                    } catch (e) {
                        this.setState({ error: e.toString() })
                    }
                }} >
                    <div style={{ marginRight: 4 }}>
                        Save
                        <FontAwesomeIcon icon={faSave} style={{ marginLeft: 4 }} />
                    </div>

                </button>

                <button style={{ margin: 20, float: "right" }} type="button" className="btn btn-primary" onClick={async () => {
                    this.setState({ editing: !this.state.editing })
                }} >
                    <div style={{ marginRight: 4 }}>
                        Cancel
                        <FontAwesomeIcon icon={faBan} style={{ marginLeft: 4 }} />
                    </div>

                </button>
            </div>
        )
    }

    ErrorMessage = () => (
        <div className="alert alert-danger" role="alert">
            {this.state.error}
        </div>
    )

    render() {
        return (
            <div className="list-group" style={{ paddingLeft: 10, paddingRight: 10, ...this.props.style }} >
                {
                    !this.state.editing
                        ? <this.ListMenu />
                        : <this.EditItemForm />
                }
                {this.state.error && (<this.ErrorMessage />)}
                {this.ModalComponent()}
            </div >
        );
    }

};

export default ItemsList;

const styles = ({
    container: {}
});
