import * as React from 'react';
import { RemoteActions } from '../../remote/remote-actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux'
import ItemsList from '../../components/generic/items-list';
import { Membership, Slot, BookingRules, Holiday, Vessel, User, MembershipType } from '../../models/models';
import DynamicForm from '../../components/generic/dynamic-form/dynamic-form';
import { TypeArray } from '../../components/generic/dynamic-form/types/type-array';
import SwitchList from '../../components/generic/switch-list';
import { UserRoles } from '../../utils/utils';

interface MembershipScreenProps {
    dispatch: Dispatch;
    membership: Membership[];
    membershipTypes: MembershipType[];
    vessels: Vessel[];
    users: User[];
    refesh: string;
}


const MembershipScreen: React.FC<any> = ({ dispatch, membership, refesh, vessels, users, membershipTypes }: MembershipScreenProps) => {

    React.useEffect(() => {
        RemoteActions.getMemberships(dispatch)
        RemoteActions.getMembershipTypes(dispatch)
        RemoteActions.getUsers(dispatch)
        RemoteActions.getVessel(dispatch)
    }, [])


    const changeForm = ({ item, editing, onChange }: { item: Membership, editing: boolean, onChange: any }) => {
        return (

            <div key={`add_item_form`}>

                <DynamicForm
                    key={'DynamicForm'}
                    data={item}
                    labels={{
                        title: 'Título',
                        creationDate: 'Data de ínicio',
                        endDate: 'Data de fim',
                        vessels: 'Barcos',
                        users: 'Utilizadores',
                        membershipType: 'Tipo de conta',
                    }}
                    editable={editing}
                    onDataChange={onChange}
                />

                <div style={{ borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid', padding: 10, margin: 10 }}>
                    <div style={{ fontWeight: 'bold', padding: 15 }}>Tipo de conta</div>
                    <select id="combo" value={item.membershipType ? JSON.stringify(item.membershipType) : membershipTypes[0] ? JSON.stringify(membershipTypes[0]) : undefined} onChange={(event) => {
                        onChange({ membershipType: JSON.parse(event.target.value) })
                    }}>
                        {membershipTypes.map((element: MembershipType, index: number) => {
                            return <option key={`${index}`} value={JSON.stringify(element)}>
                                {element.name} - Slots de semana: {element.weekSlots} - Slots fim de semana: {element.weekendSlots}
                            </option>
                        })}
                    </select>

                </div>

                <div style={{ borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid', padding: 10, margin: 10 }}>
                    <div style={{ fontWeight: 'bold', padding: 15 }}>Barcos</div>
                    <SwitchList
                        field={'vessels'}
                        leftListdata={vessels}
                        rightListdata={item.vessels}
                        onSelectedListChange={onChange}
                        listTemplate={((item: Vessel) => (
                            <div className="">
                                <div className="col-md-8">
                                    <span className="">{`${item.name}`}</span>
                                </div>
                            </div>
                        ))}
                    />
                </div>

                <div style={{ borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid', padding: 10, margin: 10 }}>
                    <div style={{ fontWeight: 'bold', padding: 15 }}>Utilizadores</div>
                    <SwitchList
                        field={'users'}
                        leftListdata={users.filter((user: User) => user.role !== UserRoles.SKIPPER)}
                        rightListdata={item.users}
                        onSelectedListChange={onChange}
                        listTemplate={((item: User) => (
                            <div className="">
                                <div className="col-md-8">
                                    <span className="">{`${item.lastName}`}, {`${item.firstName}`}</span>
                                </div>
                                <div className="col-md-4">
                                    <span className="">{`${item.role}`}</span>
                                </div>
                            </div>
                        ))}
                    />
                </div>


            </div>
        )
    }

    const InputForm = () => (
        <div className="container-fluid">
            <h1>Conta</h1>
            <div style={{ opacity: 0.0 }}>{refesh}</div>
            <ItemsList
                style={{}}
                onSelectItem={() => { }}
                onEditItem={(membership: Membership) => {
                    if (!membership.title || membership.title === '') {
                        throw 'Must set a title for membership'
                    }
                    return RemoteActions.setMembership(membership, dispatch)
                }}
                dataTemplate={{
                    title: '',
                    creationDate: '',
                    endDate: '',
                    users: [],
                    vessels: [],
                    membershipType: membershipTypes[0]
                }}
                onDeleteItem={(membership: Membership) => {
                    return RemoteActions.deleteMembership(membership.id, dispatch)
                }}
                listTemplate={(membership: Membership) => {


                    return (
                        <div className="column">


                            <div className="row">
                                <div className="col-md-4">
                                    <span className="font-weight-bold">Nome de conta</span>
                                </div>
                                <div className="col-md-8">
                                    <span className="">{`${membership.title}`}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <span className="font-weight-bold">Tipo de conta</span>
                                </div>
                                {membership.membershipType && <div className="col-md-8">
                                    <span className="">{`${membership.membershipType.name}`}</span>
                                </div>}
                            </div>
                        </div>
                    )
                }}
                newItemFormTemplate={changeForm}
                list={membership} />
        </div>
    )


    return (
        <InputForm />
    );
};


const mapStateToProps = (state: any) => {

    const reducer = {
        membership: state.membership.membership,
        membershipTypes: state.membershipTypes.membershipType,
        vessels: state.vessels.vessels,
        users: state.users.users,
        refesh: `${Date.now()}`,
    }
    return reducer
}

export default connect(mapStateToProps)(MembershipScreen);

const styles = ({
    container: {}
});
