import * as React from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Slot, SplitSlot } from '../../models/models';
import { pad } from '../../utils/utils';
import { lang, selctedLang } from '../../lang';

interface SplitSlotsAdminProps {
    slot: Slot;
    slotColor: any;
    onCancel: () => void;
    onSplit: (splitSlot: any) => void;
}

const SplitSlotsAdmin = (props: SplitSlotsAdminProps) => {
    const [state, setState] = React.useState({
        firstSlotRange: '',
        secondSlotRange: '',
        dateparts: 0,
        value: 0
    })

    React.useEffect(() => {
        const rangeParts = props.slot.range.split('-')
        updateSlots(rangeParts[0], rangeParts[1], 0)
    }, [])

    const updateSlots = (slotStart: string, slotEnd: string, value: number) => {

        const startParts = slotStart.split(':')
        const endParts = slotEnd.split(':')

        const startDate = new Date()
        startDate.setHours(parseInt(startParts[0]), parseInt(startParts[1]), 0)
        const endStartDate = new Date(startDate.valueOf())
        endStartDate.setHours(endStartDate.getHours() + value + 1)


        const startEndDate = new Date(endStartDate.valueOf())
        startEndDate.setHours(startEndDate.getHours() + 1)

        const endDate = new Date()
        endDate.setHours(parseInt(endParts[0]), parseInt(endParts[1]), 0)

        if (endDate.getTime() < startEndDate.getTime()) {
            endDate.setDate(endDate.getDate() + 1)
        }


        const dateHours = ((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60)).toFixed(1)
        let _state = {
            ...state,
            firstSlotRange: `${pad(startDate.getHours(), 2)}:${pad(startDate.getMinutes(), 2)}-${pad(endStartDate.getHours(), 2)}:${pad(endStartDate.getMinutes(), 2)}`,
            secondSlotRange: `${pad(startEndDate.getHours(), 2)}:${pad(startEndDate.getMinutes(), 2)}-${pad(endDate.getHours(), 2)}:${pad(endDate.getMinutes(), 2)}`,
            value
        }

        if (!state.dateparts) {
            _state.dateparts = (+dateHours - 3)
        }

        setState(_state)
    }


    return (
        <Container style={styles.container}>


            <Row>
                <Col style={{ backgroundColor: props.slotColor, textAlign: 'center', margin: 10, borderRadius: '2%', padding: 10 }}>
                    {state.firstSlotRange}
                </Col>

                <Col>
                </Col>

                <Col>
                </Col>
            </Row>

            <Row>
                <Col style={{ backgroundColor: props.slotColor, textAlign: 'center', margin: 10, borderRadius: '2%', padding: 10 }}>
                    {state.secondSlotRange}
                </Col>

                <Col>
                </Col>

                <Col>
                </Col>

            </Row>

            <Row>
                <RangeSlider

                    value={state.value}
                    min={0}
                    max={state.dateparts}
                    size={'lg'}
                    tooltip={'off'}
                    onChange={(changeEvent: any) => {
                        const rangeParts = props.slot.range.split('-')
                        updateSlots(rangeParts[0], rangeParts[1], +changeEvent.target.value)
                    }}
                />
            </Row>

            <Row style={{ marginTop: 30 }}>

                <Col>
                    <Button variant="primary" onClick={() => {
                        props.onSplit({
                            firstSlotRange: state.firstSlotRange,
                            secondSlotRange: state.secondSlotRange,
                            slot: props.slot.range,
                        })
                    }}>
                        {lang[selctedLang].splitSlot}
                    </Button>
                </Col>


                <Col>
                </Col>

            </Row>
        </Container>
    );
};

export default SplitSlotsAdmin;

const styles = ({
    container: {}
});
