import * as React from 'react';
import { Slot } from '../../models/models';
import { checkIfSlotInRange } from '../../utils/reservation-utils';

interface ClockProps {
    date: Date;
    onClockRechTime: () => void;
    slots: Slot[];
}

let currentSlot = ''
const Clock = ({ date, onClockRechTime, slots }: ClockProps) => {
    const [currentDate, setCurrentDate] = React.useState<Date>(date)

    React.useEffect(() => {
        update()
    }, [currentDate])

    const getCurrentSlot = () => {
        for (let i = 0; i < slots.length; i++) {
            const slot = slots[i];
            if (slot.range && checkIfSlotInRange(date, slot)) {
                currentSlot = slot.range
                break;
            }
        }

        return currentSlot
    }

    const update = () => {
        const id = setTimeout(() => {
            const newDate = new Date()
            newDate.setSeconds(currentDate.getSeconds() + 1)
            setCurrentDate(newDate)

            const previousSlot = String(currentSlot)
            const current = getCurrentSlot()
            if (previousSlot !== '' && previousSlot !== current) {
                onClockRechTime()
            }

        }, 1000)
    }

    return (
        <div style={{ textAlign: 'center' }}>
            {`${currentDate.toLocaleTimeString()}`}
        </div>
    );
};

export default Clock;

