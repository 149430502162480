import * as React from 'react';
import { connect } from 'react-redux';
import { Vessel, Membership, Slot, BookingRules, Question } from '../../models/models';
import { Row, Col, Container, Button, ButtonGroup } from 'react-bootstrap';
import RemoteActions from '../../remote/remote-actions';
import { Dispatch } from 'redux';
import bookingRules from '../../redux/reduceres/booking-rules';
import { themeColor } from '../../utils/calendar-utils';
import DynamicForm, { DynamicFormStyles } from '../../components/generic/dynamic-form/dynamic-form';



const CheckBox = ({ question }: { question: Question }) => {
    const [check, setCheck] = React.useState<boolean>(false)
    React.useEffect(() => {
        question.check = check
    }, [check])

    return (
        <div className="row" key={`${question.id}_row`} style={{ margin: 5, flexDirection: 'row', alignItems: 'center', paddingLeft: 15 }} onClick={() => setCheck(!check)}>
            <div style={{ padding: 10, width: 50 }}>
                <input style={DynamicFormStyles.input} type="checkbox" checked={question.check} onChange={(check) => setCheck(!check)} />
            </div>
            <div>
                <span className="badge badge-default">{question.question}</span>
            </div>
        </div>
    )
}

interface UserVesselScreenProps {
    vessels: Vessel[];
    bookingRules: BookingRules[];
    dispatch: Dispatch;
}

const UserVesselScreen = ({ dispatch, vessels, bookingRules }: UserVesselScreenProps) => {

    const [vessel, setVessel] = React.useState<Vessel>(vessels[0])
    const [selected, setSelected] = React.useState(0)
    const [selectedBookingRules, setBookingRules] = React.useState<BookingRules>()

    React.useEffect(() => {
        setVessel(vessels[0])
    }, [vessels])


    React.useEffect(() => {
        RemoteActions.getVessel(dispatch)
    }, [])

    React.useEffect(() => {
        if (vessel) {
            RemoteActions.getBookingRulesCheckForms(dispatch, vessel.bookingRules.id)
        }
    }, [vessel])

    React.useEffect(() => {
        if (!bookingRules || !vessel)
            return

        const rules = bookingRules.find((br: BookingRules) => br.id === vessel.bookingRules.id)
        setBookingRules(rules)
    }, [bookingRules])

    const topMenu = (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <ButtonGroup aria-label="Basic example">
                <Button variant="secondary" onClick={() => setSelected(0)} style={selected === 0 ? { backgroundColor: themeColor } : {}}>Barco</Button>
                <Button variant="secondary" onClick={() => setSelected(1)} style={selected === 1 ? { backgroundColor: themeColor } : {}}>Pré-Largada</Button>
                <Button variant="secondary" onClick={() => setSelected(2)} style={selected === 2 ? { backgroundColor: themeColor } : {}}>Chegada</Button>
            </ButtonGroup>
        </div>
    )

    const vesselInfo = (
        <Container>



            <Row>
                <Col style={{ textAlign: 'start' }}>Vessels</Col>
                <Col>
                    <select id="combo" value={JSON.stringify(vessel)} onChange={(event) => {
                        setVessel(JSON.parse(event.target.value))
                    }}>
                        {vessels!.map((element: Vessel, index: number) => {
                            return (
                                <option key={`${index}`} value={JSON.stringify(element)}>
                                    {element.name}
                                </option>
                            )
                        })}
                    </select>
                </Col>
            </Row>


            {vessel &&

                <Container style={{ padding: 10, borderColor: 'black', borderStyle: 'solid', borderWidth: 1, marginTop: 30 }}>
                    <Row>
                        <Col>
                            <h2>
                                Vessel
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col><span className="font-weight-bold">Name</span></Col>
                        <Col>{vessel.name}</Col>
                    </Row>

                    <Row>
                        <Col><span className="font-weight-bold">Cabins</span></Col>
                        <Col>{vessel.cabins}</Col>
                    </Row>

                    <Row>
                        <Col><span className="font-weight-bold">Capacity</span></Col>
                        <Col>{vessel.capacity}</Col>
                    </Row>

                    <Row>
                        <Col><span className="font-weight-bold">Year</span></Col>
                        <Col>{vessel.year}</Col>
                    </Row>


                    <Row>
                        <Col><span className="font-weight-bold">Max members</span></Col>
                        <Col>{vessel.max_members_boat}</Col>
                    </Row>
                </Container>

            }

            {vessel &&
                <Container style={{ padding: 10, borderColor: 'black', borderStyle: 'solid', borderWidth: 1, marginTop: 30 }}>
                    <Row>
                        <Col>
                            <h2>
                                Booking Rules
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span className="font-weight-bold">Name</span>
                        </Col>
                        <Col>
                            <span className="">{`${vessel.bookingRules.name}`}</span>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <span className="font-weight-bold">Borrow Previous</span>
                        </Col>
                        <Col>
                            <span className="">{`${vessel.bookingRules.borrow_previous}`}</span>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <span className="font-weight-bold">Borrow Next</span>
                        </Col>
                        <Col>
                            <span className="">{`${vessel.bookingRules.borrow_next}`}</span>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <span className="font-weight-bold">Max Months Of Future Booking</span>
                        </Col>
                        <Col>
                            <span className="">{`${vessel.bookingRules.max_months_future_booking}`}</span>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <span className="font-weight-bold">Cancelation Hours</span>
                        </Col>
                        <Col>
                            <span className="">{`${vessel.bookingRules.cancelation_hours}`}</span>
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            <span className="font-weight-bold">Week Slots</span>
                        </Col>
                        {vessel.bookingRules.beforeWeekend.map((slot: Slot) => (
                            <Col>
                                <span className="badge badge-primary">{`${slot.range}`}</span>
                            </Col>
                        )
                        )}
                    </Row>

                    <Row>
                        <Col>
                            <span className="font-weight-bold">Weekend Slots</span>
                        </Col>
                        {vessel.bookingRules.ofWeekend.map((slot: Slot) => (
                            <Col>
                                <span className="badge badge-primary">{`${slot.range}`}</span>
                            </Col>
                        )
                        )}
                    </Row>

                </Container>
            }



        </Container>
    );


    const RenderCheckFormBefore = () => {
        if (!selectedBookingRules)
            return <div></div>

        let questions: any = selectedBookingRules!.beforeStart ? selectedBookingRules!.beforeStart!.questions : []

        return (
            <div style={{ flexDirection: 'column' }}>
                {
                    questions.map((question: Question) => (
                        <CheckBox key={`${1}-${question.id}`} question={question} />
                    ))
                }
            </div>
        )
    }

    const RenderCheckFormAfter = () => {
        if (!selectedBookingRules)
            return <div></div>

        let questions: any = selectedBookingRules!.afterEnd ? selectedBookingRules!.afterEnd?.questions : []

        return (
            <div style={{ flexDirection: 'column' }}>
                {
                    questions.map((question: Question) => (
                        <CheckBox key={`${2}-${question.id}`} question={question} />
                    ))
                }
            </div>
        )
    }

    const renderScreens: any = {
        0: vesselInfo,
        1: <RenderCheckFormBefore />,
        2: <RenderCheckFormAfter />,
    }

    return (
        <Container>
            {topMenu}
            {renderScreens[selected]}
        </Container>
    )
};

const mapStateToProps = (state: any) => {

    const reducer = {
        vessels: state.vessels.vessels,
        bookingRules: state.bookingRules.bookingRules,
    }
    return reducer
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(UserVesselScreen);

const styles = {
    container: {}
}
