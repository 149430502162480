import * as React from 'react';
import { Button } from 'react-bootstrap';

import { Slot } from '../../models/models';
import { lang, selctedLang } from '../../lang';
import { colorFree, themeColor, weekendColor } from '../../utils/calendar-utils';
import { SplitCircle } from '../calendar/slot-item';
import { Icons } from '../generic/bottom-navigation';


interface SlotAvailableButtonProps {
    data: any;
    onPress: (data: any) => void;
    isFree?: boolean;
}

const SlotAvailableButton = ({ data, isFree, onPress }: SlotAvailableButtonProps) => {

    const Render = () => {
        const Slot: Slot = data.slot
        const containerStyle: any = isFree ? { borderLeftColor: colorFree, borderLeftWidth: 7, borderLeftStyle: 'solid' } : {}
        const SlotIcon = Icons[Slot.name]
        const backgroundColor = data.slot.dayType === 'WEEK_END' ? { backgroundColor: weekendColor, borderColor: 'lightgray' } : { backgroundColor: 'transparent' }
        return (
            <Button className='slotBtShadow'
                disabled={!data.isValidReservation.isValidReservation}
                onClick={() => onPress(data)}
                style={{
                    marginLeft: '4%',
                    marginRight: '4%',
                    paddingLeft: 15,
                    paddingReft: 15,
                    borderColor: 'transparent',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderRadius: 0,
                    ...backgroundColor,
                    ...containerStyle

                }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {data.isSplit && <div style={{}}><SplitCircle key='' width={10} backgroundColor={themeColor} /></div>}
                </div>
                {SlotIcon}
                <div style={{ textAlign: 'center', color: themeColor, fontSize: 13 }} >
                    {lang[selctedLang].schedule}
                </div>
                <div style={{ textAlign: 'center', color: themeColor, fontSize: 13 }} >
                    {lang[selctedLang][Slot.name]}
                </div>
            </Button>
        )
    }

    return Render()
};

export default SlotAvailableButton;

