import * as React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './components/routing/PrivateRoute';
import LoginScreen from './screens/auth/Login';
import SignupScreen from './screens/auth/Signup';

import BookingRulesScreen from './screens/membership/booking-rules';
import SlotsScreen from './screens/slots/slots-screen';
import VesselScreen from './screens/vessel/vessel-screen';
import HolidayScreen from './screens/vessel/holiday-screen';

import MembershipTypesScreen from './screens/membership/membership-type';
import MembershipScreen from './screens/membership/membership-screen';
import ReservationScreen from './screens/reservation/reservation-screen';
import SkipperAccountScreen from './screens/membership/skipper-account';

import { stack as Menu } from 'react-burger-menu'
import { Container, Row, Image } from 'react-bootstrap';
import UserInfo from './components/user-info/user-info'
import ReservationDetailsScreen from './screens/reservation/reservation-details-screen';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { User } from './models/models';
import { UserRoles } from './utils/utils';
import CreditsScreen from './screens/credits/credits-screen';
import QuestionGroupScreen from './screens/check-form/question-group-screen';
import QuestionScreen from './screens/check-form/question-screen';
import CheckFormScreen from './screens/check-form/check-form-screen';
import MessagesScreen from './screens/messages/admin/messages-screen';
import UserMessagesScreen from './screens/messages/user/user-messages-screen';
import Profile from './screens/profile/profile-screen';
import UserVesselScreen from './screens/vessel/user-vessel-screen';
import UsersScreen from './screens/profile/users-screen';
import PasswordRecoveryScreen from './screens/user/password-recovery-screen';
import ResetPasswordScreen from './screens/user/reset-password-screen';
import SkipperCalendarScreen from './screens/skipper-screen/skipper-calendar-screen'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faShip, faCalendarAlt, faCommentAlt, faFileExcel, faSignOutAlt, faAnchor, faToggleOff } from "@fortawesome/free-solid-svg-icons";
import BottomNavigation from './components/generic/bottom-navigation';

import logo from './assets/img/logo.png'
import DistributionListScreen from './screens/user/distribution-list-screen';
import CheckInOutList from './screens/check-In-out/CheckInOutList';
import Mantainace from './screens/auth/Mantainace';
import RemoteActions from './remote/remote-actions';



interface Props {
  dispatch: Dispatch;
  user: User;
  siteDisable: boolean;
}

const MainAppController = ({ user, siteDisable, dispatch }: Props) => {
  const [disableCheckBox, setDisableCheckBox] = React.useState(false);
  const AdminMenu = (
    <Menu className={"navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"} pageWrapId={"page-wrap"} outerContainerId={"outer-container"}>


      <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/reservation">
        <div className="sidebar-brand-icon">
          <FontAwesomeIcon icon={faAnchor} style={{ fontSize: 20 }} />
        </div>
        <div className="sidebar-brand-text mx-1">Nautic Squad</div>
      </a>

      <hr className="sidebar-divider my-0" />

      {user && <li className="nav-item active">
        <div className="nav-link" style={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon icon={faToggleOff} />
          <span style={{ marginLeft: 5 }}>Ecrã Manutenção</span>
          <input disabled={disableCheckBox} style={{ marginLeft: 5, padding: 3 }} type="checkbox" checked={siteDisable} onChange={async () => {
            setDisableCheckBox(true)
            const disable = !siteDisable;
            await RemoteActions.disableSite({ disable }, dispatch)
            setDisableCheckBox(false)
          }} />
        </div>
      </li>}

      <li className="nav-item active">
        <a className="nav-link" href="/signup">
          <FontAwesomeIcon icon={faUserCircle} />
          <span style={{ marginLeft: 5 }}>Registar novo utilizador</span>
        </a>
      </li>

      <li className="nav-item active">
        <a className="nav-link" href="/profile">
          <FontAwesomeIcon icon={faUserCircle} />
          <span style={{ marginLeft: 5 }}>Perfil de utilizador</span>
        </a>
      </li>

      <li className="nav-item active">
        <a className="nav-link" href="/users">
          <span style={{ marginLeft: 5 }}>Lista de utilizadores</span>
        </a>
      </li>

      <li className="nav-item active">
        <a className="nav-link" href="/dl">
          <span style={{ marginLeft: 5 }}>Lista de distribuição (emails)</span>
        </a>
      </li>

      <div className="sidebar-heading">
        Barcos
      </div>

      <li className="nav-item active">
        <a className="nav-link" href="/vessel">
          <FontAwesomeIcon icon={faShip} />
          <span style={{ marginLeft: 5 }}>Barcos</span>
        </a>
      </li>

      <li className="nav-item active">
        <a className="nav-link" href="/bookingrules">
          <span style={{ marginLeft: 5 }}>Regras de marcação</span>
        </a>
      </li>

      <li className="nav-item active">
        <a className="nav-link" href="/slots">
          <span style={{ marginLeft: 5 }}>Slots</span>
        </a>
      </li>

      <div className="sidebar-heading">
        Contas
      </div>

      <li className="nav-item active">
        <a className="nav-link" href="/membership">
          <span style={{ marginLeft: 5 }}>Memberships</span>
        </a>
      </li>

      <li className="nav-item active">
        <a className="nav-link" href="/membershipTypes">
          <span style={{ marginLeft: 5 }}>Tipos de Memberships</span>
        </a>
      </li>

      <div className="sidebar-heading">
        Reservas
      </div>

      <li className="nav-item active">
        <a className="nav-link" href="/reservation">
          <span style={{ marginLeft: 5 }}>Calendário</span>
        </a>
      </li>


      <li className="nav-item active">
        <a className="nav-link" href="/credits">
          <span style={{ marginLeft: 5 }}>Report</span>
        </a>
      </li>

      <li className="nav-item active">
        <a className="nav-link" href="/skipperAccount">
          <span style={{ marginLeft: 5 }}>Contas de tripulação</span>
        </a>
      </li>

      <li className="nav-item active">
        <a className="nav-link" href="/adminMessages">
          <span style={{ marginLeft: 5 }}>Board de mensagens</span>
        </a>
      </li>

      <div className="sidebar-heading">
        Forms de Check in/out
      </div>
      <li className="nav-item active">
        <a className="nav-link" href="/questionGroup">
          <span style={{ marginLeft: 5 }}>Grupos de perguntas</span>
        </a>
      </li>

      <li className="nav-item active">
        <a className="nav-link" href="/question">
          <span style={{ marginLeft: 5 }}>Questões</span>
        </a>
      </li>

      <li className="nav-item active">
        <a className="nav-link" href="/checkFormScreen">
          <span style={{ marginLeft: 5 }}>Formulários</span>
        </a>
      </li>

      <hr className="sidebar-divider" />

      <li className="nav-item active">
        <a className="nav-link" href="/login">
          <FontAwesomeIcon icon={faSignOutAlt} />
          <span style={{ marginLeft: 5 }}>Terminar sessão</span>
        </a>
      </li>

    </Menu>
  )

  const CustomerMenu = (
    <Menu className={"navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"} pageWrapId={"page-wrap"} outerContainerId={"outer-container"}>

      <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/reservation">
        <div className="sidebar-brand-icon">
          <FontAwesomeIcon icon={faAnchor} style={{ fontSize: 20 }} />
        </div>
        <div className="sidebar-brand-text mx-1">Nautic Squad</div>
      </a>

      <hr className="sidebar-divider my-0" />

      <li className="nav-item active">
        <a className="nav-link" href="/profile">
          <FontAwesomeIcon icon={faUserCircle} />
          <span style={{ marginLeft: 5 }}>Perfil de utilizador</span>
        </a>
      </li>

      <div className="sidebar-heading">
        Interface
      </div>


      <li className="nav-item active">
        <a className="nav-link" href="/userVessels">
          <FontAwesomeIcon icon={faShip} />
          <span style={{ marginLeft: 5 }}>Barcos</span>
        </a>
      </li>

      <li className="nav-item active">
        <a className="nav-link" href="/reservation">
          <FontAwesomeIcon icon={faCalendarAlt} />
          <span style={{ marginLeft: 5 }}>Reservas</span>
        </a>
      </li>

      <li className="nav-item active">
        <a className="nav-link" href="/credits">
          <FontAwesomeIcon icon={faFileExcel} />
          <span style={{ marginLeft: 5 }}>Créditos</span>
        </a>
      </li>

      <li className="nav-item active">
        <a className="nav-link" href="/userMessages">
          <FontAwesomeIcon icon={faCommentAlt} />
          <span style={{ marginLeft: 5 }}>Quadro de mensagens</span>
        </a>
      </li>


      <hr className="sidebar-divider" />


      <li className="nav-item active">
        <a className="nav-link" href="/login">
          <FontAwesomeIcon icon={faSignOutAlt} />
          <span style={{ marginLeft: 5 }}>Terminar sessão</span>
        </a>
      </li>

    </Menu>
  )

  const SkipperMenu = (
    <Menu className={"navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"} pageWrapId={"page-wrap"} outerContainerId={"outer-container"}>
      <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/reservation">
        <div className="sidebar-brand-icon">
          <FontAwesomeIcon icon={faAnchor} style={{ fontSize: 20 }} />
        </div>
        <div className="sidebar-brand-text mx-1">Nautic Squad</div>
      </a>

      <hr className="sidebar-divider my-0" />
      <a className="menu-item" href="/reservation">Reservations</a>
      <a className="menu-item" href="/credits">Credits</a>
      <a className="menu-item" href="/login">logout</a>
    </Menu>
  )




  const NAV: any = {
    [UserRoles.ADMIN]: AdminMenu,
    [UserRoles.SUPER_USER]: AdminMenu,
    [UserRoles.MEMBER_CLUB]: null,
    [UserRoles.CERTIFIED_CLIENT]: null,
    [UserRoles.TURISTIC_OPERATOR]: null,
    [UserRoles.SKIPPER]: SkipperMenu,
  }


  return (

    <div id="outer-container" style={{
      overflow: 'scroll',
      marginBottom: 100,
    }}>


      {user && NAV[user.role!] && <div id='menubar' className="navbar-light topbar mb-4 static-top" style={{
        position: 'fixed',
        top: 0,
        width: 100,
        height: '80px',
        backgroundColor: 'transparent',
        zIndex: 100
      }}>
        {user && NAV[user.role!]}

        {/* <div style={{ float: 'right' }}>
          <UserInfo />
        </div> */}

      </div>}









      <main id="page-wrap" className={'container-fluid'} style={{

        // backgroundImage: 'url(https://img.vixdata.io/pd/webp-large/pt/sites/default/files/p/praia-mar-oceano-1019-1400x800.jpg)',
        // backgroundRepeat: 'no-repeat',
        // backgroundAttachment: 'fixed',
        // backgroundSize: 'cover',
      }}>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
          <Image src={logo} width={80} />
        </div>

        <Row>
          <Router>
            {user && <BottomNavigation />}
            <Switch>

              <Route path="/maintenance">
                <Mantainace />
              </Route>

              <PrivateRoute path="/signup">
                <SignupScreen />
              </PrivateRoute>

              <PrivateRoute path="/CheckInOutList">
                <CheckInOutList />
              </PrivateRoute>


              <Route path="/resetPassword">
                <ResetPasswordScreen />
              </Route>


              <Route path="/login">
                <LoginScreen />
              </Route>

              <Route path="/passwordRecoveryScreen">
                <PasswordRecoveryScreen />
              </Route>


              <PrivateRoute path="/skipperAccount">
                <SkipperAccountScreen />
              </PrivateRoute>

              <PrivateRoute path="/dl">
                <DistributionListScreen />
              </PrivateRoute>

              <PrivateRoute path="/users">
                <UsersScreen />
              </PrivateRoute>

              <PrivateRoute path="/reservation">
                <ReservationScreen />
              </PrivateRoute>

              <PrivateRoute path="/skipperCalendar">
                <SkipperCalendarScreen />
              </PrivateRoute>

              <PrivateRoute path="/membership">
                <MembershipScreen />
              </PrivateRoute>

              <PrivateRoute path="/questionGroup">
                <QuestionGroupScreen />
              </PrivateRoute>

              <PrivateRoute path="/question">
                <QuestionScreen />
              </PrivateRoute>

              <PrivateRoute path="/checkFormScreen">
                <CheckFormScreen />
              </PrivateRoute>

              <PrivateRoute path="/credits">
                <CreditsScreen />
              </PrivateRoute>

              <PrivateRoute path="/membershipTypes">
                <MembershipTypesScreen />
              </PrivateRoute>

              <PrivateRoute path="/bookingrules">
                <BookingRulesScreen />
              </PrivateRoute>

              <PrivateRoute path="/slots">
                <SlotsScreen />
              </PrivateRoute>

              <PrivateRoute path="/vessel">
                <VesselScreen />
              </PrivateRoute>

              <PrivateRoute path="/holiday">
                <HolidayScreen />
              </PrivateRoute>

              <PrivateRoute path="/profile">
                <Profile />
              </PrivateRoute>

              <PrivateRoute path="/adminMessages">
                <MessagesScreen />
              </PrivateRoute>

              <PrivateRoute path="/userMessages">
                <UserMessagesScreen />
              </PrivateRoute>

              <PrivateRoute path="/userVessels">
                <UserVesselScreen />
              </PrivateRoute>

              <PrivateRoute path="/">
                <ReservationScreen />
              </PrivateRoute>

            </Switch>
          </Router>

        </Row>
      </main>
    </div >
  )
}

const mapStateToProps = (state: any) => {

  return {
    user: state.login.user,
    siteDisable: state.login.siteDisable
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(MainAppController)



