import { combineReducers } from 'redux'
import login from '../reduceres/auth'
import slots from '../reduceres/slots'
import splitSlot from '../reduceres/split-slot'
import accountRules from '../reduceres/booking-rules'
import reservationSkipper from '../reduceres/reservation-skipper'
import reservations from '../reduceres/reservation'
import skipperAccounts from '../reduceres/skipper-account'
import skipperAvailability from '../reduceres/skipper-availability'
import membership from '../reduceres/membership'
import membershipTypes from '../reduceres/membership-type'
import bookingRules from '../reduceres/booking-rules'
import vessels from '../reduceres/vessel'
import holiday from '../reduceres/holidays'
import users from '../reduceres/users'
import waitingList from '../reduceres/waiting-list'
import questionGroup from '../reduceres/check-form/group'
import question from '../reduceres/check-form/question'
import checkForm from '../reduceres/check-form/check-form'
import answer from '../reduceres/check-form/answer'
import messages from '../reduceres/messages'
import dls from '../reduceres/dl'

export default combineReducers({
  login,
  users,
  slots,
  splitSlot,
  accountRules,
  reservationSkipper,
  reservations,
  skipperAccounts,
  skipperAvailability,
  membership, 
  membershipTypes,
  bookingRules, 
  vessels,
  holiday, 
  waitingList,
  questionGroup, 
  question,
  checkForm,
  answer,
  messages,
  dls
})
