import * as React from 'react';
import { Dispatch } from 'redux'
import ItemsList from '../../components/generic/items-list';
import { Vessel, VesselBlock } from '../../models/models';
import DynamicForm from '../../components/generic/dynamic-form/dynamic-form';
import { validDateRange } from '../../utils/utils';



// vessel: Vessel;
// startDate: string;
// endDate: string;

interface VesselBlockScreenProps {
    vessel: Vessel;
    // onEditItem: (block: VesselBlock) => void;
    // onDeleteItem: (block: VesselBlock) => void;
}


const VesselBlockScreen: React.FC<any> = ({ vessel }: VesselBlockScreenProps) => {

    const changeForm = ({ item, editing, onChange }: { item: VesselBlock, editing: boolean, onChange: any }) => {
        let error: any;
        if (item.startDate) {
            const validDate = validDateRange(item.startDate)
            if (!validDate) {
                error = 'Invalid date. format "YYYY/MM/DD"'
            } else {
                error = ''
            }
        }

        if (item.endDate) {
            const validDate = validDateRange(item.endDate)
            if (!validDate) {
                error = 'Invalid date. format "YYYY/MM/DD"'
            } else {
                error = ''
            }
        }

        return (

            <div key={`add_item_form`}>
                <DynamicForm
                    key={'DynamicForm'}
                    data={item}
                    labels={{
                        title: 'Title',
                        startDate: 'Start Date',
                        endDate: 'End Date',
                    }}
                    editable={editing}
                    onDataChange={onChange}
                />

                {error && error !== '' && <div className="alert alert-danger" role="alert">
                    {error}
                </div>}
            </div>
        )
    }

    const InputForm = () => (
        <div className="container-fluid">
            <h2>Block Rules</h2>
            <ItemsList
                style={{}}
                onSelectItem={() => { }}
                onEditItem={(block: VesselBlock) => {
                    return new Promise((resolve, reject) => {
                        if (block.id) {
                            const exists = vessel.vesselBlock.findIndex(x => x.id === block.id)
                            vessel.vesselBlock[exists] = block
                        } else {
                            vessel.vesselBlock.push(block)
                        }
                        resolve(vessel)
                    })
                }}
                dataTemplate={{
                    vessel: {id: vessel.id},
                    title: '',
                    startDate: '',
                    endDate: '',
                }}
                onDeleteItem={(block: VesselBlock) => {
                    return new Promise((resolve, reject) => {
                        if (block.id) {
                            const exists = vessel.vesselBlock.findIndex(x => x.id === block.id)
                            vessel.vesselBlock.splice(exists, 1)
                        }
                        resolve(vessel)
                    })
                }}
                listTemplate={(vessel: VesselBlock) => {


                    return (
                        <div className="column">
                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Start Date</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${vessel.startDate}`}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">End Date</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${vessel.endDate}`}</span>
                                </div>
                            </div>
                        </div>
                    )
                }}
                newItemFormTemplate={changeForm}
                list={vessel.vesselBlock} />
        </div>
    )


    return InputForm()
};

export default (VesselBlockScreen);


