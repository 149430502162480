import * as React from 'react';
import { Slot, Reservation, SkipperAvailability, SkipperAccount } from '../../../../../models/models';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { SlotItemProps, getStyle, AVAILABLE, NORMAL, } from '../../slot-type-interface';
import RemoteActions from '../../../../../remote/remote-actions';
import { months } from '../../../../../utils/calendar-utils';
import { RenderSlot } from '../../../slot-item';
import { lang, selctedLang } from '../../../../../lang';
import membership from '../../../../../redux/reduceres/membership';


interface SlotReservedProps extends SlotItemProps {
    slotType: string;
    dayType: string;
    skipper: SkipperAccount;
}

const SlotAvailableSkipper = ({ dispatch, user, vessel, bookingRules, reservations, day, month, year, slot, slotType, dayType, skipper }: SlotReservedProps) => {
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const makeReservation = () => {
        const skipperAvailability: SkipperAvailability = {
            vessel,
            skipper,
            slot: slot.range,
            date: `${year}-${month}-${day}`,
        }

        RemoteActions.setSkipperAvailability(skipperAvailability, dispatch)
    }



    const SlotTemplate = ({ slot }: { slot: Slot }) => (
        <Button onClick={handleShow} key={`${slot.id}-${day}-${month}-${year}`} style={getStyle(NORMAL, dayType, AVAILABLE)} className="event-desc list-group-item list-group-item-action list-group-item-light">
            <RenderSlot slot={slot} />
        </Button>
    )

    const ModalComponent = () => (
        <Modal show={show} onHide={handleClose} animation={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{lang[selctedLang].reservation}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3>{`${user.email}`}</h3>
                <p>{lang[selctedLang].reservationConfirmation(slot, vessel, day, month, year, membership)}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {lang[selctedLang].cancel}
                </Button>
                <Button variant="primary" onClick={makeReservation}>
                    {lang[selctedLang].save}
                </Button>
            </Modal.Footer>
        </Modal>
    )

    return (
        <div>
            <SlotTemplate slot={slot} />
            <ModalComponent />
        </div>
    )

}

export default (SlotAvailableSkipper)