import * as React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { User } from '../../models/models';
import maintanance from '../../assets/img/maintanance.png'

interface MantainaceScreenProps {
    user: User;
    loggedIn: boolean;
    dispatch: Dispatch;
}


const MantainaceScreen: React.FC<MantainaceScreenProps> = (props: MantainaceScreenProps) => {

    return (
        <div className="container-fluid">
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 100 }}>
                <Image src={maintanance} width={'30%'} />
            </div>

            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <h3>Site em Manutenção</h3>
            </div>

            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <h4>Volte a tentar mais tarde</h4>
            </div>

            <style>{`
                body{
                    background-color: #4e73df;
                    background-image: linear-gradient(180deg, #4e73df 10%, #224abe 100%);
                    background-size: cover;
                }
                #main-container{
                    overflow-y: hidden;
                }
            `}</style>

        </div>
    );
};


/**
 * controlles login state
 * @param state
 */
const mapStateToProps = (state: any) => {

    return {
        user: state.login.user,
        loggedIn: state.login.loggedIn
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(MantainaceScreen);

