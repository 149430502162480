import * as React from 'react';

import { Button, Modal, Row, Col, Container } from 'react-bootstrap';
import { SlotItemProps, getStyle, RESERVED } from '../../components/calendar/slot-types/slot-type-interface';
import { SlotReservedProps } from '../../components/calendar/slot-types/slots/customer/slot-reserved';
import { Reservation, Slot, User, SkipperAccount, SkipperAvailability } from '../../models/models';
import { months } from '../../utils/calendar-utils';
import RemoteActions from '../../remote/remote-actions';
import DynamicForm from '../../components/generic/dynamic-form/dynamic-form';
import { useLocation, useHistory, Link } from 'react-router-dom';
import SwitchList from '../../components/generic/switch-list';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { avaliableCredits, getDayType, borrowCredits, DayType, isValidToRemoveReservation, disableCheck, getWeekDayType } from '../../utils/reservation-utils';
import { UserRoles } from '../../utils/utils';
import FormScreen from '../../screens/check-form/form-screen';
import { lang, selctedLang } from '../../lang';
import { Icons } from '../../components/generic/bottom-navigation';
import moment from 'moment';

enum DisplayView {
    Default,
    CheckIn,
    Delete,
    ManageSkipers
}

interface ReservationDetailsScreenProps {
    skippers: SkipperAccount[];
    skippersAvailability: SkipperAvailability[];
    dispatch: Dispatch;
    state: any;
    disable?: [string];
}

const ReservationDetailsScreen = (props: ReservationDetailsScreenProps) => {
    const { date, reservation, user, vessel, membership, bookingRules, reservations, day, month, year, slot, slotType, dayType, answers }: SlotReservedProps = props.state;
    const [showView, setView] = React.useState<DisplayView>(DisplayView.Default);
    const [freeSlot, SetFreeSlot] = React.useState<boolean>(props.state.reservation!.free)

    const deleteSlot = async (isValidDelete: boolean) => {

        if (!reservation?.borrow && reservation?.borrow === '') {
            const borrowReservations = reservations!.filter((reservation: Reservation | any) => {
                const dateParts = reservation.date.split('-')
                let reservationDayType = getWeekDayType(+dateParts[0], +dateParts[1], +dateParts[2])

                if (
                    reservation.membership.id === membership.id &&
                    year === +dateParts[0] &&
                    month === +dateParts[1] &&
                    reservation.free === false &&
                    reservation.borrow !== '' &&
                    reservationDayType === dayType
                )
                    return reservation
            })

            let selected: Reservation | null = null;
            borrowReservations.forEach((reservation: Reservation) => {
                if (!selected)
                    selected = reservation
                else {
                    const reservationDateParts = reservation.borrow!.split('-')
                    const selectedDateParts = selected.borrow!.split('-')
                    if (
                        +reservationDateParts[0] >= +selectedDateParts[0] &&
                        +reservationDateParts[1] >= +selectedDateParts[1]
                    ) {
                        selected = reservation
                    }
                }
            })

            if (selected) {
                selected!.borrow = ''
                await RemoteActions.setReservation(selected!, props.dispatch)
            }
        }

        if (user.role === UserRoles.ADMIN || isValidDelete) {
            await RemoteActions.deleteReservation(reservation!.id, props.dispatch)
        } if (user.role !== UserRoles.ADMIN && !isValidDelete) {
            reservation!.disable = true;
            await RemoteActions.setReservation(reservation!, props.dispatch)
        }

    }

    const DeleteScreen = () => {
        const isValidDelete = isValidToRemoveReservation(date, reservation!, vessel)

        return (
            <Container>
                <Row>
                    <Col>
                        {user.role !== UserRoles.ADMIN && !isValidDelete ? lang[selctedLang].removeExpiredReservation : lang[selctedLang].remove}
                    </Col>
                </Row>

                <Row>
                    <button style={{ margin: 20, float: "right" }} type="button" className="btn btn-danger" onClick={() => deleteSlot(isValidDelete)} >
                        <div style={{ marginRight: 4 }}>
                            {lang[selctedLang].remove}
                            <FontAwesomeIcon icon={faSave} style={{ marginLeft: 4 }} />
                        </div>
                    </button>
                </Row>
            </Container>
        )
    }




    const Render = () => {
        const date = reservation!.date.split('-')
        const slotDate = new Date(+date[0], +date[1], +date[2])
        const dateString = moment(slotDate).format("YYYY-MM-DD")
        return (
            <Container>
                <Row>
                    <Col>
                        {lang[selctedLang].membership}
                    </Col>
                    <Col>
                        {membership.title}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {lang[selctedLang].vessel}
                    </Col>
                    <Col>
                        {reservation?.vessel.name}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {lang[selctedLang].user}
                    </Col>
                    <Col>

                        <select id="combo" onChange={(event) => {
                            const user: User = JSON.parse(event.target.value)
                            reservation!.user = user
                        }}>
                            {membership.users.map((element: User, index: number) => {
                                const isSelected = reservation?.user.id === element.id
                                return isSelected
                                    ?
                                    <option key={`${index}`} selected value={JSON.stringify(element)}>{element.lastName}, {element.firstName}</option>
                                    :
                                    <option key={`${index}`} value={JSON.stringify(element)}>{element.lastName}, {element.firstName}</option>
                            })}
                        </select>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {lang[selctedLang].date}
                    </Col>
                    <Col>
                        {dateString}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {lang[selctedLang].slot}
                    </Col>
                    <Col>
                        {reservation?.slot}
                    </Col>
                </Row>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                    <input type="checkbox" checked={freeSlot} onChange={() => SetFreeSlot(!freeSlot)} />
                    <div style={{ marginLeft: 10 }}>{lang[selctedLang].free}</div>
                </div>

                {reservation!.skippers! && reservation!.skippers!.length > 0 && (
                    <Row>
                        <Col>
                            {lang[selctedLang].skippers}
                        </Col>
                        <Col>
                            {reservation!.skippers!.map((skipper: SkipperAccount) => (
                                <div style={{ borderWidth: 1, borderColor: 'black', borderStyle: 'solid', padding: 5 }}>
                                    <div>{skipper.user.firstName} {skipper.user.lastName}</div>
                                    <div>{skipper.user.email}</div>
                                </div>
                            ))}
                        </Col>
                    </Row>
                )}

                <Row style={{ marginTop: 20 }}>
                    {/* {disableCheck(props.disable, (
                    <Col>
                        <Button onClick={() => { setView(showView === DisplayView.CheckIn ? DisplayView.Default : DisplayView.CheckIn) }} >
                            <Container>
                                <Row>Check-in</Row>
                            </Container>
                        </Button>
                    </Col>), 'checkin')
                } */}

                    {disableCheck(props.disable, (
                        <Col>
                            <Button onClick={() => { setView(showView === DisplayView.ManageSkipers ? DisplayView.Default : DisplayView.ManageSkipers) }} >
                                <Container>
                                    <Row>{lang[selctedLang].manageSkippers}</Row>
                                </Container>
                            </Button>
                        </Col>), 'skippers')
                    }

                </Row>

                <Row style={{ marginTop: 20 }}>

                    {reservation!.checks && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                        {reservation!.checks!.indexOf('checkIn') >= 0 && (
                            <Link to={`/CheckInOutList?id=${reservation?.id}&type=checkIn`} style={{ margin: 20, float: "right" }} className="btn btn-primary">CheckIn
                                {Icons.CheckIn}
                            </Link>
                        )}
                        {reservation!.checks!.indexOf('checkOut') >= 0 && (
                            <Link to={`/CheckInOutList?id=${reservation?.id}&type=checkOut`} style={{ margin: 20, float: "right" }} className="btn btn-primary">CheckOut
                                {Icons.CheckOut}
                            </Link>
                        )}
                    </div>}

                </Row>
                <Row style={{ marginTop: 20 }}>

                    <Col>

                        <button style={{ margin: 20, float: "right" }} type="button" className="btn btn-primary" onClick={async () => {
                            const reservatioMutated = { ...reservation!, free: freeSlot }
                            await RemoteActions.setReservation(reservatioMutated, props.dispatch)
                        }} >
                            <div style={{ marginRight: 4 }}>
                                {lang[selctedLang].save}
                                <FontAwesomeIcon icon={faSave} style={{ marginLeft: 4 }} />
                            </div>

                        </button>

                        <button style={{ margin: 20, float: "right" }} type="button" className="btn btn-danger" onClick={() => { setView(showView === DisplayView.Delete ? DisplayView.Default : DisplayView.Delete) }} >
                            <div style={{ marginRight: 4 }}>
                                {lang[selctedLang].remove}
                                <FontAwesomeIcon icon={faSave} style={{ marginLeft: 4 }} />
                            </div>

                        </button>
                    </Col>
                </Row>

            </Container >
        )
    }

    const onChange = async (items: any) => {
        reservation!.skippers = items.skippers
    }

    const ManageSkipers = () => {
        return (
            <Container>

                <Row>
                    <Col>
                        <SwitchList
                            field={'skippers'}
                            leftListdata={props.skippers}
                            rightListdata={reservation!.skippers ? reservation!.skippers : []}
                            onSelectedListChange={onChange}
                            listTemplate={((item: SkipperAccount) => (
                                <Container>
                                    <Row>
                                        <Col>
                                            {`${item.user.firstName} ${item.user.lastName}`}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ fontSize: 7 }}>
                                            {`${item.user.email}`}
                                        </Col>
                                    </Row>
                                </Container>
                            ))}
                        />
                    </Col>
                </Row>

                <Row >
                    <Col>
                        <button style={{ margin: 20, float: "right" }} type="button" className="btn btn-primary" onClick={async () => {
                            await RemoteActions.setReservation(reservation!, props.dispatch)
                        }} >
                            <Col style={{ marginRight: 4 }}>
                                {lang[selctedLang].save}
                                <FontAwesomeIcon icon={faSave} style={{ marginLeft: 4 }} />
                            </Col>

                        </button>
                    </Col>
                </Row>
            </Container>
        )
    }


    const displayView = {
        [DisplayView.Default]: (<Render />),
        [DisplayView.CheckIn]: null, //(<FormScreen reservation={reservation} type={'checkIn'} dispatch={props.dispatch} answers={answers} onCancel={()=>{}}/>),
        [DisplayView.Delete]: (<DeleteScreen />),
        [DisplayView.ManageSkipers]: (<ManageSkipers />),
    }

    return displayView[showView]

}


const mapStateToProps = (state: any) => {
    return {
        skippers: state.skipperAccounts.skipperAccounts,
        skippersAvailability: state.skipperAvailability.skipperAvailability
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(ReservationDetailsScreen)