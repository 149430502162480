export enum ACTIONS_RESERVATION_SKIPPER {
    ADD_RESERVATION_SKIPPERS_SUCCESS = 'ADD_RESERVATION_SKIPPERS_SUCCESS',
    ADD_RESERVATION_SKIPPERS_FAILURE = 'ADD_RESERVATION_SKIPPERS_FAILURE',

    GET_RESERVATION_SKIPPERS_SUCCESS = 'GET_RESERVATION_SKIPPERS_SUCCESS',
    GET_RESERVATION_SKIPPERS_FAILURE = 'GET_RESERVATION_SKIPPERS_FAILURE',

    DELETE_RESERVATION_SKIPPERS_SUCCESS = 'DELETE_RESERVATION_SKIPPERS_SUCCESS',
    DELETE_RESERVATION_SKIPPERS_FAILURE = 'DELETE_RESERVATION_SKIPPERS_FAILURE',
}

export const ADD_RESERVATION_SKIPPERS_SUCCESS = (data:any) => ({
    type: ACTIONS_RESERVATION_SKIPPER.ADD_RESERVATION_SKIPPERS_SUCCESS,
    data
})

export const ADD_RESERVATION_SKIPPERS_FAILURE = (e:any) => ({
    type: ACTIONS_RESERVATION_SKIPPER.ADD_RESERVATION_SKIPPERS_FAILURE,
    error: e
})

export const DELETE_RESERVATION_SKIPPERS_SUCCESS = (data:any) => ({
    type: ACTIONS_RESERVATION_SKIPPER.DELETE_RESERVATION_SKIPPERS_SUCCESS,
    data
})

export const DELETE_RESERVATION_SKIPPERS_FAILURE = (e:any) => ({
    type: ACTIONS_RESERVATION_SKIPPER.DELETE_RESERVATION_SKIPPERS_FAILURE,
    error: e
})


export const GET_RESERVATION_SKIPPERS_SUCCESS = (data:any) => ({
    type: ACTIONS_RESERVATION_SKIPPER.GET_RESERVATION_SKIPPERS_SUCCESS,
    data
})

export const GET_RESERVATION_SKIPPERS_FAILURE = (e:any) => ({
    type: ACTIONS_RESERVATION_SKIPPER.GET_RESERVATION_SKIPPERS_FAILURE,
    error: e
})