import * as React from 'react';
import { Slot, Reservation, SkipperAccount, SkipperAvailability, QuestionAnswer } from '../../../../../models/models';
import { Button, Modal, Row, Col, Container } from 'react-bootstrap';
import { months } from '../../../../../utils/calendar-utils';
import { SlotItemProps, getStyle, AVAILABLE, RESERVED, NORMAL } from '../../slot-type-interface';
import RemoteActions from '../../../../../remote/remote-actions';
import { UserRoles } from '../../../../../utils/utils';
import { Link, Router, useHistory } from 'react-router-dom';
import FormScreen from '../../../../../screens/check-form/form-screen';
import { RenderSlot } from '../../../slot-item';
import { lang, selctedLang } from '../../../../../lang';


export interface SlotReservedProps extends SlotItemProps {
    slotType: string;
    dayType: string;
    skipper: SkipperAccount;
    skipperAvailability: SkipperAvailability | undefined;
    reservation: Reservation | undefined;
    answers: QuestionAnswer[];
}

const SlotReservedSkipper = ({ dispatch, skipperAvailability, reservation, user, vessel, bookingRules, reservations, day, month, year, slot, slotType, dayType, answers }: SlotReservedProps) => {
    const [show, setShow] = React.useState(false);

    const [check, setCheck] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const removeReservation = () => {
        RemoteActions.deleteSkipperAvailability(skipperAvailability!.id, dispatch)
    }

    const SlotTemplate = ({ slot }: { slot: Slot }) => (
        <Button onClick={handleShow} key={`${slot.id}-${day}-${month}-${year}`} style={getStyle(AVAILABLE, dayType, reservation ? NORMAL : AVAILABLE)} className="event-desc list-group-item list-group-item-action list-group-item-light">
            <Row style={{ fontSize: 14 }}>
                {reservation?.membership.title}
            </Row>
            <Row >
                <Col xs={12} style={{ fontSize: 14 }}>
                    {reservation?.user.firstName} {reservation?.user.lastName}
                </Col>
            </Row>
            <RenderSlot slot={slot} />
        </Button>
    )


    const displayView = {
        checkIn: (
            <Container>
                <h3>Check-in</h3>
                {/* <FormScreen reservation={reservation} type={'checkIn'} dispatch={dispatch} answers={answers} onCancel={()=>{}}/> */}
            </Container>
        ),
        checkOut: (
            <Container>
                <h3>Check-out</h3>
                {/* <FormScreen reservation={reservation} type={'checkOut'} dispatch={dispatch} answers={answers} onCancel={()=>{}}/> */}
            </Container>
        ),
    }

    const [view, setView] = React.useState(displayView.checkIn);

    const ModalComponent = () => (
        <Modal show={show} onHide={handleClose} animation={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{reservation ? <p>{lang[selctedLang].reservation}</p> : <p>{lang[selctedLang].remove}</p>}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    !check
                        ? reservation ? <p>Check-in/Check-out</p> : <p>{lang[selctedLang].remove}</p>
                        : view
                }
                {check && (
                    <Row>
                        <Col>
                            <Button variant={'secondary'} onClick={() => {
                                setCheck(false)
                                setView(displayView.checkIn)
                            }}>
                                {lang[selctedLang].cancel}
                            </Button>
                        </Col>
                    </Row>)
                }



            </Modal.Body>
            <Modal.Footer>
                <Row style={{ width: '100%' }}>
                    {!check && (

                        <Col>
                            <Button variant={'info'} onClick={() => {
                                setCheck(true)
                                setView(displayView.checkIn)
                            }}>
                                {'Check-in'}
                            </Button>
                        </Col>
                    )}

                    {!check && (
                        <Col>
                            <Button variant={'info'} onClick={() => {
                                setCheck(true)
                                setView(displayView.checkOut)
                            }}>
                                {'Check-out'}
                            </Button>
                        </Col>
                    )}



                    <Col>
                        <Button variant="secondary" onClick={handleClose}>
                            {lang[selctedLang].cancel}
                        </Button>
                    </Col>
                </Row>


                {!reservation &&
                    <Button variant={'danger'} onClick={removeReservation}>
                        {lang[selctedLang].remove}
                    </Button>}
            </Modal.Footer>
        </Modal >
    )

    return (
        <div>
            <SlotTemplate slot={slot} />
            <ModalComponent />
        </div>
    )

}


export default (SlotReservedSkipper)