import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShip, faBalanceScaleRight, faCalendarDay, faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';
import { useHistory, withRouter } from 'react-router-dom';
import { ReactComponent as creditsSvg } from '../../assets/img/credit.svg'
import { ReactComponent as vesselsSvg } from '../../assets/img/vessels.svg'
import { ReactComponent as reservationsSvg } from '../../assets/img/reservations.svg'
import { ReactComponent as messageBoardSvg } from '../../assets/img/messageboard.svg'
import { ReactComponent as userProfileSvg } from '../../assets/img/userprofile.svg'
import { Image } from 'react-bootstrap';

import { ReactComponent as Morning } from '../../assets/img/morning.svg'
import { ReactComponent as Afternoon } from '../../assets/img/afternoon.svg'
import { ReactComponent as Evening } from '../../assets/img/evening.svg'
import { ReactComponent as WaitingSvg } from '../../assets/img/waiting.svg'
import { ReactComponent as Remove } from '../../assets/img/remove.svg'
import { ReactComponent as AddWaiting } from '../../assets/img/addtowaitinglist.svg'
import { ReactComponent as CheckOut } from '../../assets/img/check_out_bt.svg'
import { ReactComponent as CheckIn } from '../../assets/img/check_in_bt.svg'


import { themeColor, colorRemove } from '../../utils/calendar-utils';
import { notify } from '../../utils/ScreenNotifier';

export const Icons: { [k: string]: any } = {
    Morning: <Morning style={{ width: 30, height: 30 }} fill={'#D9AD58'} />,
    Afternoon: <Afternoon style={{ width: 30, height: 30 }} fill={'#D78062'} />,
    Evening: <Evening style={{ width: 30, height: 30 }} fill={'#9889B5'} />,
    Waiting: <WaitingSvg style={{ width: 12, height: 12 }} fill={themeColor} />,
    Remove: <Remove style={{ width: 20, height: 20 }} fill={colorRemove} />,
    AddWaiting: <AddWaiting style={{ width: 20, height: 20 }} fill={themeColor} />,
    CheckIn: <CheckIn style={{ width: 65, height: 45 }} fill={themeColor} />,
    CheckOut: <CheckOut style={{ width: 65, height: 45 }} fill={themeColor} />,
    CheckInSM: <CheckIn style={{ width: 35, height: 25 }} fill={themeColor} />,
    CheckOutSM: <CheckOut style={{ width: 35, height: 25 }} fill={themeColor} />,
}



interface BottomNavigationProps { }

const BottomNavigation = (props: BottomNavigationProps) => {
    const history = useHistory();
    const [menuItems, setMenuItems] = React.useState([
        {
            route: '/credits',
            icon: creditsSvg
        },
        {
            route: '/userVessels',
            icon: vesselsSvg
        },
        {
            route: '/reservations',
            icon: reservationsSvg
        },
        {
            route: '/userMessages',
            icon: messageBoardSvg
        },
        {
            route: '/profile',
            icon: userProfileSvg
        },
    ])

    const [active, setActive] = React.useState(menuItems[2])

    const onClick = async (item: any) => {
        history.replace({ pathname: item.route })
        setActive(item)
        await notify(item.route)
    }


    return (
        <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%', zIndex: 1000 }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', backgroundColor: '#FFFFFF', paddingTop: 10, paddingBottom: 10 }}>
                {
                    menuItems.map((item) => {
                        return (
                            <div key={`${item.route}`} onClick={() => onClick(item)}>
                                {item === active ? <item.icon style={{ width: 30, height: 30 }} fill={'#53AFB9'} /> : <item.icon style={{ width: 30, height: 30 }} fill={'#E9E9E9'} />}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default withRouter(BottomNavigation);
