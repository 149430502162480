import { ACTIONS_RESERVATION_SKIPPER } from "../actions/reservation-skipper"
import { Slot, ReservationSkipper } from "../../models/models"

interface IState {
    reservationSkipper: ReservationSkipper[],
    error: string
}

export const initialState: IState = { reservationSkipper: [], error: '' }

export const reservationSkipper = (state = initialState, action: any) => {
    switch (action.type) {
        case ACTIONS_RESERVATION_SKIPPER.ADD_RESERVATION_SKIPPERS_SUCCESS:

            const exists = state.reservationSkipper.findIndex(x => x.id===action.data.id)
            if(exists > -1){
                state.reservationSkipper[exists] = action.data as ReservationSkipper
            }else{
                state.reservationSkipper.push(action.data as ReservationSkipper)
            }

            return {
                ...state,
            }
        case ACTIONS_RESERVATION_SKIPPER.ADD_RESERVATION_SKIPPERS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_RESERVATION_SKIPPER.DELETE_RESERVATION_SKIPPERS_SUCCESS:
            const index = state.reservationSkipper.findIndex(x => x.id===action.data.id)
            state.reservationSkipper.splice(index, 1)
            return {
                ...state,
            }

            
        case ACTIONS_RESERVATION_SKIPPER.DELETE_RESERVATION_SKIPPERS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_RESERVATION_SKIPPER.GET_RESERVATION_SKIPPERS_SUCCESS:
            return {
                ...state,
                reservationSkipper: action.data
            }
        case ACTIONS_RESERVATION_SKIPPER.GET_RESERVATION_SKIPPERS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        default:
            return state
    }
}

export default reservationSkipper