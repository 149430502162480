import { ACTIONS_WAITING_LIST } from "../actions/waiting-list"
import { WaitingList } from "../../models/models"

interface IState {
    waitingList: WaitingList[],
    error: string
}

export const initialState: IState = { waitingList: [], error: '' }

export const waitingList = (state = initialState, action: any) => {
    switch (action.type) {
        case ACTIONS_WAITING_LIST.ADD_WAITING_LIST_SUCCESS:
            
            const exists = state.waitingList.findIndex(x => x.id===action.data.id)
            if(exists > -1){
                state.waitingList[exists] = action.data as WaitingList
            }else{
                state.waitingList.push(action.data as WaitingList)
            }

            return {
                ...state,
            }
        case ACTIONS_WAITING_LIST.ADD_WAITING_LIST_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_WAITING_LIST.DELETE_WAITING_LIST_SUCCESS:
            const waitingList = state.waitingList.filter((waitingList: WaitingList)=> waitingList.id !== +action.data.id)
            return {
                ...state,
                waitingList
            }
        case ACTIONS_WAITING_LIST.DELETE_WAITING_LIST_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_WAITING_LIST.GET_WAITING_LIST_SUCCESS:
            return {
                ...state,
                waitingList: action.data
            }
        case ACTIONS_WAITING_LIST.GET_WAITING_LIST_FAILURE:
            return {
                ...state,
                error: action.data
            }
        default:
            return state
    }
}

export default waitingList