import * as React from 'react';
import { Slot, Reservation, WaitingList, QuestionAnswer, SkipperAvailability } from '../../../../../models/models';
import { Button, Modal, Row, Col, Container } from 'react-bootstrap';
import { months } from '../../../../../utils/calendar-utils';
import { SlotItemProps, getStyle, AVAILABLE, RESERVED, WAITING_LIST } from '../../slot-type-interface';
import RemoteActions from '../../../../../remote/remote-actions';
import { UserRoles } from '../../../../../utils/utils';
import { Link, Router, useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { filterUser } from '../../../../../utils/utils';
import { isValidToRemoveReservation } from '../../../../../utils/reservation-utils';
import ReservationDetailsScreen from '../../../../../screens/reservation/reservation-details-screen';
import { RenderSlot } from '../../../slot-item';
import { lang, selctedLang } from '../../../../../lang';

export interface SlotReservedProps extends SlotItemProps {
    slotType: string;
    dayType: string;
    reservation: Reservation | undefined;
    skippersAvailability: SkipperAvailability[];
    waitingList: WaitingList[];
    answers: QuestionAnswer[];
    dispatch: Dispatch;
    date: Date;
}

const SlotReservedCustomer = ({ dispatch, date, reservation, user, vessel, membership, bookingRules, reservations, waitingList, day, month, year, slot, slotType, dayType, answers, skippersAvailability }: SlotReservedProps) => {
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [slotState, setSlotState] = React.useState<any>({});

    React.useEffect(() => {

        const onWaitingList = waitingList.filter((waitingList: WaitingList) => waitingList.date === reservation!.date && waitingList.slot === reservation!.slot && waitingList.user.id === user.id)

        if (reservation!.user.id === user.id) {
            setSlotState({
                title: lang[selctedLang].reservation,
                body: '',
                belongsToUser: 'true',
                variant: 'danger',
                waitingList: onWaitingList.length ? true : false,
            })
        } else {
            setSlotState({
                title: lang[selctedLang].addWaitingList,
                body: lang[selctedLang].addWaitingListBody(slot, vessel, day, month, year, membership),
                variant: 'primary',
                belongsToUser: 'false',
                waitingList: onWaitingList.length ? true : false,
            })
        }



    }, [])

    const removeReservation = () => {
        isValidToRemoveReservation(date, reservation!, vessel)
        RemoteActions.deleteReservation(reservation!.id, dispatch)
    }

    const makeWaittingListReservation = () => {
        const waitingList: WaitingList = {
            user: filterUser(user),
            membership: membership!,
            slot: reservation!.slot,
            date: reservation!.date,
            vessel: reservation!.vessel,
        }
        RemoteActions.setWaitingList(waitingList, dispatch)
    }

    const history = useHistory();
    const goToDetails = () => {
        const state = { date, reservation, user, vessel, membership, bookingRules, reservations, day, month, year, slot, slotType, dayType }
        history.push({ pathname: '/reservationDetails', state })
    }

    const actions: { [k: string]: any } = {
        [UserRoles.ADMIN]: goToDetails,
        [UserRoles.SUPER_USER]: goToDetails,
        [UserRoles.MEMBER_CLUB]: handleShow,
        [UserRoles.CERTIFIED_CLIENT]: handleShow,
    }

    const SlotTemplate = ({ slot }: { slot: Slot }) => {
        const validCancelation = isValidToRemoveReservation(date, reservation!, vessel)

        return (
            <div style={{ width: 4, height: 4, borderRadius: 2, backgroundColor: '#53AFB9', marginRight: 2 }} />
        )

        // if (!validCancelation && slotState.belongsToUser === 'true') {
        //     return (
        //         <Col key={`${slot.id}-${day}-${month}-${year}`} style={getStyle(slotType, dayType, WAITING_LIST)} className="event-desc list-group-item list-group-item-action list-group-item-light">
        //             <Row>{reservation?.membership.title}</Row>
        //             <Row>{reservation?.user.firstName} {reservation?.user.lastName}</Row>
        //             <Row style={{ fontSize: 12 }}>
        //                 {slot.name} {slot.range}
        //             </Row>
        //         </Col>
        //     )
        // }

        return (
            <Button onClick={actions[user.role!]} key={`${slot.id}-${day}-${month}-${year}`} style={{ ...getStyle(slotType, dayType, RESERVED), color: 'white' }} className="event-desc list-group-item list-group-item-action list-group-item-light">
                <Row style={{ fontSize: 14 }}>
                    {reservation?.membership.title}
                </Row>
                <Row >
                    <Col xs={12} style={{ fontSize: 14 }}>
                        {reservation?.user.firstName} {reservation?.user.lastName}
                    </Col>
                </Row>
                <RenderSlot slot={slot} />
            </Button>
        )
    }

    const OnWaitingList = ({ slot }: { slot: Slot }) => (
        <Col key={`${slot.id}-${day}-${month}-${year}`} style={{ ...getStyle(slotType, dayType, WAITING_LIST), color: 'white' }} className="event-desc list-group-item list-group-item-action list-group-item-light">
            <Row>{lang[selctedLang].addWaitingList}</Row>
            <Row style={{ fontSize: 14 }}>
                {reservation?.membership.title}
            </Row>
            <Row >
                <Col xs={12} style={{ fontSize: 14 }}>
                    {reservation?.user.firstName} {reservation?.user.lastName}
                </Col>
            </Row>
            <RenderSlot slot={slot} />
        </Col>
    )

    const modalAction: { [k: string]: () => void; } = {
        'true': removeReservation,
        'false': makeWaittingListReservation,
    }

    const ModalComponent = () => (
        <Modal show={show} onHide={handleClose} animation={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{slotState.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{slotState.body}</p>
                {slotState.belongsToUser === 'true' && <ReservationDetailsScreen disable={['checkin']} state={{ date, reservation, user, vessel, membership, bookingRules, reservations, day, month, year, slot, slotType, dayType, answers, skippersAvailability }} />}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {lang[selctedLang].cancel}
                </Button>


                {slotState.belongsToUser === 'false' &&
                    <Button variant={slotState.variant} onClick={modalAction[slotState.belongsToUser]}>
                        {lang[selctedLang].save}
                    </Button>}
            </Modal.Footer>
        </Modal>
    )

    return (
        <SlotTemplate slot={slot} />
        // <div>
        //     {!slotState.waitingList ? <SlotTemplate slot={slot} /> : <OnWaitingList slot={slot} />}
        //     <ModalComponent />
        // </div>
    )

}


const mapStateToProps = (state: any) => {

    return {
        waitingList: state.waitingList.waitingList,
        date: state.reservations.date,
        skippersAvailability: state.skipperAvailability.skipperAvailability
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(SlotReservedCustomer)