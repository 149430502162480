import { ACTIONS_VESSEL } from "../actions/vessel"
import { Vessel } from "../../models/models"

interface IState {
    vessels: Vessel[],
    error: string
}

export const initialState: IState = { vessels: [], error: '' }

export const vessels = (state = initialState, action: any) => {
    switch (action.type) {
        case ACTIONS_VESSEL.ADD_VESSELS_SUCCESS:

            const exists = state.vessels.findIndex(x => x.id === action.data.id)
            if (exists > -1) {
                state.vessels[exists] = action.data as Vessel
            } else {
                state.vessels.push(action.data as Vessel)
            }
            
            return {
                ...state,
            }
        case ACTIONS_VESSEL.ADD_VESSELS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_VESSEL.DELETE_VESSELS_SUCCESS:
            const vessels = state.vessels.filter((vessel: Vessel) => vessel.id !== action.data.id)
            return {
                ...state,
                vessels
            }
        case ACTIONS_VESSEL.DELETE_VESSELS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_VESSEL.GET_VESSELS_SUCCESS:
            return {
                ...state,
                vessels: action.data
            }
        case ACTIONS_VESSEL.GET_VESSELS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        default:
            return state
    }
}

export default vessels