

import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Container, Row, Col } from 'react-bootstrap';
import * as Ably from "ably";
import { ADD_RESERVATIONS_SUCCESS, DELETE_RESERVATIONS_SUCCESS } from "../../redux/actions/reservation";
import { ADD_SPLIT_SLOTS_SUCCESS, DELETE_SPLIT_SLOTS_SUCCESS } from "../../redux/actions/split-slot";
import { ADD_WAITING_LIST_SUCCESS, DELETE_WAITING_LIST_SUCCESS } from "../../redux/actions/waiting-list";
import RemoteActions from "../../remote/remote-actions";
import { User } from "../../models/models";



const myId =
    "id-" +
    Math.random()
        .toString(36)
        .substr(2, 16);





const actionList: any = {
    postReservation: { actionType: 'dispatch', action: ADD_RESERVATIONS_SUCCESS },
    deleteReservation: { actionType: 'dispatch', action: DELETE_RESERVATIONS_SUCCESS },

    postSplitSlot: { actionType: 'method', action: RemoteActions.getSplitSlot },
    deleteSplitSlot: { actionType: 'dispatch', action: DELETE_SPLIT_SLOTS_SUCCESS },

    postWaitingList: { actionType: 'method', action: RemoteActions.getWaitingLists },
    deleteWaitingList: { actionType: 'dispatch', action: DELETE_WAITING_LIST_SUCCESS },
}


interface RealtimeProps {
    dispatch: Dispatch;
    user: User;
}


const Realtime = (props: RealtimeProps) => {

    React.useEffect(() => {

        if (props.user && props.user.realtime) {
            const { apiKey, channel, updateEvent } = props.user.realtime

            // Ably Instance
            const ably = new Ably.Realtime({
                key: apiKey,
                clientId: myId,
                echoMessages: false
            });

            const chatChannel = ably.channels.get(channel!);
            chatChannel.subscribe(updateEvent!, data => {
                var dataObj = JSON.parse(JSON.parse(JSON.stringify(data)).data)
                const action = actionList[dataObj.method]

                if (action) {
                    if (action.actionType === 'dispatch') {
                        props.dispatch(action.action(dataObj.data))
                    } else if (action.actionType === 'method') {
                        action.action(props.dispatch)
                    }
                }

            });
        }


    }, [props.user]);


    return (
        <Container style={{ display: 'none' }}>
        </Container>
    )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

const mapStateToProps = (state: any) => {
    return {
        user: state.login.user,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Realtime);


