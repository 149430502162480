import * as React from 'react';
import { DynamicFormStyles } from '../dynamic-form';

export const TypeBoolean = ({ label, value, _key, editable, onChange }: any) => {

    const handleChange = (event: any) => {
        onChange({ label: _key, value: !value });
    }

    return (
        <div className="row" key={`${_key}_row`} style={{ margin: 5, flexDirection:'row', alignItems:'center', paddingLeft: 15 }}>
            <div>
                <span className="badge badge-default">{label}</span>
            </div>
            <div style={{ padding: 10, width: 50}}>
                <input style={DynamicFormStyles.input} key={`${_key}`} disabled={!editable} type="checkbox" checked={value} onChange={handleChange} />
            </div>
        </div>
    )
}