import * as React from 'react';
import { RemoteActions } from '../../remote/remote-actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux'
import ItemsList from '../../components/generic/items-list';
import { Slot, Question, QuestionGroup, User } from '../../models/models';
import DynamicForm from '../../components/generic/dynamic-form/dynamic-form';
import question from '../../redux/reduceres/check-form/question';
import { UserRoles } from '../../utils/utils';

var md5 = require('md5');



interface UsersScreenProps {
    dispatch: Dispatch,
    users: User[];
    refesh: string;
}

const UsersScreen: React.FC<any> = ({ dispatch, users, refesh }: UsersScreenProps) => {

    React.useEffect(() => {
        RemoteActions.getUsers(dispatch)
    }, [])


    return (
        <div className="container-fluid">
            <h1>Users</h1>
            <div style={{ opacity: 0.0 }}>{refesh}</div>
            <ItemsList
                disableNew
                style={{}}
                onSelectItem={() => { }}
                onEditItem={(user: User) => {
                    const _user = {...user}
                    if (user.password && user.password !== '') {
                        _user.password = md5(user.password)
                    }
                    return RemoteActions.setUser(_user, dispatch)
                }}
                dataTemplate={{
                    user: '',
                }}
                onDeleteItem={(user: User) => {
                    return RemoteActions.deleteUser(user.id, dispatch)
                }}
                listTemplate={(user: User) => {
                    return (
                        <div>
                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">First Name</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${user.firstName}`}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">lastName</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${user.lastName}`}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">email</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${user.email}`}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Role</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${user.role}`}</span>
                                </div>
                            </div>

                            
                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">eMail notifications is</span>
                                </div>
                                <div className="col-md-10">
                                {user.receiveMails ? <span className="font-weight-bold" style={{color: 'green'}}>Active</span> : <span className="font-weight-bold" style={{color: 'red'}}>Disable</span>}
                                </div>
                            </div>
                        </div>
                    )
                }}
                newItemFormTemplate={({ item, editing, onChange }: { item: User, editing: boolean, onChange: any }) => {
                    const labels: any = {
                        firstName: 'First Name',
                        lastName: 'Last Name',
                        password: 'Password',
                        receiveMails: 'Receive email notifications'
                    }
                    return (
                        <div>
                            <DynamicForm
                                exclude={{ role: '' }}
                                data={{ ...item, password: '' }}
                                labels={labels}
                                editable={editing}
                                onDataChange={(data: any) => {
                                    onChange(data)
                                }}
                            />

                            <div style={{ borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid', padding: 10, margin: 10 }}>
                                <div style={{ fontWeight: 'bold', padding: 15 }}>User Role</div>

                                <select id="combo2" value={item.role} onChange={(event) => {
                                    onChange({ role: event.target.value })
                                }}>
                                    <option value={UserRoles.MEMBER_CLUB}>{UserRoles.MEMBER_CLUB}</option>
                                    <option value={UserRoles.CERTIFIED_CLIENT}>{UserRoles.CERTIFIED_CLIENT}</option>
                                    <option value={UserRoles.TURISTIC_OPERATOR}>{UserRoles.TURISTIC_OPERATOR}</option>
                                    <option value={UserRoles.SKIPPER}>{UserRoles.SKIPPER}</option>
                                    <option value={UserRoles.ADMIN}>{UserRoles.ADMIN}</option>

                                </select>
                            </div>


                        </div>
                    )
                }}
                list={users} />
        </div>
    );
};


const mapStateToProps = (state: any) => {
    const reducer = {
        users: state.users.users,
        refesh: `${Date.now()}`,
    }
    return reducer
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersScreen);

const styles = ({
    container: {}
});
