import { Slot, Membership, Vessel } from "../models/models"
import { months } from "../utils/calendar-utils"



const pt = {
    schedule: 'Reservar',
    save: 'Guardar',
    cancel: 'Cancelar',
    remove: 'Remover',
    removeExpiredReservation: 'Esta reserva está fora do periodo de cancelamento, se remover o credito será descontado',
    removeReservation: 'Remover esta reserva?',
    checkIn: 'Check-in',
    checkOut: 'Check-out',
    free: 'Gratuito',
    reservation: 'Reserva',
    reservationConfirmation: (slot: Slot, vessel: Vessel, day: any, month: any, year: any, membership: Membership) => `Reservar o slot ${slot.range} no barco ${vessel.name} para ${day} ${months[month]} ${year} com a conta ${membership.title}`,
    splitSlot: 'Dividir Slot',
    dontSplitSlot: 'Não Dividir Slot',
    removeSplitSlot: 'Remover slot dividido',
    details: 'Detalhes da Reserva',
    addWaitingList: 'Adicionar para a waiting list',
    addWaitingListBody: (slot: Slot, vessel: Vessel, day: any, month: any, year: any, membership: Membership) => `Adicionar entrada para lista de espera no slot ${slot.range} do barco ${vessel.name} para ${day} ${months[month]} ${year} com a conta ${membership.title}`,
    membership: 'Conta',
    vessel: 'Barco',
    user: 'Utilizador',
    date: 'Data',
    slot: 'Slot',
    whoReserved: 'Registado por',
    manageSkippers: 'Gerir Tripulação',
    loading: 'A Carregar Dados',
    skippers: 'Tripulação',

    availableSlots: 'Slots disponíveis',
    workingdaySlots: 'Slots dias úteis',
    weekendSlots: 'Slots fim de semana',


    availableCreditsForThisMonth: 'Créditos disponíveis para este mês',
    currentMonth: 'Mês atual',
    borrowCredits: 'Créditos emprestados',
    total: 'Total',
    previousMonth: 'Mês anterior',
    nextMonth: 'Mês seguinte',
    maxNumberOfAvailableCreditsForThisMonth: 'Número maximo de slots dísponiveis este mês',
    usedCreditsThisMonth: 'Créditos utilizados este mês',
    creditMonth: 'Mês créditado',
    remarks: 'Remarks',
    Morning: 'Manhã',
    Afternoon: 'tarde',
    Evening: 'Noite',

    vacant: 'Livre',
    reserved: 'Reservado',
    half: 'Dividido',

    yes: 'Sim',
    no: 'Não',

    next: 'Seguinte',
    previous: 'Anterior',
    submit: 'Submeter',

    // menssages

    removeConfirmation: 'Tem a certeza que pretende remover este comentário?',
    newMessage: 'Publicar nova mensagem',
    message: 'Texto de mensagem',
    image: 'Imagem para upload'


}

export default pt