import { ACTIONS_BOOKING_RULES } from "../actions/booking-rules"
import { BookingRules } from "../../models/models"

interface IState {
    bookingRules: BookingRules[],
    error: string
}

export const initialState: IState = { bookingRules: [], error: '' }

export const bookingRules = (state = initialState, action: any) => {
    switch (action.type) {
        case ACTIONS_BOOKING_RULES.ADD_BOOKING_RULES_SUCCESS:

            const exists = state.bookingRules.findIndex(x => x.id === action.data.id)
            if (exists > -1) {
                state.bookingRules[exists] = action.data as BookingRules
            } else {
                state.bookingRules.push(action.data as BookingRules)
            }

            return {
                ...state,
            }
        case ACTIONS_BOOKING_RULES.ADD_BOOKING_RULES_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_BOOKING_RULES.DELETE_BOOKING_RULES_SUCCESS:
            const bookingRules = state.bookingRules.filter((rule: BookingRules) => rule.id !== action.data.id)
            return {
                ...state,
                bookingRules
            }
        case ACTIONS_BOOKING_RULES.DELETE_BOOKING_RULES_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_BOOKING_RULES.GET_BOOKING_RULES_SUCCESS:
            return {
                ...state,
                bookingRules: action.data
            }
        case ACTIONS_BOOKING_RULES.GET_BOOKING_RULES_FAILURE:
            return {
                ...state,
                error: action.data
            }

        case ACTIONS_BOOKING_RULES.GET_BOOKING_RULES_CHECK_FORM_SUCCESS:
            const br = state.bookingRules.findIndex(x => x.id === action.data.id)
            const newBr = [...state.bookingRules]
            if (br > -1) {
                newBr[br] = { ...newBr[br], ...action.data } as BookingRules
            }else{
                newBr.push({ ...newBr, ...action.data })
            }

            return {
                bookingRules: newBr,
            }
        case ACTIONS_BOOKING_RULES.GET_BOOKING_RULES_CHECK_FORM_FAILURE:
            return {
                ...state,
                error: action.data
            }

        default:
            return state
    }
}

export default bookingRules