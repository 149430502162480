import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { User, Membership, BookingRules, Slot, Vessel, Reservation, Holiday } from '../../models/models';
import { Row, Container, Col } from 'react-bootstrap';
import ReservationsMonthDetail from './reservations-month-detail';

import RemoteActions from '../../remote/remote-actions';
import AvailableCreditsReports from './available-credits';
import Brief from './brief';
import { lang, selctedLang } from '../../lang';
import { faCaretSquareLeft, faCaretSquareRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { months, getPreviousMonth, getNextMonth } from '../../utils/calendar-utils';


interface CreditsScreenProps {
    user: User;
    dispatch: Dispatch;
    reservations: Reservation[],
    refesh: string,
    date: Date,
    memberships: Membership[],
    holidays: Holiday[],
}

const SELECTED_MEMBERSHIP = 'SELECTED_MEMBERSHIP'
const SELECTED_VESSEL = 'SELECTED_VESSEL'

const CreditsScreen = (props: CreditsScreenProps) => {

    const getSelectedMembership = () => {
        const selectedMembership = localStorage.getItem(SELECTED_MEMBERSHIP)

        if (selectedMembership) {
            const stored = JSON.parse(selectedMembership)
            const membership = props.memberships!.find((membership: Membership) => membership.id === stored.id)
            if (membership)
                return membership!
        }

        if (props.memberships)
            return props.memberships[0]
        else
            return undefined
    }

    const [membership, setMembership] = React.useState<Membership | undefined>()

    const getSelectedVessel = () => {

        const selectedVessel = localStorage.getItem(SELECTED_VESSEL)

        if (selectedVessel && membership) {
            const stored = JSON.parse(selectedVessel!)
            const vessel = membership!.vessels!.find((_vessel: Vessel) => _vessel.id === stored.id)
            if (vessel)
                return vessel!
        }

        if (membership && membership!.vessels)
            return membership!.vessels[0]
        else
            return undefined
    }


    const [vessel, setVessel] = React.useState<Vessel | undefined>()
    const [viewDate, setViewDate] = React.useState({ month: props.date.getMonth(), year: props.date.getFullYear() })
    const [monthName, setMonthName] = React.useState(months[props.date.getMonth()])

    React.useEffect(() => {
        setViewDate({ month: props.date.getMonth(), year: props.date.getFullYear() })
        setMonthName(months[props.date.getMonth()])
    }, [props.date])

    React.useEffect(() => {
        setMembership(getSelectedMembership())
        setVessel(getSelectedVessel())
    }, [props.memberships, membership])


    React.useEffect(() => {
        RemoteActions.getHoliday(props.dispatch, new Date().getFullYear())
        RemoteActions.getServerDate(props.dispatch)

        RemoteActions.getBookingRules(props.dispatch)
        RemoteActions.refreshUserMemberships(props.dispatch)
    }, [])

    React.useEffect(() => {
        if (vessel && viewDate) {
            RemoteActions.getReservationWithStartMonth(props.dispatch, `${viewDate.year}-${viewDate.month}`, `${vessel.bookingRules.id}`)
        }
    }, [vessel, viewDate])

    const getDate = (_date: any) => {
        const date = new Date()
        date.setMonth(_date.month)
        date.setFullYear(_date.year)
        return date
    }

    return (
        <Container>
            {props.user && props.memberships && membership && <Row style={{ paddingLeft: 15, marginBottom: 15 }}>
                <Col md='1' style={{ textAlign: 'start' }}>{lang[selctedLang].membership}</Col>
                <Col>
                    <select id="combo" onChange={(event) => {
                        setMembership(JSON.parse(event.target.value))
                        localStorage.setItem(SELECTED_MEMBERSHIP, event.target.value)
                    }}>
                        {props.memberships!.map((element: Membership, index: number) => {
                            const isSelected = membership?.id === element.id
                            return isSelected
                                ?
                                <option selected key={`${index}`} value={JSON.stringify(element)}>{element.title}</option>
                                :
                                <option key={`${index}`} value={JSON.stringify(element)}>{element.title}</option>

                        })}
                    </select>
                </Col>
            </Row>}

            {!membership &&
                <div className="d-flex justify-content-center" style={{ textAlign: 'center' }}>
                    <Row>
                        <h3>Loading</h3>
                    </Row>

                    <Row style={{ marginLeft: 25 }}>
                        <div className="spinner-border" role="status">
                            <span className="sr-only">{lang[selctedLang].loading}...</span>
                        </div>
                    </Row>
                </div>}

            {membership &&
                <Row style={{ paddingLeft: 15, marginBottom: 15 }}>
                    <Col md='1' style={{ textAlign: 'start' }}>{lang[selctedLang].vessel}</Col>
                    <Col>

                        <select id="combo" onChange={(event) => {
                            const _vessel: Vessel = JSON.parse(event.target.value)
                            setVessel(_vessel)
                            localStorage.setItem(SELECTED_VESSEL, event.target.value)
                        }}>
                            {membership!.vessels.map((element: any, index: number) => {
                                const isSelected = vessel?.id === element.id
                                return isSelected
                                    ?
                                    <option selected key={`${index}`} value={JSON.stringify(element)}>{element.name}</option>
                                    :
                                    <option key={`${index}`} value={JSON.stringify(element)}>{element.name}</option>
                            })}
                        </select>

                    </Col>
                </Row>}

            <Row>
                <Col>
                    <div style={{ margin: 20, float: 'right', borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid' }}>
                        <button style={{ float: 'left' }} onClick={() => {
                            const newData = getPreviousMonth(viewDate)
                            setMonthName(months[newData.month])
                            setViewDate(newData)
                        }}>
                            <FontAwesomeIcon icon={faCaretSquareLeft} />
                        </button>
                        <div style={{ float: 'left', width: 80, textAlign: 'center' }}>{monthName}</div>
                        <div style={{ float: 'left', textAlign: 'center', marginRight: 15 }}>{viewDate.year}</div>
                        <button style={{ float: 'left' }} onClick={() => {
                            const newData = getNextMonth(viewDate)
                            setMonthName(months[newData.month])
                            setViewDate(newData)
                        }}>
                            <FontAwesomeIcon icon={faCaretSquareRight} />
                        </button>
                    </div>
                </Col>
            </Row>

            <Row>

                {
                    membership && vessel &&
                    <Col>
                        <AvailableCreditsReports reservations={props.reservations} month={viewDate.month} year={viewDate.year} bookingRules={vessel!.bookingRules} membership={membership} holidays={props.holidays} />
                    </Col>
                }

            </Row>

            <Row>

                {
                    membership && vessel &&
                    <Col>
                        <Brief reservations={props.reservations} month={viewDate.month} year={viewDate.year} bookingRules={vessel!.bookingRules} membership={membership} holidays={props.holidays} />
                    </Col>
                }

            </Row>

            <Row>

                {
                    membership && vessel && props.reservations &&
                    <Col>
                        <ReservationsMonthDetail reservations={props.reservations} membership={membership} month={viewDate.month} year={viewDate.year} holidays={props.holidays} />
                    </Col>
                }

            </Row>





        </Container >
    );
};


/**
 * controlles login state
 * @param state 
 */
const mapStateToProps = (state: any) => {
    return {
        user: state.login.user,
        reservations: state.reservations.reservations,
        refesh: `${Date.now()}`,
        date: state.reservations.date,
        memberships: state.login.memberships,
        holidays: state.holiday.holiday,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(CreditsScreen);

