import * as React from 'react';
import { RemoteActions } from '../../remote/remote-actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux'
import ItemsList from '../../components/generic/items-list';
import { MembershipType } from '../../models/models';
import DynamicForm from '../../components/generic/dynamic-form/dynamic-form';




interface MembershipTypesScreenProps {
    dispatch: Dispatch,
    membershipTypes: MembershipType[];
    refesh: string;
}

const MembershipTypesScreen: React.FC<any> = ({ dispatch, membershipTypes, refesh }: MembershipTypesScreenProps) => {

    React.useEffect(() => {
        RemoteActions.getMembershipTypes(dispatch)
    }, [])


    return (
        <div className="container-fluid">
            <h1>Membership Types</h1>
            <div style={{ opacity: 0.0 }}>{refesh}</div>
            <ItemsList
                style={{}}
                onSelectItem={() => { }}
                onEditItem={(membershipType: MembershipType) => {
                    return RemoteActions.setMembershipType(membershipType, dispatch)
                }}
                dataTemplate={{
                    name: '',
                    weekendSlots: 0,
                    weekSlots: 0,
                }}
                onDeleteItem={(membershipType: MembershipType) => {
                    return RemoteActions.deleteMembershipType(membershipType.id, dispatch)
                }}
                listTemplate={(membershipType: MembershipType) => {
                    return (
                        <div>
                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Name</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${membershipType.name}`}</span>
                                </div>
                            </div>

                            <div className="row">
                
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Week Slots</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${membershipType.weekSlots}`}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Weekend Slots</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${membershipType.weekendSlots}`}</span>
                                </div>
                            </div>
                        </div>
                    )
                }}
                newItemFormTemplate={({ item, editing, onChange }: { item: MembershipType, editing: boolean, onChange: any }) => {
                    const labels: any = {
                        name: 'Name',
                        weekSlots: 'Week Slots',
                        weekendSlots: 'Weekend Slots'
                    }
                    return (
                        <DynamicForm
                            data={item}
                            labels={labels}
                            editable={editing}
                            onDataChange={(data: any) => {
                                onChange(data)
                            }}
                        />
                    )
                }}
                list={membershipTypes} />
        </div>
    );
};


const mapStateToProps = (state: any) => {
    const reducer = {
        membershipTypes: state.membershipTypes.membershipType,
        refesh: `${Date.now()}`,
    }
    return reducer
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(MembershipTypesScreen);

const styles = ({
    container: {}
});
