export enum ACTIONS_SLOT {
    ADD_SLOTS_SUCCESS = 'ADD_SLOTS_SUCCESS',
    ADD_SLOTS_FAILURE = 'ADD_SLOTS_FAILURE',

    GET_SLOTS_SUCCESS = 'GET_SLOTS_SUCCESS',
    GET_SLOTS_FAILURE = 'GET_SLOTS_FAILURE',

    DELETE_SLOTS_SUCCESS = 'DELETE_SLOTS_SUCCESS',
    DELETE_SLOTS_FAILURE = 'DELETE_SLOTS_FAILURE',
}

export const ADD_SLOTS_SUCCESS = (data:any) => ({
    type: ACTIONS_SLOT.ADD_SLOTS_SUCCESS,
    data
})

export const ADD_SLOTS_FAILURE = (e:any) => ({
    type: ACTIONS_SLOT.ADD_SLOTS_FAILURE,
    error: e
})

export const DELETE_SLOTS_SUCCESS = (data:any) => ({
    type: ACTIONS_SLOT.DELETE_SLOTS_SUCCESS,
    data
})

export const DELETE_SLOTS_FAILURE = (e:any) => ({
    type: ACTIONS_SLOT.DELETE_SLOTS_FAILURE,
    error: e
})


export const GET_SLOTS_SUCCESS = (data:any) => ({
    type: ACTIONS_SLOT.GET_SLOTS_SUCCESS,
    data
})

export const GET_SLOTS_FAILURE = (e:any) => ({
    type: ACTIONS_SLOT.GET_SLOTS_FAILURE,
    error: e
})