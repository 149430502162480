import { ACTIONS_MEMBERSHIP } from "../actions/membership"
import { Membership } from "../../models/models"

interface IState {
    membership: Membership[],
    error: string
}

export const initialState: IState = { membership: [], error: '' }

export const membership = (state = initialState, action: any) => {

    switch (action.type) {
        case ACTIONS_MEMBERSHIP.ADD_MEMBERSHIPS_SUCCESS:

            const exists = state.membership.findIndex(x => x.id===action.data.id)
            if(exists > -1){
                state.membership[exists] = action.data as Membership
            }else{
                state.membership.push(action.data as Membership)
            }

            return {
                ...state,
            }
        case ACTIONS_MEMBERSHIP.ADD_MEMBERSHIPS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_MEMBERSHIP.DELETE_MEMBERSHIPS_SUCCESS:
            const index = state.membership.findIndex(x => x.id===action.data.id)
            state.membership.splice(index, 1)
            return {
                ...state,
            }
        case ACTIONS_MEMBERSHIP.DELETE_MEMBERSHIPS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_MEMBERSHIP.GET_MEMBERSHIPS_SUCCESS:
            return {
                ...state,
                membership: action.data
            }
        case ACTIONS_MEMBERSHIP.GET_MEMBERSHIPS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        default:
            return state
    }
}

export default membership