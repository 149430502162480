import { ACTIONS_SPLIT_SLOTS } from "../actions/split-slot"
import { SplitSlot } from "../../models/models"

interface IState {
    splitSlot: SplitSlot[],
    error: string
}

export const initialState: IState = { splitSlot: [], error: '' }

export const splitSlot = (state = initialState, action: any) => {
    switch (action.type) {
        case ACTIONS_SPLIT_SLOTS.ADD_SPLIT_SLOTS_SUCCESS:

            const exists = state.splitSlot.findIndex(x => x.id===action.data.id)
            if(exists > -1){
                state.splitSlot[exists] = action.data as SplitSlot
            }else{
                state.splitSlot.push(action.data as SplitSlot)
            }

            return {
                ...state,
            }
        case ACTIONS_SPLIT_SLOTS.ADD_SPLIT_SLOTS_FAILURE:
            return {
                ...state,
                error: action.data.sort()
            }
        case ACTIONS_SPLIT_SLOTS.DELETE_SPLIT_SLOTS_SUCCESS:
            const splitSlot = state.splitSlot.filter((splitSlot: SplitSlot)=> splitSlot.id !== +action.data.id)
            return {
                ...state,
                splitSlot
            }
        case ACTIONS_SPLIT_SLOTS.DELETE_SPLIT_SLOTS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_SPLIT_SLOTS.GET_SPLIT_SLOTS_SUCCESS:
            return {
                ...state,
                splitSlot: action.data
            }
        case ACTIONS_SPLIT_SLOTS.GET_SPLIT_SLOTS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        default:
            return state
    }
}

export default splitSlot