import * as React from 'react';
import { Dispatch } from 'redux'
import { Question, Reservation, QuestionAnswer } from '../../models/models';
import { Col, Row, Container, Button } from 'react-bootstrap';
import { groupBy } from '../../utils/utils';
import RemoteActions from '../../remote/remote-actions';
import { connect } from 'react-redux';
import answer from '../../redux/reduceres/check-form/answer';
import { lang, selctedLang } from '../../lang';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { themeColor } from '../../utils/calendar-utils';


interface FormScreenProps {
    dispatch: Dispatch;
    reservation?: Reservation[];
    answers: QuestionAnswer[];
    type: 'checkIn' | 'checkOut';
    onCancel: () => void;
}



const CheckBox = ({ answer, onChange, showError, checked }: { answer: QuestionAnswer, onChange: any, showError: boolean, checked: boolean }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
            <div style={{ width: '25%' }}>
                <label style={{ fontWeight: 'bold' }}>
                    Yes
                    <input checked={answer.answer === 'yes'} type="radio" id="yes" name={answer.question.question} value="yes" style={{ marginLeft: 5 }} onChange={(event: any) => onChange(event, answer)} />
                </label>
            </div>
            <div style={{ width: '25%', marginRight: 10 }}>
                <label style={{ fontWeight: 'bold' }}>
                    No
                    <input checked={answer.answer === 'no'} type="radio" id="no" name={answer.question.question} value="no" style={{ marginLeft: 5 }} onChange={(event: any) => onChange(event, answer)} />
                </label>
            </div>
            <div style={showError ? { color: 'red', width: '80%' } : { width: '80%' }}>
                {answer.question.question}
            </div>
        </div>
    )
}

const Text = ({ answer, onChange, showError, checked }: { answer: QuestionAnswer, onChange: any, showError: boolean, checked: boolean }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
            <div style={showError ? { color: 'red', width: '80%' } : { width: '80%' }}>
                {answer.question.question}
            </div>
            <div>
                <input type="text" id="yes" name="check" value={answer.answer} style={{ marginLeft: 5, width: '100%' }} onChange={(event: any) => onChange(event, answer)} />
            </div>
        </div>
    )
}

class FormScreen extends React.Component<FormScreenProps, { [k: string]: any }> {

    translateType = {
        checkIn: 'CHECK IN',
        checkOut: 'CHECK OUT'
    }

    constructor(props: FormScreenProps) {
        super(props)

        this.state = {
            pag: 0,
            answers: [],
            showError: false,
            checked: false,
            groups: [],
        }
    }

    QuestionTypes: any = {
        text: Text,
        check: CheckBox
    }

    componentDidMount = () => {
        const answers = this.props.answers.filter((answer: QuestionAnswer) => {
            return answer.type === this.props.type && answer.reservation && answer.reservation.filter((reservation: Reservation) => reservation.id === this.props.reservation![0].id).length
        })

        if (answers && answers.length) {
            this.setState({ answers, checked: true }, () => {
                const groupsDict: any = groupBy(this.state.answers, (questionAnswer: QuestionAnswer) => questionAnswer.question.questionGroup.group)
                const keys = [...groupsDict.keys()].sort()

                this.setState({ groups: keys }, () => {
                    this.RenderQuestions(this.state.answers)
                })
            })
        } else if (this.props.reservation![0].vessel.bookingRules[this.props.type]) {
            this.props.reservation![0].vessel.bookingRules[this.props.type].questions.forEach((question: Question) => {
                const answer: QuestionAnswer = {
                    question,
                    reservation: this.props.reservation!,
                    type: this.props.type,
                    answer: ''
                }
                this.state.answers.push(answer)
            })

            this.setState({ answers: this.state.answers }, () => {
                const groupsDict: any = groupBy(this.state.answers, (questionAnswer: QuestionAnswer) => questionAnswer.question.questionGroup.group)
                const keys = [...groupsDict.keys()].sort()

                this.setState({ groups: keys }, () => {
                    this.RenderQuestions(this.state.answers)
                })
            })
        }
    }


    onChange = (event: any, answer: QuestionAnswer) => {
        const index = this.state.answers.findIndex((_a: QuestionAnswer) => _a.question.id === answer.question.id)
        this.state.answers[index].answer = event.target.value
        this.setState({ answers: this.state.answers })
    }

    submitForm = () => {

        let isValidForm = true
        this.state.answers.forEach((answer: QuestionAnswer) => {
            if (answer.answer === '') {
                isValidForm = false
            }
        });

        if (!isValidForm) {
            this.setState({ showError: true })
        } else {
            // submit data
            const submit = async () => {
                for (let i = 0; i < this.state.answers.length; i++) {
                    const answer = this.state.answers[i];
                    await RemoteActions.setAnswer(answer, this.props.dispatch)
                }
                await RemoteActions.sendForm({ reservations: this.props.reservation!, type: this.props.type }, this.props.dispatch)
            }
            submit()
            this.props.onCancel()
        }

    }

    RenderQuestions = (questionAnswers: QuestionAnswer[]) => {

        const groupsDict: any = groupBy(questionAnswers, (questionAnswer: QuestionAnswer) => questionAnswer.question.questionGroup.group)

        const groupedObject: any[] = []
        const keys = [...groupsDict.keys()].sort()

        keys.forEach((value: any) => {
            const questions = groupsDict.get(value)
            const questionsParsed = questions.map((question: QuestionAnswer) => {
                const Type: any = this.QuestionTypes[question.question.responseType]
                return <Type key={`${question.question.id}`} answer={question} onChange={this.onChange} showError={this.state.showError && question.answer === ''} checked={this.state.checked} />
            })

            groupedObject.push(
                <Row>
                    <Col>
                        {questionsParsed}
                    </Col>
                </Row>
            )
        })

        return groupedObject
    }

    render() {

        const percentage = (100 * (this.state.pag + 1)) / this.state.groups.length

        return (
            <div style={{ width: '100%', padding: 15, overflow: 'scroll' }}>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                    <div style={{ width: 60 }}>
                        <CircularProgressbar styles={buildStyles({
                            rotation: 0.10,
                            strokeLinecap: 'butt',
                            textSize: '20px',
                            pathTransitionDuration: 2,
                            pathColor: themeColor,
                            textColor: 'black',
                            trailColor: 'lightGrey',
                            backgroundColor: 'rgba(0,0,0,0.0)',
                        })}
                            value={percentage}
                            text={`${this.state.pag + 1}/${this.state.groups.length}`}
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ fontWeight: 'bold', textAlign: 'right' }}>
                            {this.translateType[this.props.type]}
                        </div>

                        <div style={{ textAlign: 'right' }}>
                            {this.state.groups[this.state.pag]}
                        </div>

                    </div>
                </div>

                {this.RenderQuestions(this.state.answers)[this.state.pag]}

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: 20,
                    position: 'fixed',
                    bottom: 40,
                    left: 0,
                    right: 0,
                }}>

                    {this.state.pag > -1 &&
                        <div className='btHorizontal btCANCEL' onClick={() => {
                            this.state.pag === 0 ? this.props.onCancel() : this.setState({ pag: this.state.pag - 1 })
                        }}>
                            {this.state.pag === 0 ? lang[selctedLang].cancel : lang[selctedLang].previous}
                        </div>}

                    {this.state.pag < this.state.groups.length &&
                        <div className='btHorizontal btOK' style={{ alignSelf: 'flex-end' }} onClick={() => {
                            this.state.pag < this.state.groups.length - 1 ? this.setState({ pag: this.state.pag + 1 }) : this.submitForm()
                        }}>
                            {this.state.pag < this.state.groups.length - 1 ? lang[selctedLang].next : lang[selctedLang].submit}
                        </div>}

                </div>


            </div>
        );
    }
};



export default (FormScreen);


