import { ACTIONS_MEMBERSHIP_TYPE } from "../actions/membership-type"
import { MembershipType } from "../../models/models"

interface IState {
    membershipType: MembershipType[],
    error: string
}

export const initialState: IState = { membershipType: [], error: '' }

export const membershipTypes = (state = initialState, action: any) => {
    switch (action.type) {
        case ACTIONS_MEMBERSHIP_TYPE.ADD_MEMBERSHIP_TYPE_SUCCESS:

            const exists = state.membershipType.findIndex(x => x.id===action.data.id)
            if(exists > -1){
                state.membershipType[exists] = action.data as MembershipType
            }else{
                state.membershipType.push(action.data as MembershipType)
            }

            return {
                ...state,
            }
        case ACTIONS_MEMBERSHIP_TYPE.ADD_MEMBERSHIP_TYPE_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_MEMBERSHIP_TYPE.DELETE_MEMBERSHIP_TYPE_SUCCESS:
            const index = state.membershipType.findIndex(x => x.id===action.data.id)
            state.membershipType.splice(index, 1)
            return {
                ...state,
            }
        case ACTIONS_MEMBERSHIP_TYPE.DELETE_MEMBERSHIP_TYPE_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_MEMBERSHIP_TYPE.GET_MEMBERSHIP_TYPE_SUCCESS:
            return {
                ...state,
                membershipType: action.data
            }
        case ACTIONS_MEMBERSHIP_TYPE.GET_MEMBERSHIP_TYPE_FAILURE:
            return {
                ...state,
                error: action.data
            }
        default:
            return state
    }
}

export default membershipTypes