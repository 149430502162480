import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RemoteActions } from '../../remote/remote-actions';
import { User } from '../../models/models';
import { Redirect, Link } from 'react-router-dom';
import { LOGOUT } from '../../redux/actions/auth';
import { UserRoles } from '../../utils/utils';


interface LoginScreenProps {
    loginError: any;
    user: User;
    loggedIn: boolean;
    dispatch: Dispatch;
}

const styles = {
    form: {
        // borderWidth: 0.5,
        // borderColor: 'lightgrey',
        // borderStyle: 'solid',
        // borderRadius: 6,
        padding: 22,
    },
    login: {
        marginLeft: 'auto',
        marginRight: 'auto',
    }
}



const LoginScreen: React.FC<LoginScreenProps> = (props: LoginScreenProps) => {

    const [user, setUser] = React.useState<any>({ email: '', password: '' })
    const [loading, setLoading] = React.useState<Boolean>(false)

    React.useEffect(() => {
        localStorage.removeItem('user')
        props.dispatch(LOGOUT())
    }, [])

    const handleChange = (key: 'email' | 'password', value: any) => {
        let state = user
        state[key] = value
        const newState = { ...user, ...state }

        setUser(newState)
    }

    const LoggedIn = () => {
        if (props.user.role !== UserRoles.SKIPPER) {
            if (!props.user.memberships || !props.user.memberships.length) {
                return (<Redirect to={'/userMessages'} />)
            }
            return (<Redirect to={'/reservation'} />)
        } else {
            return (<Redirect to={'/skipperCalendar'} />)
        }
    }

    const LoginForm = () => {
        if (props.loginError && props.loginError.error?.disableSite) {
            console.log('error', props.loginError)
            return (<Redirect to={'/maintenance'} />)
        }

        return (
            <div className="container-fluid">

                <div className="row justify-content-center">

                    <div className="col-xl-10 col-lg-12 col-md-9">

                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                                    <div className="col-lg-6">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-4">Bem vindo!</h1>
                                                <h3 className="h5 mb-4">NauticSquad</h3>
                                            </div>
                                            <div className="user">
                                                <div className="div-group">
                                                    <input type="email" className="form-control form-control-user" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Email Address..." value={user.email} onChange={(event: any) => handleChange('email', event.target.value)} />
                                                </div>
                                                <div className="form-group">
                                                    <input type="password" className="form-control form-control-user" id="exampleInputPassword1" value={user.password} onChange={(event: any) => handleChange('password', event.target.value)} />
                                                </div>

                                                <button className="btn btn-primary btn-user btn-block" style={{ width: '100%' }} onClick={async () => {
                                                    try {
                                                        setLoading(true)
                                                        await RemoteActions.login(user, props.dispatch)
                                                    } catch (error) {
                                                        console.log(error)
                                                        setLoading(false)
                                                    }

                                                }}>
                                                    Login
                                                </button>

                                                <hr />

                                            </div>
                                            <div className="text-center">
                                                <a className="small" href="/passwordRecoveryScreen">Esqueceu a palavra pass?</a>
                                                {props.loginError && props.loginError.error && <span className="badge badge-danger">{JSON.stringify(props.loginError.error)}</span>}
                                                {loading && <div className="d-flex justify-content-center">
                                                    <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <style>{`          
            body{
                background-color: #4e73df;
                background-image: linear-gradient(180deg, #4e73df 10%, #224abe 100%);
                background-size: cover;
            }
            #main-container{
                overflow-y: hidden;
            }
            `}</style>

            </div>
        )
    };

    return props.loggedIn ? LoggedIn() : LoginForm()
};


/**
 * controlles login state
 * @param state
 */
const mapStateToProps = (state: any) => {

    return {
        loginError: state.login.error,
        user: state.login.user,
        loggedIn: state.login.loggedIn
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);

