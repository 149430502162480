import * as React from 'react';
import { FormTypes } from './types';


interface DynamicFormProps {
    exclude?: { [k: string]: any };
    data: { [k: string]: any };
    labels: { [k: string]: any };
    editable: boolean;
    onDataChange: (data: any) => void;

}

const DynamicForm = ({ data, editable, onDataChange, labels, exclude }: DynamicFormProps) => {

    const [dataState, setDataState] = React.useState(data)

    const onChange = ({ label, value }: any) => {
        const newState = { ...dataState, ...{ [label]: value } }
        if (!Array.isArray(value)) {
            setDataState(newState)
        }
        onDataChange({ [label]: value })
    }

    const RenderFields = (dataSet: { [k: string]: any }, editable: boolean) => {
        const res = Object.keys(dataSet).map((key: string, index: number) => {
            if (key === 'id' || (exclude && key in exclude))
                return

            let type: any = typeof dataSet[key]
            if (type === 'object' && Array.isArray(dataSet[key])) {
                type = 'array'
            }
            const ObjectType = FormTypes[type]
            return ObjectType ? <ObjectType key={key} _key={key} label={labels[key] ? labels[key] : key} value={dataSet[key]} editable={editable} onChange={onChange} /> : null
        })

        return res
    }

    return (
        <div style={DynamicFormStyles.container}>
            {RenderFields(dataState, editable)}
        </div>
    );
};


export default DynamicForm;

export const DynamicFormStyles = {
    container: {

    },
    input: {
        width: '100%',
    }
}
