import * as React from 'react';
import { RemoteActions } from '../../remote/remote-actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux'
import ItemsList from '../../components/generic/items-list';
import { BookingRules, Slot, CheckForm } from '../../models/models';
import DynamicForm from '../../components/generic/dynamic-form/dynamic-form';
import SwitchList from '../../components/generic/switch-list';

interface BookingRulesScreenProps {
    dispatch: Dispatch;
    bookingRules: BookingRules[];
    slots: Slot[];
    refesh: string;
    checkForm: CheckForm[];
}


const BookingRulesScreen: React.FC<any> = ({ dispatch, bookingRules, refesh, slots, checkForm }: BookingRulesScreenProps) => {

    React.useEffect(() => {
        RemoteActions.getCheckForm(dispatch)
        RemoteActions.getBookingRules(dispatch)
        RemoteActions.getSlots(dispatch)
    }, [])

    const changeForm = ({ item, editing, onChange }: { item: BookingRules, editing: boolean, onChange: any }) => {

        return (
            <div key={`add_item_form`}>
                <DynamicForm
                    key={'DynamicForm'}
                    data={item}
                    labels={{
                        name: 'Rules name',
                        beforeWeekend: 'Week slots',
                        ofWeekend: 'Weekend slots',
                        borrow_previous: 'From previous month',
                        borrow_next: 'From next month',
                        max_months_future_booking: 'Max months booking',
                        cancelation_hours: 'Cancelation hours',
                    }}
                    editable={editing}
                    onDataChange={onChange}
                />
                <div style={{ borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid', padding: 10, margin: 10 }}>
                    <div style={{ fontWeight: 'bold', padding: 15 }}>Week slots</div>
                    <SwitchList
                        field={'beforeWeekend'}
                        leftListdata={slots}
                        rightListdata={item.beforeWeekend}
                        onSelectedListChange={onChange}
                        listTemplate={((item: Slot) => (
                            <div>
                                {`${item.name} ${item.range}`}
                            </div>
                        ))}
                    />
                </div>

                <div style={{ borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid', padding: 10, margin: 10 }}>
                    <div style={{ fontWeight: 'bold', padding: 15 }}>Weekend start slot</div>

                    <select id="combo" value={item.weekendStartSlot ? JSON.stringify(item.weekendStartSlot) : '{}'} onChange={(event) => {
                        onChange({ weekendStartSlot: JSON.parse(event.target.value) })
                    }}>
                        <option key={`weekendStartSlot`} value={JSON.stringify({})}>No defined</option>
                        {item.beforeWeekend.map((element: any, index: number) => {
                            return <option key={`weekendStartSlot${index}`} value={JSON.stringify(element)}>{element.name}</option>
                        })}
                    </select>
                </div>

                <div style={{ borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid', padding: 10, margin: 10 }}>
                    <div style={{ fontWeight: 'bold', padding: 15 }}>Weekend slots</div>
                    <SwitchList
                        field={'ofWeekend'}
                        leftListdata={slots}
                        rightListdata={item.ofWeekend}
                        onSelectedListChange={onChange}
                        listTemplate={((item: Slot) => (
                            <div>
                                {`${item.name} ${item.range}`}
                            </div>
                        ))}
                    />
                </div>


                <div style={{ borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid', padding: 10, margin: 10 }}>
                    <div style={{ fontWeight: 'bold', padding: 15 }}>Week start slot</div>

                    <select id="comboweek" value={item.weekStartSlot ? JSON.stringify(item.weekStartSlot) : '{}'} onChange={(event) => {
                        onChange({ weekStartSlot: JSON.parse(event.target.value) })
                    }}>
                        <option key={`weekStartSlot`} value={JSON.stringify({})}>No defined</option>
                        {item.beforeWeekend.map((element: any, index: number) => {
                            return <option key={`weekStartSlot${index}`} value={JSON.stringify(element)}>{element.name}</option>
                        })}
                    </select>
                </div>


                {/* Check in / out form definitions */}


                {checkForm && checkForm.length > 0 && <div style={{ borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid', padding: 10, margin: 10 }}>
                    <div style={{ fontWeight: 'bold', padding: 15 }}>Check-in</div>

                    <select id="checkIncombo" onChange={(event) => {
                        onChange({ checkIn: JSON.parse(event.target.value) })
                    }}>

                        {checkForm.map((element: CheckForm, index: number) => {
                            const isSelected = item.checkIn?.id === element.id
                            return isSelected
                                ?
                                <option key={`checkIncombo${index}`} selected value={JSON.stringify(element)}>{element.title}</option>
                                :
                                <option key={`checkIncombo${index}`} value={JSON.stringify(element)}>{element.title}</option>
                        })}
                    </select>
                </div>}



                {checkForm && checkForm.length > 0 && <div style={{ borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid', padding: 10, margin: 10 }}>
                    <div style={{ fontWeight: 'bold', padding: 15 }}>Check-out</div>

                    <select id="checkOutcombo2" onChange={(event) => {
                        onChange({ checkOut: JSON.parse(event.target.value) })
                    }}>

                        {checkForm.map((element: CheckForm, index: number) => {
                            const isSelected = item.checkOut?.id === element.id
                            return isSelected
                                ?
                                <option key={`checkOutcombo2${index}`} selected value={JSON.stringify(element)}>{element.title}</option>
                                :
                                <option key={`checkOutcombo2${index}`} value={JSON.stringify(element)}>{element.title}</option>
                        })}

                    </select>

                    {/* {!item.checkOut && checkForm.length ? onChange({ checkOut: checkForm[0] }) : null} */}
                </div>}


                {/* Check list definitions */}

                {checkForm && checkForm.length > 0 && <div style={{ borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid', padding: 10, margin: 10 }}>
                    <div style={{ fontWeight: 'bold', padding: 15 }}>Before start list</div>

                    <select id="checkIncombo3" onChange={(event) => {
                        onChange({ beforeStart: JSON.parse(event.target.value) })
                    }}>
                        {checkForm.map((element: CheckForm, index: number) => {
                            const isSelected = item.beforeStart?.id === element.id
                            return isSelected
                                ?
                                <option key={`checkIncombo3${index}`} selected value={JSON.stringify(element)}>{element.title}</option>
                                :
                                <option key={`checkIncombo3${index}`} value={JSON.stringify(element)}>{element.title}</option>
                        })}
                    </select>
                    {/* {!item.beforeStart && checkForm.length ? onChange({ beforeStart: checkForm[0] }) : null} */}
                </div>}

                {checkForm && checkForm.length > 0 && <div style={{ borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid', padding: 10, margin: 10 }}>
                    <div style={{ fontWeight: 'bold', padding: 15 }}>After end list</div>

                    <select id="checkOutcombo4" onChange={(event) => {
                        onChange({ afterEnd: JSON.parse(event.target.value) })
                    }}>
                        {checkForm.map((element: CheckForm, index: number) => {
                            const isSelected = item.afterEnd?.id === element.id
                            return isSelected
                                ?
                                <option key={`checkOutcombo4${index}`} selected value={JSON.stringify(element)}>{element.title}</option>
                                :
                                <option key={`checkOutcombo4${index}`} value={JSON.stringify(element)}>{element.title}</option>
                        })}
                    </select>

                    {/* {!item.afterEnd && checkForm.length ? onChange({ afterEnd: checkForm[0] }) : null} */}
                </div>}



            </div>
        )
    }

    const InputForm = () => (
        <div className="container-fluid">
            <h1>Booking Rules</h1>
            <div style={{ opacity: 0.0 }}>{refesh}</div>
            <ItemsList
                style={{}}
                onSelectItem={() => { }}
                onEditItem={(bookingRules: BookingRules) => {
                    bookingRules.weekendStartSlot = bookingRules.weekendStartSlot || {}
                    bookingRules.weekStartSlot = bookingRules.weekStartSlot || {}
                    return RemoteActions.setBookingRules(bookingRules, dispatch)
                }}
                dataTemplate={{
                    name: '',
                    borrow_previous: 0,
                    borrow_next: 0,
                    max_months_future_booking: 0,
                    cancelation_hours: 0,
                    ofWeekend: [],
                    beforeWeekend: [],
                }}
                onDeleteItem={(bookingRule: BookingRules) => {
                    return RemoteActions.deleteBookingRules(bookingRule.id, dispatch)
                }}
                listTemplate={(bookingRules: BookingRules) => {
                    return (
                        <div style={{ display: 'grid' }}>

                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Name</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${bookingRules.name}`}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Borrow Previous</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${bookingRules.borrow_previous}`}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Borrow Next</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${bookingRules.borrow_next}`}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Max Months Of Future Booking</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${bookingRules.max_months_future_booking}`}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Cancelation Hours</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${bookingRules.cancelation_hours}`}</span>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Week Slots</span>
                                </div>
                                {bookingRules.beforeWeekend.map((slot: Slot) => (
                                    <div className="col-md-1">
                                        <span className="badge badge-primary">{`${slot.name}`}</span>
                                    </div>
                                )
                                )}
                            </div>

                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Weekend Slots</span>
                                </div>
                                {bookingRules.ofWeekend.map((slot: Slot) => (
                                    <div className="col-md-1">
                                        <span className="badge badge-primary">{`${slot.name}`}</span>
                                    </div>
                                )
                                )}
                            </div>

                        </div>
                    )
                }}
                newItemFormTemplate={changeForm}
                list={bookingRules} />
        </div>
    )


    return (
        <InputForm />
    );
};


const mapStateToProps = (state: any) => {

    const reducer = {
        bookingRules: state.bookingRules.bookingRules,
        checkForm: state.checkForm.checkForm,
        slots: state.slots.slots,
        refesh: `${Date.now()}`,
    }

    return reducer
}

export default connect(mapStateToProps)(BookingRulesScreen);

const styles = ({
    container: {}
});
