import React, { } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/less/font-awesome.less'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import rootReducer from './redux/store/store'
import MainAppController from "./MainAppController";
import Realtime from "./components/realtime/realtime";

const store = createStore(rootReducer)

const App: React.FC = () => {

  return (
    <Provider store={store}>
      <div id="main-container" style={{backgroundColor: '#F5F5F5'}}>
        <Realtime />
        <MainAppController />
      </div>
    </Provider>
  );
};





export default (App);


