

import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { User } from '../../models/models';

interface PrivateRouteProps {
  user?: User;
  children: any;
  rest?: any;
  path: string;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  return (
    <Route
      {...props.rest}
      render={({ location }) =>
        props.user ?
          (
            props.children
          )
          : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}

const mapStateToProps = (state: any) => {

  const user = (state.login.user && Object.keys(state.login.user).length) ? state.login.user : null
  return {
    user
  }
}

export default connect(mapStateToProps)(PrivateRoute);


