export enum ACTIONS_USERS {
    ADD_USERS_SUCCESS = 'ADD_USERS_SUCCESS',
    ADD_USERS_FAILURE = 'ADD_USERS_FAILURE',

    GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
    GET_USERS_FAILURE = 'GET_USERS_FAILURE',

    DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS',
    DELETE_USERS_FAILURE = 'DELETE_USERS_FAILURE',
}

export const ADD_USERS_SUCCESS = (data:any) => ({
    type: ACTIONS_USERS.ADD_USERS_SUCCESS,
    data
})

export const ADD_USERS_FAILURE = (e:any) => ({
    type: ACTIONS_USERS.ADD_USERS_FAILURE,
    error: e
})

export const DELETE_USERS_SUCCESS = (data:any) => ({
    type: ACTIONS_USERS.DELETE_USERS_SUCCESS,
    data
})

export const DELETE_USERS_FAILURE = (e:any) => ({
    type: ACTIONS_USERS.DELETE_USERS_FAILURE,
    error: e
})


export const GET_USERS_SUCCESS = (data:any) => ({
    type: ACTIONS_USERS.GET_USERS_SUCCESS,
    data
})

export const GET_USERS_FAILURE = (e:any) => ({
    type: ACTIONS_USERS.GET_USERS_FAILURE,
    error: e
})