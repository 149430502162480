import { User, Vessel, Membership, Slot, Holiday, Reservation, BookingRules, QuestionAnswer, WaitingList } from "../../../models/models"
import { Dispatch } from 'redux';
import { colorFree, weekendColor } from "../../../utils/calendar-utils";

export const NORMAL = 'NORMAL'
export const FREE = 'FREE'
export const WORKING_DAY = 'WORKING_DAY'
export const WEEK_END = 'WEEK_END'
export const AVAILABLE = 'AVAILABLE'
export const RESERVED = 'RESERVED'
export const WAITING_LIST = 'WAITING_LIST'

export interface SlotItemProps {
    user: User;
    vessel: Vessel;
    membership: Membership;
    slot: Slot;
    day: number;
    month: number;
    year: number;
    holidays?: Holiday[],
    reservations?: Reservation[],
    bookingRules?: BookingRules,
    date?: Date,
    answers: QuestionAnswer[],
    waitingList: WaitingList[],
    canRemoveSplit?: boolean,
    dispatch: Dispatch;
}

export const SLOTS_CONFIG: any = {
    [NORMAL]: {
        [WORKING_DAY]: {
            [AVAILABLE]: 'white',
            [RESERVED]: 'rgba(0,255,255,0.2)',
            [WAITING_LIST]: 'rgba(0,102,255,0.2)',
        },
        [WEEK_END]: {
            [AVAILABLE]: weekendColor,
            [RESERVED]: 'rgba(0,102,255,0.2)',
            [WAITING_LIST]: 'rgba(0,102,255,0.2)',
        }
    },
    [FREE]: {
        [WORKING_DAY]: {
            [AVAILABLE]: 'white',
            [RESERVED]: 'rgba(0,102,255,0.2)',
            [WAITING_LIST]: 'rgba(0,102,255,0.2)',
        },
        [WEEK_END]: {
            [AVAILABLE]: weekendColor,
            [RESERVED]: 'rgba(51,204,0,0.8)',
            [WAITING_LIST]: 'rgba(0,102,255,0.2)',
        }
    },
    [AVAILABLE]: {
        [WORKING_DAY]: {
            [AVAILABLE]: '#66ccff',
            [RESERVED]: 'rgba(0,255,255,0.2)',
        },
        [WEEK_END]: {
            [AVAILABLE]: '#0099e6',
            [RESERVED]: 'rgba(0,102,255,0.2)',
        }
    },
}

export const getStyle = (_slotType: string, _dayType: string, _available: string): any => {
    const backgroundColor: string = SLOTS_CONFIG[_slotType][_dayType][_available]


    const freeStyles = _slotType === FREE && _available !== RESERVED ? {borderLeftWidth: 7, borderLeftColor: colorFree, borderLeftStyle: 'solid'} : {}
    return { float: 'left', backgroundColor, color: 'black', minHeight: 110, width: '100%', margin: 2, ...freeStyles }
}


export interface SlotTypeInterface {
    getColor: string;
    getSlotInterface: any;
}