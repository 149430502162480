import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { User } from '../../models/models';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import RemoteActions from '../../remote/remote-actions';

interface ProfileProps {
    user: User;
    loggedIn: boolean;
    dispatch: Dispatch;
}

const Profile = (props: ProfileProps) => {
    const [user, setUser] = React.useState<any>({ user: props.user, newPassword: '', oldPassword: '', confirmPassword: '' })
    const [loading, setLoading] = React.useState<Boolean>(false)
    const [error, setError] = React.useState<string | null>(null)
    const [updated, setUpdated] = React.useState<string | null>(null)


    const handleChange = (key: 'oldPassword' | 'newPassword' | 'confirmPassword', value: string) => {
        let state = user
        state[key] = value
        const newState = { ...user, ...state }
        setUser(newState)
    }

    React.useEffect(() => {
        if (user.newPassword !== user.confirmPassword) {
            setError('Passwords not match')
        } else {
            setError(null)
        }
    }, [user.newPassword, user.confirmPassword])


    return (
        <Container style={styles.container}>

            <Row>
                <Col>First Name</Col>
                <Col>{user.user.firstName}</Col>
            </Row>

            <Row>
                <Col>Last Name</Col>
                <Col>{user.user.lastName}</Col>
            </Row>

            <Row>
                <Col>Email</Col>
                <Col>{user.user.email}</Col>
            </Row>

            <Row>
                <Col>User Role</Col>
                <Col>{user.user.role}</Col>
            </Row>


            <Row className="form-group" style={{ marginTop: 60 }}>
                <Col>
                    <label>
                        Old Password
				</label>
                </Col>

                <Col>
                    <input type="password" className="form-control" id="oldpassword" onChange={(event: any) => handleChange('oldPassword', event.target.value)} />
                </Col>
            </Row>


            <Row className="form-group" >
                <Col>
                    <label>
                        Password
				</label>
                </Col>

                <Col>
                    <input type="password" className="form-control" id="newpassword" onChange={(event: any) => handleChange('newPassword', event.target.value)} />
                </Col>
            </Row>

            <Row className="form-group">
                <Col>
                    <label>
                        Repeat Password
			    </label>
                </Col>

                <Col>
                    <input type="password" className="form-control" id="confirmpassword" onChange={(event: any) => handleChange('confirmPassword', event.target.value)} />
                </Col>
            </Row>


            <Row className="text-center">
                <Col>{updated && <span className="badge badge-success">{updated}</span>}{error && <span className="badge badge-danger">{error}</span>}</Col>
                <Col>
                    <button disabled={user.confirmPassword === '' || error !== null} type="submit" className="btn btn-primary" style={{ width: '100%' }} onClick={async () => {
                        try {
                            setUpdated('')
                            setError('')
                            setLoading(true)
                            await RemoteActions.resetPassword(user, props.dispatch)
                            setUpdated('Your password was updated')
                        } catch (error) {
                            setLoading(false)
                            setError(error.toString())
                        }
                    }}>
                        Update Password
				</button>
                </Col>
            </Row>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 35 }}>

                <a className="btn btn-primary" href="/login">logout</a>
            </div>


        </Container>
    );
};

const mapStateToProps = (state: any) => {
    return {
        user: state.login.user,
        loggedIn: state.login.loggedIn,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

const styles = {
    container: {}
};
