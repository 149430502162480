import * as React from 'react';
import { Slot, Reservation, User } from '../../../../../models/models';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { SlotItemProps, getStyle, AVAILABLE, FREE } from '../../slot-type-interface';
import RemoteActions from '../../../../../remote/remote-actions';
import { months, themeColor } from '../../../../../utils/calendar-utils';
import { avaliableCredits, isFreeSlot, isValidMaxMonthsOfReservation } from '../../../../../utils/reservation-utils';
import SplitSlots from '../../../../split-slots/split-slots';
import { RenderSlot, SplitCircle } from '../../../slot-item';
import { lang, selctedLang } from '../../../../../lang';
import SplitSlotsAdmin from '../../../../split-slots/split-slots-admin';


interface SlotReservedProps extends SlotItemProps {
    slotType: string;
    dayType: string;
    date: Date;
    canRemoveSplit: boolean;
}

const SlotAvailableAdmin = ({ dispatch, date, user, vessel, membership, reservations, day, month, year, slot, slotType, dayType, holidays, canRemoveSplit }: SlotReservedProps) => {
    const [show, setShow] = React.useState(false);
    const [freeSlot, SetFreeSlot] = React.useState<boolean>(isFreeSlot(date, slot, year, month, day))
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const initialUser = () => {
        if (membership.users.some((u) => u.id === user.id)) {
            return user
        } else {
            return membership.users[0]
        }
    }

    let selectedUser = initialUser()

    const makeReservation = () => {
        const credits = avaliableCredits(reservations!, year, month, vessel.bookingRules, membership, holidays!)
        const currentDate = `${year}-${month}`
        const index = credits.findIndex((month: any) => month.date === currentDate)

        let borrow = ''
        let isValidReservation = false

        if (!freeSlot && credits[index][dayType] <= 0) {
            credits.forEach((val: any) => {
                if (val[dayType] > 0 && !isValidReservation) {
                    borrow = val.date
                    isValidReservation = true
                }
            })
        } else {
            isValidReservation = true
        }

        if (isValidReservation) {
            const reservation: Reservation = {
                membership,
                user: selectedUser,
                vessel,
                slot: slot.range,
                date: `${year}-${month}-${day}`,
                skippers: [],
                borrow,
                free: freeSlot

            }
            if (slot.original) {
                reservation.original = slot.original
            }

            RemoteActions.setReservation(reservation, dispatch)
        }
    }



    const SlotTemplate = ({ slot }: { slot: Slot }) => (
        <Button onClick={handleShow} key={`${slot.id}-${day}-${month}-${year}`} style={{ ...getStyle(slotType, dayType, AVAILABLE), justifyContent: 'center', alignItems: 'center' }} className="event-desc list-group-item list-group-item-action list-group-item-light">
            {slot!.original && <div style={{ position: 'absolute', right: 5, top: 5 }}><SplitCircle key='' width={10} backgroundColor={themeColor} /></div>}
            <RenderSlot slot={slot} />
        </Button>
    )

    const ModalComponent = () => (
        <Modal show={show} onHide={handleClose} animation={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{lang[selctedLang].reservation}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        {lang[selctedLang].user}
                    </Col>
                    <Col>

                        <select id="combo" onChange={(event) => {
                            const user: User = JSON.parse(event.target.value)
                            selectedUser = user
                        }}>
                            {membership.users.map((element: User, index: number) => {
                                const isSelected = selectedUser.id === element.id
                                return isSelected
                                    ?
                                    <option key={`${index}`} selected value={JSON.stringify(element)}>{element.lastName}, {element.firstName}</option>
                                    :
                                    <option key={`${index}`} value={JSON.stringify(element)}>{element.lastName}, {element.firstName}</option>
                            })}
                        </select>
                    </Col>
                </Row>
                <p>{lang[selctedLang].reservationConfirmation(slot, vessel, day, month, year, membership)}</p>

                {!slot.original &&
                    <SplitSlotsAdmin
                        slotColor={getStyle(slotType, dayType, AVAILABLE).backgroundColor}
                        slot={slot}
                        onCancel={() => { }}
                        onSplit={(split: any) => {
                            RemoteActions.setSplitSlot({
                                ...split,
                                user,
                                vessel,
                                date: `${year}-${month}-${day}`,
                            }, dispatch)
                        }}
                    />}

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                    <input type="checkbox" checked={freeSlot} onChange={() => SetFreeSlot(!freeSlot)} />
                    <div style={{ marginLeft: 10 }}>{lang[selctedLang].free}</div>
                </div>

                {canRemoveSplit && (
                    <Row style={{ marginTop: 30 }}>
                        <Col>
                            <Button variant="primary" onClick={() => {
                                RemoteActions.deleteSplitSlot(slot.id, dispatch)
                            }}>
                                {lang[selctedLang].removeSplitSlot}
                            </Button>
                        </Col>
                    </Row>
                )}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {lang[selctedLang].cancel}
                </Button>
                <Button variant="primary" onClick={makeReservation}>
                    {lang[selctedLang].save}
                </Button>
            </Modal.Footer>
        </Modal>
    )

    return (
        <div>
            <SlotTemplate slot={slot} />
            <ModalComponent />
        </div>
    )

}

export default (SlotAvailableAdmin)