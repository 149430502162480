import * as React from 'react';
import { lang, selctedLang } from '../../lang';
import RemoteActions from '../../remote/remote-actions';
import { isValidToRemoveReservation } from '../../utils/reservation-utils';
import SelectionModal from './Modal';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import ButtonWithFeedback from '../generic/buttonWithFeedback';

interface RemoveModalProps {
    show: boolean;
    slotData: any;
    date: Date;
    handleClose?: () => void;
    dispatch: Dispatch;
}

const RemoveModal = ({ dispatch, show, slotData, date, handleClose }: RemoveModalProps) => {

    const Render = () => {
        let isvalidRemove = false
        if (slotData.reservation) {
            isvalidRemove = isValidToRemoveReservation(date, slotData.reservation!, slotData.vessel)
        }

        return (
            <div style={{}}>

                <SelectionModal handleClose={handleClose} show={show} Content={() => {
                    return (
                        <div style={{ display: 'flex', marginBottom: 8, flexDirection: 'column', padding: 5, paddingTop: 20 }}>

                            <div style={{ textAlign: 'center', paddingBottom: 20 }}>
                                {slotData && isvalidRemove || slotData.isFree ? lang[selctedLang].removeReservation : lang[selctedLang].removeExpiredReservation}
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 5, }}>
                                <div className='btHorizontal btCANCEL' onClick={handleClose}>
                                    {lang[selctedLang].no}
                                </div>

                                <ButtonWithFeedback
                                    onPress={async () => {
                                        return new Promise<void>(async (resolve, reject) => {
                                            try {
                                                if (slotData.canRemoveSplit) {
                                                    await RemoteActions.deleteSplitSlot(slotData.isSplit[0].id, dispatch)
                                                }

                                                if (isvalidRemove) {
                                                    await RemoteActions.deleteReservation(slotData.reservation!.id, dispatch)
                                                } else {
                                                    const _reservation = {
                                                        ...slotData.reservation!,
                                                        disable: true
                                                    }
                                                    await RemoteActions.setReservation(_reservation, dispatch)
                                                }
                                                handleClose!()
                                                resolve()
                                            } catch (error) {
                                                reject()
                                            }
                                        })
                                    }}
                                    text={lang[selctedLang].yes}
                                    className='btHorizontal btREMOVE'
                                />
                            </div>
                        </div>
                    )
                }} />
            </div>
        )
    };


    return Render()
};
/**
 * controlles login state
 * @param state 
 */
const mapStateToProps = (state: any) => {
    return {
        reservations: state.reservations.reservations,
        waitingList: state.waitingList.waitingList,
        holidays: state.holiday.holiday,
        date: state.reservations.date,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(RemoveModal);

