export enum ACTIONS_AUTH {
    LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE = 'USERS_LOGIN_FAILURE',
    LOGOUT = 'USERS_LOGOUT',

    SIGNUP_SUCCESS = 'USERS_SIGNUP_SUCCESS',
    SIGNUP_FAILURE = 'USERS_SIGNUP_FAILURE',

    REFRESH_USER_MEMBERSHIPS_SUCCESS = 'REFRESH_USER_MEMBERSHIPS_SUCCESS',
    REFRESH_USER_MEMBERSHIPS_FAILURE = 'REFRESH_USER_MEMBERSHIPS_FAILURE',

    DISABLE_SITE = 'DISABLE_SITE',
    DISABLE_SITE_FAILURE = 'DISABLE_SITE_FAILURE',
}

export const LOGIN_SUCCESS = (data: any) => ({
    type: ACTIONS_AUTH.LOGIN_SUCCESS,
    data
})

export const LOGIN_FAILURE = (e: any) => ({
    type: ACTIONS_AUTH.LOGIN_FAILURE,
    error: e
})

export const LOGOUT = () => ({
    type: ACTIONS_AUTH.LOGOUT
})

export const SIGNUP_SUCCESS = (data: any) => ({
    type: ACTIONS_AUTH.SIGNUP_SUCCESS,
    data
})

export const SIGNUP_FAILURE = (e: any) => ({
    type: ACTIONS_AUTH.SIGNUP_FAILURE,
    error: e
})

export const REFRESH_USER_MEMBERSHIPS_SUCCESS = (data: any) => ({
    type: ACTIONS_AUTH.REFRESH_USER_MEMBERSHIPS_SUCCESS,
    data
})

export const REFRESH_USER_MEMBERSHIPS_FAILURE = (e: any) => ({
    type: ACTIONS_AUTH.REFRESH_USER_MEMBERSHIPS_FAILURE,
    error: e
})

export const DISABLE_SITE = (data: any) => ({
    type: ACTIONS_AUTH.DISABLE_SITE,
    data
})

export const DISABLE_SITE_FAILURE = (e: any) => ({
    type: ACTIONS_AUTH.DISABLE_SITE_FAILURE,
    error: e
})
