import * as React from 'react';
import { lang, selctedLang } from '../../lang';
import RemoteActions from '../../remote/remote-actions';
import { isValidToRemoveReservation } from '../../utils/reservation-utils';
import SelectionModal from './Modal';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Membership, User, WaitingList } from '../../models/models';
import { filterUser } from '../../utils/utils';
import ButtonWithFeedback from '../generic/buttonWithFeedback';

interface WaithingModalProps {
    show: boolean;
    user: User;
    membership: Membership;
    slotData: any;
    handleClose?: () => void;
    dispatch: Dispatch;
}

const WaithingModal = ({ dispatch, show, slotData, user, membership, handleClose }: WaithingModalProps) => {

    const Render = () => {
        const dateParts = slotData.date.split('-')

        const year = dateParts[0]
        const month = dateParts[1]
        const day = dateParts[2]

        return (
            <div style={{}}>

                <SelectionModal handleClose={handleClose} show={show} Content={() => {
                    return (
                        <div style={{ display: 'flex', marginBottom: 8, flexDirection: 'column', padding: 5, paddingTop: 20 }}>

                            <div style={{ textAlign: 'center', paddingBottom: 20 }}>
                                {lang[selctedLang].addWaitingListBody(slotData.slot, slotData.vessel, day, month, year, membership)}
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 5, }}>
                                <div className='btHorizontal btCANCEL' onClick={handleClose}>
                                    {lang[selctedLang].no}
                                </div>

                                <ButtonWithFeedback
                                    onPress={async () => {
                                        return new Promise(async (resolve, reject) => {
                                            try {
                                                const waitingList: WaitingList = {
                                                    user: filterUser(user),
                                                    membership: membership!,
                                                    slot: slotData.reservation!.slot,
                                                    date: slotData.reservation!.date,
                                                    vessel: slotData.reservation!.vessel,
                                                }
                                                await RemoteActions.setWaitingList(waitingList, dispatch)
                                                handleClose!()
                                            } catch (error) {
                                                reject()
                                            }
                                        })
                                    }}
                                    text={lang[selctedLang].yes}
                                    className='btHorizontal btOK'
                                />
                            </div>
                        </div>
                    )
                }} />
            </div>
        )
    };


    return Render()
};
/**
 * controlles login state
 * @param state 
 */
const mapStateToProps = (state: any) => {
    return {
        user: state.login.user,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(WaithingModal);

