import axios from 'axios'

export enum REMOTE_SERVICES {
    HOST = '',
    PORT = ''
}

// export enum REMOTE_SERVICES {
//     HOST = '',
//     PORT = ''
// }

export const getImage = (url: string) => `${REMOTE_SERVICES.HOST}${REMOTE_SERVICES.PORT}/${url}`

export enum API_PATH {
    //user-management
    LOGIN = '/user-management/login',
    PASSWORD_RECOVERY = '/user-management/passwordRecovery',
    RESET_PASSWORD = '/user-management/passwordRecoveryReset',
    SIGNUP = '/user-management/signup',
    RESET_PASS = '/user-management/resetPassword',
    REFRESH_USER_MEMBERSHIPS = '/user-management/refreshUserMemberships',
    USERS = '/user-management/users',
    MESSAGE = '/user-management/message',
    IMAGE = '/user-management/image',
    DL = '/user-management/dl',
    //membership-management
    SLOT = '/membership-management/slot',
    MEMBERSHIP = '/membership-management/membership',
    MEMBERSHIP_TYPE = '/membership-management/membershipType',
    BOOKING_RULES = '/vessel-management/bookingRules',
    BOOKING_RULES_CHECK_FORM = '/vessel-management/bookingRulesCheckForms',
    SKIPPER_ACCOUNT = '/membership-management/skipperAccount',
    SKIPPER_AVAILABILITY = '/membership-management/skipperAvailability',
    //vessel-management
    VESSEL = '/vessel-management/vessel',
    HOLIDAYS = '/vessel-management/holidays',
    //reservations-management
    RESERVATION = '/reservations-management/reservation',
    SPLIT_SLOT = '/reservations-management/splitSlot',
    SERVER_DATE = '/reservations-management/date',
    WAITING_LIST = '/reservations-management/waitingList',
    SKIPPER_RESERVATION = '/vessel-management/skipperReservation',
    // check form 
    QUESTION = '/reservations-management/question',
    QUESTION_GROUP = '/reservations-management/questionGroup',
    CHECK_FORM = '/reservations-management/checkForm',
    QUESTION_ANSWER = "/reservations-management/answer",
    QUESTION_ANSWER_FOR_RESERVATION = "/reservations-management/answersForReservation",
    SEND_FORM = '/reservations-management/sendForm',
    DISABLE_SITE = '/user-management/disableSite',
}

export default class API {

    /**
     * abstract post
     */
    public static makePost = async (path: string, data: any, extraHeaders: any) => {
        var headers: any = {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'accept': 'application/json'
        }
        headers = { ...headers, ...extraHeaders }
        API.addToken(headers)
        return axios.post(
            `${REMOTE_SERVICES.HOST}${REMOTE_SERVICES.PORT}${path}`,
            JSON.stringify(data),
            { headers: headers }
        )
    }

    /**
     * abstract get
     */
    public static makeGet = async (path: string, params?: any) => {
        var headers: any = {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        }
        let _path = `${REMOTE_SERVICES.HOST}${REMOTE_SERVICES.PORT}${path}`
        if (params) {
            _path = _path + `/${params}`
        }
        API.addToken(headers)
        return axios.get(
            _path,
            { headers: headers }
        )
    }

    /**
     * abstract delete
     */
    public static makeDelete = async (path: string, id: any) => {
        var headers: any = {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        }
        API.addToken(headers)
        return axios.delete(
            `${REMOTE_SERVICES.HOST}${REMOTE_SERVICES.PORT}${path}/${id}`,
            { headers: headers }
        )
    }

    /**
     * adds user token to the request
     * @param headers 
     */
    private static addToken(headers: any) {
        try {
            const token = JSON.parse(localStorage.getItem('user') as string).token
            if (token) {
                headers['usertoken'] = token
            }
        } catch (e) {
            console.log(e.toString())
        }
    }


}