import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareLeft, faCaretSquareRight } from '@fortawesome/free-solid-svg-icons';
import { Vessel, BookingRules, Reservation, Slot, Membership, User, Holiday, SplitSlot } from '../models/models';
import { months, weekDay, daysInMonth, isHoliday, getWeekday, getPreviousMonth, getNextMonth } from '../utils/calendar-utils';
import RemoteActions from '../remote/remote-actions';
import { sortSlots } from '../utils/utils';

import { Table, Container, Row, Col } from 'react-bootstrap';
import AvailableCredits, { CalendarScrollState } from '../components/calendar/available-credits';
import membershipType from './membership/membership-type';
import holiday from '../redux/reduceres/holidays';
import Legend from '../components/calendar/legend';
import { lang, selctedLang } from '../lang';
import Clock from '../components/calendar/Clock';
import { clearTimeout, setTimeout } from 'timers';
import { clearTimer, setTimer } from '../utils/TimeoutUtils';




interface CalendarProps {
    user: User;
    membership: Membership;
    reservations: Reservation[];
    dispatch: Dispatch;
    refesh: string;
    date: Date;
    holidays: Holiday[];
    splitSlots: SplitSlot[];
    getSlots: (day: number, month: number, year: number, bookingRules: BookingRules, vessel: Vessel, reservations: Reservation[]) => any;
    siteDisable: boolean;
}



const SELECTED_VESSEL = 'SELECTED_VESSEL'

const Calendar = ({ user, membership, reservations, dispatch, refesh, getSlots, date, holidays, splitSlots, siteDisable }: CalendarProps) => {

    const getSelectedVessel = () => {
        const selectedVessel = localStorage.getItem(SELECTED_VESSEL)

        if (selectedVessel) {
            const stored = JSON.parse(selectedVessel!)
            const vessel = membership.vessels!.find((_vessel: Vessel) => _vessel.id === stored.id)
            if (vessel)
                return vessel!
        }

        if (membership.vessels)
            return membership.vessels[0]
        else
            return undefined
    }

    const [viewDate, setViewDate] = React.useState({ month: date.getMonth(), year: date.getFullYear() })
    const [vessel, setVessel] = React.useState<Vessel | undefined>(getSelectedVessel())
    const [monthName, setMonthName] = React.useState(months[date.getMonth()])
    const [calendarState, setCalendarState] = React.useState<CalendarScrollState>()


    React.useEffect(() => {
        setViewDate({ month: date.getMonth(), year: date.getFullYear() })
        setMonthName(months[date.getMonth()])
    }, [date])

    React.useEffect(() => {

        const nowDate = new Date(+viewDate.year, +viewDate.month)
        if (membership) {
            if (membership.creationDate) {
                const startDateParts = membership.creationDate.split('-')
                const startDate = new Date(+startDateParts[0], +startDateParts[1], +startDateParts[2])
                if (nowDate.getTime() < startDate.getTime()) {
                    setViewDate({ month: +startDateParts[1], year: +startDateParts[0] })
                    setMonthName(months[+startDateParts[1] - 1])
                }
            }

            if (membership.endDate) {
                const endDateParts = membership.endDate.split('-')
                const endDate = new Date(+endDateParts[0], +endDateParts[1], +endDateParts[2])
                if (nowDate.getTime() > endDate.getTime()) {
                    setViewDate({ month: +endDateParts[1], year: +endDateParts[0] })
                    setMonthName(months[+endDateParts[1] - 1])
                }
            }

        }
    }, [membership])

    React.useEffect(() => {
        RemoteActions.getServerDate(dispatch)
        //RemoteActions.getReservation(dispatch)
        RemoteActions.getBookingRules(dispatch)
    }, [])

    React.useEffect(() => {
        const _vessel = getSelectedVessel()
        setVessel(_vessel)
    }, [membership])

    const [loading, setLoading] = React.useState(false)
    React.useEffect(() => {
        if (vessel && viewDate) {
            clearTimer()
            setLoading(true)
            setTimer(async () => {
                await RemoteActions.getReservationWithStartMonth(dispatch, `${viewDate.year}-${viewDate.month}`, `${vessel.bookingRules.id}`)
                setLoading(false)
            }, 1000)
        }
    }, [vessel, viewDate])

    const DrawCalendarHeader = () => {
        const CHeader = weekDay.map((day: string) => {
            return (
                <th key={`${day}`} className="text-center" style={{ width: '100%' }}>
                    {day}
                </th>
            )
        })

        return (
            <tr className={'card-header py-3 d-flex flex-row align-items-center justify-content-between calendarHeader'} style={{ backgroundColor: '#343a40' }}>
                {CHeader}
            </tr>
        )
    }

    const ShowCalendar = ({ month, year, bookingRules }: { month: number, year: number, bookingRules: BookingRules }) => {

        let firstDay = (new Date(year, month)).getDay();
        firstDay = firstDay === 0 ? 6 : firstDay - 1
        const _document = []

        let _date = 1;
        for (let i = 0; i < 6; i++) {
            let row = [];
            for (let j = 0; j < 7; j++) {
                if (i === 0 && j < firstDay) {
                    const cell = <td className="day" key={`${i}-${j}`}></td>
                    row.push(cell)
                }
                else if (_date > daysInMonth(month + 1, year)) {
                    const cell = <td className="day" key={`${i}-${j}`}></td>
                    //row.push(cell)
                    break;
                }
                else {
                    const holiday = isHoliday(_date, month, year, holidays)
                    const isToday = (_date === date.getDate()) && (month === date.getMonth()) && (year === date.getFullYear())
                    let style = {}
                    if (holiday) {
                        style = { backgroundColor: 'rgba(223, 242, 192, 1)' }
                    }

                    if (isToday) {
                        style = { borderColor: 'white', ...style }
                    }

                    const cell = (
                        <td key={`${i}-${j}`} style={style}>
                            <div className="date" style={{ textAlign: 'right', fontWeight: 'bold' }}>{`${_date}`}</div>
                            {getSlots(_date, month, year, bookingRules, vessel!, reservations)}
                        </td>
                    )
                    row.push(cell)
                    _date++;
                }
            }
            _document.push((<tr className="days" key={`${i}`}>{row}</tr>))
        }
        return (
            <tbody className={'card-body'}>
                {_document}
            </tbody>
        )
    }


    return (
        <Container>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 10 }}>
                <div style={{ textAlign: 'start', marginRight: 10 }}>{lang[selctedLang].vessel}</div>
                <div>

                    <select id="combo" onChange={(event) => {
                        const _vessel: Vessel = JSON.parse(event.target.value)
                        setVessel(_vessel)
                        localStorage.setItem(SELECTED_VESSEL, event.target.value)
                    }}>
                        {membership.vessels.map((element: any, index: number) => {
                            const isSelected = vessel?.id === element.id
                            return isSelected ?
                                <option selected key={`${index}`} value={JSON.stringify(element)}>{element.name}</option>
                                :
                                <option key={`${index}`} value={JSON.stringify(element)}>{element.name}</option>
                        })}
                    </select>
                </div>
            </div>

            {reservations && date &&
                <AvailableCredits
                    reservations={reservations}
                    month={viewDate.month}
                    year={viewDate.year}
                    bookingRules={vessel!.bookingRules}
                    membership={membership}
                    holidays={holidays}
                    onMonthUpdate={(update: CalendarScrollState) => { setCalendarState(update) }}
                />}

            <Row>
                <Col>
                    {loading && <div className="spinner-border" role="status">
                        <span className="sr-only">{lang[selctedLang].loading}...</span>
                    </div>}
                    <div style={{ margin: 20, float: 'right', borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid' }}>
                        {(calendarState === CalendarScrollState.NONE || calendarState === CalendarScrollState.END_DATE) && <button style={{ float: 'left' }} onClick={() => {
                            const newData = getPreviousMonth(viewDate)
                            setMonthName(months[newData.month])
                            setViewDate(newData)
                        }}>
                            <FontAwesomeIcon icon={faCaretSquareLeft} />
                        </button>}
                        <div style={{ float: 'left', width: 80, textAlign: 'center' }}>{monthName}</div>
                        <div style={{ float: 'left', textAlign: 'center', marginRight: 15 }}>{viewDate.year}</div>
                        {(calendarState === CalendarScrollState.NONE || calendarState === CalendarScrollState.CREATION_DATE) && <button style={{ float: 'left' }} onClick={() => {
                            const newData = getNextMonth(viewDate)
                            setMonthName(months[newData.month])
                            setViewDate(newData)
                        }}>
                            <FontAwesomeIcon icon={faCaretSquareRight} />
                        </button>}
                    </div>
                </Col>
            </Row>


            <Row>
                <Col>
                    {!siteDisable && date && <Clock date={date} slots={[...vessel!.bookingRules.beforeWeekend, ...vessel!.bookingRules.ofWeekend, ...splitSlots.flatMap((split: SplitSlot) => {
                        const slot1: Slot = {
                            name: '',
                            range: split.firstSlotRange
                        }
                        const slot2: Slot = {
                            name: '',
                            range: split.secondSlotRange
                        }
                        return [slot1, slot2]
                    })]}
                        onClockRechTime={() => RemoteActions.getServerDate(dispatch)}
                    />}

                    <Table id="calendar" bordered style={{ borderRadius: 5 }} className="table-borderless table-dark header-fixed">

                        <DrawCalendarHeader />

                        <ShowCalendar month={viewDate.month} year={viewDate.year} bookingRules={vessel!.bookingRules} />

                    </Table>
                </Col>
            </Row>

            <div style={{ opacity: 0.0 }}>{refesh}</div>
        </Container>
    );
};


/**
 * controlles login state
 * @param state
 */
const mapStateToProps = (state: any) => {
    return {
        reservations: state.reservations.reservations,
        refesh: `${Date.now()}`,
        date: state.reservations.date,
        holidays: state.holiday.holiday,
        splitSlots: state.splitSlot.splitSlot,
        siteDisable: state.login.siteDisable
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);

