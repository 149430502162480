import * as React from 'react';
import { RemoteActions } from '../../remote/remote-actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux'
import ItemsList from '../../components/generic/items-list';
import { Vessel, Slot, BookingRules, Holiday, VesselBlock } from '../../models/models';
import DynamicForm from '../../components/generic/dynamic-form/dynamic-form';
import { TypeArray } from '../../components/generic/dynamic-form/types/type-array';
import SwitchList from '../../components/generic/switch-list';
import VesselBlockScreen from './vessel-block-screen';

interface VesselScreenProps {
    dispatch: Dispatch;
    vessel: Vessel[];
    bookingRules: BookingRules[];
    refesh: string;
    holidays: Holiday[];
}


const VesselScreen: React.FC<any> = ({ dispatch, vessel, refesh, bookingRules, holidays }: VesselScreenProps) => {

    React.useEffect(() => {
        RemoteActions.getVessel(dispatch)
        RemoteActions.getBookingRules(dispatch)
        //RemoteActions.getHoliday(dispatch)
    }, [])


    const changeForm = ({ item, editing, onChange }: { item: Vessel, editing: boolean, onChange: any }) => {
        return (

            <div key={`add_item_form`}>

                <DynamicForm
                    key={'DynamicForm'}
                    data={item}
                    labels={{
                        bookingRules: 'BookingRules',
                        name: 'Name',
                        year: 'Year',
                        cabins: 'Cabins',
                        capacity: 'Capacity',
                        max_members_boat: 'Max Members On Vessel',
                    }}
                    editable={editing}
                    onDataChange={onChange}
                />
                <div style={{ borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid', padding: 10, margin: 10 }}>
                    <div style={{ fontWeight: 'bold', padding: 15 }}>Booking Rules</div>
                    <select id="combo" value={item.bookingRules ? JSON.stringify(item.bookingRules) : bookingRules[0] ? JSON.stringify(bookingRules[0]) : undefined} onChange={(event) => {
                        onChange({ bookingRules: JSON.parse(event.target.value) })
                    }}>
                        {bookingRules.map((element: any, index: number) => {
                            return <option key={`${index}`} value={JSON.stringify(element)}>{element.name}</option>
                        })}
                    </select>

                </div>


                <div style={{ borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid', padding: 10, margin: 10 }}>
                    <VesselBlockScreen
                        vessel={item}
                    />

                </div>

                {/* <div style={{ borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid', padding: 10, margin: 10 }}>
                    <div style={{ fontWeight: 'bold', padding: 15 }}>Holidays</div>
                    <SwitchList
                        field={'holiday'}
                        leftListdata={holidays}
                        rightListdata={item.holiday}
                        onSelectedListChange={onChange}
                        listTemplate={((item: Holiday) => (
                            <div className="">
                                <div className="col-md-8">
                                    <span className="">{`${item.name}`}</span>
                                </div>
                                <div className="col-md-4">
                                    <span className="">{`${item.date}`}</span>
                                </div>
                            </div>
                        ))}
                    />
                </div> */}


            </div>
        )
    }

    const InputForm = () => (
        <div className="container-fluid">
            <h1>Vessels</h1>
            <div style={{ opacity: 0.0 }}>{refesh}</div>
            <ItemsList
                style={{}}
                onSelectItem={() => { }}
                onEditItem={(vessel: Vessel) => {
                    if (!vessel.bookingRules) {
                        throw 'Must select booking rules'
                    }
                    return RemoteActions.setVessel({...vessel}, dispatch)
                }}
                dataTemplate={{
                    name: '',
                    year: '',
                    cabins: 1,
                    capacity: 10,
                    max_members_boat: 10,
                    bookingRules: bookingRules[0],
                    vesselBlock: [],
                }}
                onDeleteItem={(vessel: Vessel) => {
                    return RemoteActions.deleteVessel(vessel.id, dispatch)
                }}
                listTemplate={(vessel: Vessel) => {
                    return (
                        <div className="column">


                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Vessel Name</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${vessel.name}`}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Vessel Year</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${vessel.year}`}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Vessel Cabins</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${vessel.cabins}`}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Vessel Capacity</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${vessel.capacity}`}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Vessel Max Members</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${vessel.max_members_boat}`}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Booking Rules</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${vessel.bookingRules.name}`}</span>
                                </div>
                            </div>
                        </div>
                    )
                }}
                newItemFormTemplate={changeForm}
                list={vessel} />
        </div>
    )


    return InputForm()
};


const mapStateToProps = (state: any) => {
    const reducer = {
        vessel: state.vessels.vessels,
        bookingRules: state.bookingRules.bookingRules,
        holidays: state.holiday.holiday,
        refesh: `${Date.now()}`,
    }
    return reducer
}

export default connect(mapStateToProps)(VesselScreen);

const styles = ({
    container: {}
});
