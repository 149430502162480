import * as React from 'react';
import { Slot, Reservation } from '../../../../../models/models';
import { Button, Modal, Row, Col, Container } from 'react-bootstrap';
import { SlotItemProps, getStyle, AVAILABLE, FREE, NORMAL } from '../../slot-type-interface';
import RemoteActions from '../../../../../remote/remote-actions';
import { months } from '../../../../../utils/calendar-utils';
import { avaliableCredits, isFreeSlot, isValidMaxMonthsOfReservation } from '../../../../../utils/reservation-utils';
import SplitSlots from '../../../../split-slots/split-slots';
import { RenderSlot } from '../../../slot-item';
import { lang, selctedLang } from '../../../../../lang';



interface SlotReservedProps extends SlotItemProps {
    slotType: string;
    dayType: string;
    date: Date;
    canRemoveSplit: boolean;
}

const SlotAvailableCustomer = ({ dispatch, user, vessel, membership, bookingRules, reservations, day, month, year, slot, slotType, dayType, date, holidays, canRemoveSplit }: SlotReservedProps) => {
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const isValidReservation = () => {
        const credits = avaliableCredits(reservations!, year, month, vessel.bookingRules, membership, holidays!)
        const currentDate = `${year}-${month}`
        const index = credits.findIndex((month: any) => month.date === currentDate)

        let borrow = ''
        let isValidReservation = false

        const maxMonthsOfReservation = isValidMaxMonthsOfReservation(date, new Date(year, month, day), vessel.bookingRules)

        if (credits[index][dayType] <= 0) {

            credits.forEach((val: any) => {
                if (val[dayType] > 0 && !isValidReservation) {
                    borrow = val.date
                    isValidReservation = true
                }
            })
        } else {
            isValidReservation = true
        }

        if (!maxMonthsOfReservation)
            isValidReservation = false

        const slotStartHour = +slot.range.split(':')[0]
        const slotDate = new Date(year, month, day, slotStartHour)

        if (slotDate.getTime() < date.getTime())
            isValidReservation = false

        return { isValidReservation, borrow }
    }


    const makeReservation = () => {

        const validReservation = isValidReservation()
        if (validReservation.isValidReservation) {
            const free = isFreeSlot(date, slot, year, month, day)

            const reservation: Reservation = {
                membership,
                user,
                vessel,
                slot: slot.range,
                date: `${year}-${month}-${day}`,
                skippers: [],
                borrow: validReservation.borrow,
                free
            }

            if (slot.original) {
                reservation.original = slot.original
            }
            RemoteActions.setReservation(reservation, dispatch)
        }
    }



    const SlotTemplate = ({ slot }: { slot: Slot }) => (
        <Button onClick={() => isValidReservation().isValidReservation ? handleShow() : () => { }} key={`${slot.id}-${day}-${month}-${year}`} style={getStyle(slotType, dayType, AVAILABLE)} className="event-desc list-group-item list-group-item-action list-group-item-light">
            {slotType === FREE && <Row style={{ backgroundColor: 'yellow', borderRadius: 20, textAlign: 'center', display: 'inline', paddingRight: 10, paddingLeft: 10 }}>
                {lang[selctedLang].free}
            </Row>}
            <RenderSlot slot={slot} />
        </Button>
    )

    const ModalComponent = () => (
        <Modal show={show} onHide={handleClose} animation={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Reservation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3>{`${user.email}`}</h3>
                <p>{lang[selctedLang].reservationConfirmation(slot, vessel, day, month, year, membership)}</p>


                {/* {!slot.original && <SplitSlots
                    slot={slot}
                    slotColor={getStyle(slotType, dayType, AVAILABLE).backgroundColor}
                    onCancel={() => { }}
                    onSplit={(split: any) => {
                        RemoteActions.setSplitSlot({
                            ...split,
                            user,
                            vessel,
                            date: `${year}-${month}-${day}`,
                        }, dispatch)
                    }}
                />} */}

                {canRemoveSplit && (
                    <Row style={{ marginTop: 30 }}>
                        <Col>
                            <Button variant="primary" onClick={() => {
                                RemoteActions.deleteSplitSlot(slot.id, dispatch)
                            }}>
                                {lang[selctedLang].removeSplitSlot}
                            </Button>
                        </Col>
                    </Row>
                )}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {lang[selctedLang].cancel}
                </Button>
                <Button variant="primary" onClick={makeReservation}>
                    {lang[selctedLang].save}
                </Button>
            </Modal.Footer>
        </Modal>
    )

    return (
        <div>
            <SlotTemplate slot={slot} />
            <ModalComponent />
        </div>
    )

}

export default (SlotAvailableCustomer)