import { Holiday } from "../models/models"

export const themeColor = '#53AFB9'
export const colorFree = '#F5C744'
export const colorRemove = '#DA3A42'
export const weekendColor = '#D6D6D6'


export const weekDay = ["Seg", "Ter", "Qua", "Qui", "Sex", "Sab", "Dom"]
export const months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]

export const getWeekday = (day: number) => {
    return weekDay[day]
}

export const daysInMonth = (iMonth: number, iYear: number) => {
    return new Date(iYear, iMonth, 0).getDate();
}

export const getPreviousMonth = ({ month, year }: { month: number, year: number }) => {
    const m = month - 1
    if (m < 0) {
        return {
            month: 11,
            year: year - 1
        }
    }
    return {
        month: m,
        year: year
    }
}

export const getNextMonth = ({ month, year }: { month: number, year: number }) => {
    const m = month + 1
    if (m > 11) {
        return {
            month: 0,
            year: year + 1
        }
    }
    return {
        month: m,
        year: year
    }
}

export const isHoliday = (day: number, month: number, year: number, holidays: Holiday[]) => {
    const isHoliday = holidays.filter((holiday: Holiday) => {
        const parts = holiday.date.split('/')
        const allYears = parts.length === 2


        if (allYears) {
            return (+parts[0] === (month + 1)) && (+parts[1] === day)
        } else {
            return (+parts[0] === +year) && (+parts[1] === +(month + 1)) && (+parts[2] === +day)
        }
    })

    return isHoliday.length > 0
}


export const monthDiff = (dateFrom: Date, dateTo: Date) => {
    return dateTo.getMonth() - dateFrom.getMonth() +
        (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}


