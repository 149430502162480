import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RemoteActions } from '../../remote/remote-actions';
import { User } from '../../models/models';
import { Redirect } from 'react-router-dom';
import { LOGOUT } from '../../redux/actions/auth';
import { Row, Col } from 'react-bootstrap';


interface PasswordRecoveryScreenProps {
    loggedIn: boolean;
    dispatch: Dispatch;
}

const styles = {
    form: {
        // borderWidth: 0.5,
        // borderColor: 'lightgrey',
        // borderStyle: 'solid',
        // borderRadius: 6,
        padding: 22,
    },
    login: {
        marginLeft: 'auto',
        marginRight: 'auto',
    }
}



const PasswordRecoveryScreen: React.FC<PasswordRecoveryScreenProps> = (props: PasswordRecoveryScreenProps) => {

    const [data, setData] = React.useState<any>({ email: '', host: '' })
    const [loading, setLoading] = React.useState<Boolean>(false)
    const [error, setError] = React.useState<string | null>(null)
    const [mailSend, setMailSend] = React.useState<Boolean>(false)


    React.useEffect(() => {
        const url = window.location.href
        const arr = url.split("/");
        const host = arr[0] + "//" + arr[2]
        setData({...data, host})
    }, [])

    const handleChange = (key: 'email', value: any) => {
        let state = data
        state[key] = value
        const newState = { ...data, ...state }

        setData(newState)
    }

    const LoggedIn = () => (<Redirect to={'/reservation'} />)

    const LoginForm = () => (
        <div className="container-fluid">

            {!mailSend && <div className="row">
                <div className="col-md-4">
                </div>
                <div className="col-md-4">
                    <div role="div" style={styles.form}>
                        {error && <span className="badge badge-danger">{error}</span>}
                        <div className="form-group">
                            <label>
                                Email address
                            </label>
                            <input type="email" className="form-control" id="exampleInputEmail1" value={data.email} onChange={(event: any) => handleChange('email', event.target.value)} />
                        </div>

                        <div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-primary" style={{ width: '100%' }} onClick={async () => {
                                    try {
                                        setLoading(true)
                                        await RemoteActions.passwordRecovery(data, props.dispatch)
                                        setLoading(false)
                                        setMailSend(true)
                                    } catch (error) {
                                        setLoading(false)
                                        setError(error.toString())
                                    }

                                }}>
                                    Submit
				                </button>

                                {loading && <div className="d-flex justify-content-center">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>}
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                </div>
            </div>}

            {mailSend && (
                <Row>
                    <Col>
                    <div className="text-center">
                        An email was sent to you account
                    </div>
                    </Col>
                </Row>
            )}


        </div>
    );

    return props.loggedIn ? LoggedIn() : LoginForm()
};


/**
 * controlles login state
 * @param state 
 */
const mapStateToProps = (state: any) => {

    return {
        loggedIn: state.login.loggedIn
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecoveryScreen);

