import * as React from 'react';
import { RemoteActions } from '../../remote/remote-actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux'
import ItemsList from '../../components/generic/items-list';
import { CheckForm, Question } from '../../models/models';
import DynamicForm from '../../components/generic/dynamic-form/dynamic-form';
import SwitchList from '../../components/generic/switch-list';
import { Col, Row, Container } from 'react-bootstrap';

interface CheckFormScreenProps {
    dispatch: Dispatch;
    checkForm: CheckForm[];
    question: Question[];
    refesh: string;
}


const CheckFormScreen: React.FC<any> = ({ dispatch, checkForm, refesh, question }: CheckFormScreenProps) => {

    React.useEffect(() => {
        RemoteActions.getCheckForm(dispatch)
        RemoteActions.getQuestion(dispatch)
    }, [])

    const changeForm = ({ item, editing, onChange }: { item: CheckForm, editing: boolean, onChange: any }) => {


        return (
            <div key={`add_item_form`}>

                <DynamicForm
                    key={'DynamicForm'}
                    data={item}
                    labels={{
                        title: 'Title',
                        questions: 'Questions'
                    }}
                    editable={editing}
                    onDataChange={onChange}
                />
                <div style={{ borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid', padding: 10, margin: 10 }}>
                    <div style={{ fontWeight: 'bold', padding: 15 }}>Form</div>
                    <SwitchList
                        field={'questions'}
                        leftListdata={question}
                        rightListdata={item.questions}
                        onSelectedListChange={onChange}
                        listTemplate={((item: Question) => (
                            <Container>
                                <Row>
                                    {`${item.question}`}
                                </Row>
                                {item.questionGroup && <Row>
                                    {`${item.questionGroup.group}`}
                                </Row>}
                                <Row>
                                    {`${item.responseType}`}
                                </Row>
                            </Container>
                        ))}
                    />
                </div>

            </div>
        )
    }

    const InputForm = () => (
        <div className="container-fluid">
            <h1>Check Form</h1>
            <div style={{ opacity: 0.0 }}>{refesh}</div>
            <ItemsList
                style={{}}
                onSelectItem={() => { }}
                onEditItem={(checkForm: CheckForm) => {
                    return RemoteActions.setCheckForm(checkForm, dispatch)
                }}
                dataTemplate={{
                    title: '',
                    questions: []
                }}
                onDeleteItem={(checkForm: CheckForm) => {
                    return RemoteActions.deleteCheckForm(checkForm.id, dispatch)
                }}
                listTemplate={(checkForm: CheckForm) => {
                    return (
                        <div style={{ display: 'grid' }}>

                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Title</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${checkForm.title}`}</span>
                                </div>
                            </div>


                        </div>
                    )
                }}
                newItemFormTemplate={changeForm}
                list={checkForm} />
        </div>
    )


    return (
        <InputForm />
    );
};


const mapStateToProps = (state: any) => {

    const reducer = {
        checkForm: state.checkForm.checkForm,
        question: state.question.question,
        refesh: `${Date.now()}`,
    }

    return reducer
}

export default connect(mapStateToProps)(CheckFormScreen);

const styles = ({
    container: {}
});
