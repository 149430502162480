import { Table, Row, Col } from "react-bootstrap";
import React from "react";
import { Reservation, Membership, Holiday } from "../../models/models";
import { getDayType, DayType } from "../../utils/reservation-utils";
import { months } from "../../utils/calendar-utils";
import { CSVLink } from "react-csv";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { lang, selctedLang } from "../../lang";



const ReservationsMonthDetail = (props: { year: number, month: number, reservations: Reservation[], membership: Membership, holidays: Holiday[] }) => {

    let data: any[] = []

    const Render = ({ year, month, reservations, membership }: { year: number, month: number, reservations: Reservation[], membership: Membership }) => {
        data = []

        let _reservations = reservations.filter((reservation: Reservation) => {
            if (
                reservation.membership.id === membership.id
            )
                return reservation
        })

        _reservations = _reservations.filter((reservation: Reservation) => {
            const dateParts = reservation.date.split('-')
            if (
                year === +dateParts[0] &&
                month === +dateParts[1]
            )
                return reservation
        })

        const dayTypeLabels = {
            [DayType.WEEK_END]: lang[selctedLang].weekendSlots,
            [DayType.WORKING_DAY]: lang[selctedLang].workingdaySlots,
        }

        let tb: any = []

        _reservations?.forEach((reservation: Reservation) => {
            const dateParts = reservation.date.split('-')
            const dayType = getDayType(reservation, props.holidays)

            const borrowDateParts = reservation.borrow!.split('-')

            data.push({
                User: `${reservation.user.firstName} ${reservation.user.lastName}`,
                Vessel: reservation.vessel.name,
                Date: `${dateParts[0]}, ${months[+dateParts[1]]} ${dateParts[2]}`,
                Ano: `${dateParts[0]}`,
                Mês: `${+dateParts[1] + 1}`,
                Day: `${dateParts[2]}`,
                Slot: `${reservation.slot}`,
                Slots: dayTypeLabels[dayType],
                CreditedMonth: reservation.borrow ? `${borrowDateParts[0]}, ${months[+borrowDateParts[1]]}` : '',
                Remarks: reservation.free ? 'Free slot' : ''
            })

            tb.push(
                (
                    <tr style={{ textAlign: 'center', }} key={`${reservation.date}-${reservation.slot}-${reservation.id}}`}>
                        <td>{reservation.user.firstName} {reservation.user.lastName}</td>

                        <td>{reservation.vessel.name}</td>

                        <td>{`${dateParts[0]}, ${months[+dateParts[1]]} ${dateParts[2]}`}</td>

                        <td>{reservation.slot}</td>

                        <td>{dayTypeLabels[dayType]}</td>

                        {reservation.borrow ? <td>{`${borrowDateParts[0]}, ${months[+borrowDateParts[1]]}`}</td> : <td></td>}

                        <td>{reservation.free ? 'Free slot' : reservation.disable ? 'Removida, crédito cobrado' : ''}</td>
                    </tr>
                )
            )
        })

        return (
            <div>
                <Table bordered className="table table-striped" style={{ maxWidth: 1000, marginLeft: 'auto', marginRight: 'auto', }}>
                    <tbody>
                        <tr>
                            <th style={{ textAlign: 'center', backgroundColor: '#343a40', color: 'white' }} colSpan={7}>Detalhe
                            <CSVLink filename={`${membership.title}-${year}-${month + 1}.csv`} data={data} headers={headers} style={{ float: 'right', padding: 10, backgroundColor: 'lightgrey' }}>
                                    <FontAwesomeIcon icon={faDownload} />
                                </CSVLink>
                            </th>

                        </tr>

                        <tr>
                            <th style={{ textAlign: 'center', }} colSpan={7}>{lang[selctedLang].usedCreditsThisMonth}</th>
                        </tr>

                        <tr>
                            <th style={{ textAlign: 'center', }} colSpan={1}>{lang[selctedLang].user}</th>

                            <th style={{ textAlign: 'center', }} colSpan={1}>{lang[selctedLang].vessel}</th>

                            <th style={{ textAlign: 'center', }} colSpan={2}>{lang[selctedLang].date}</th>

                            <th style={{ textAlign: 'center', }} colSpan={1}>{lang[selctedLang].slot}</th>

                            <th style={{ textAlign: 'center', }} colSpan={1}>{lang[selctedLang].creditMonth}</th>

                            <th style={{ textAlign: 'center', }} colSpan={1}>{lang[selctedLang].remarks}</th>
                        </tr>
                        {tb}
                    </tbody>
                </Table>
            </div>
        )
    }


    const headers = [
        { label: lang[selctedLang].user, key: "User" },
        { label: lang[selctedLang].vessel, key: "Vessel" },
        { label: "Ano", key: "Ano" },
        { label: "Mês", key: "Mês" },
        { label: "Day", key: "Day" },
        { label: "Slot", key: "Slot" },
        { label: "Tipo Slot", key: "Slots" },
        { label: lang[selctedLang].creditMonth, key: "CreditedMonth" },
        { label: lang[selctedLang].remarks, key: "Remarks" },
    ];


    return Render({ ...props })

}

export default ReservationsMonthDetail;