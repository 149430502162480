import SplitSlots from "../components/split-slots/split-slots";
import { BookingRules, Holiday, Membership, Reservation, Slot, SplitSlot, User, Vessel, WaitingList } from "../models/models";
import membership from "../redux/reduceres/membership";
import reservations from "../redux/reduceres/reservation";
import RemoteActions from "../remote/remote-actions";
import { getWeekday, isHoliday, weekDay } from "./calendar-utils";
import { avaliableCredits, borrowReservationsInMonth, borrowringReservationsInMonth, DayType, getWeekSlotsRange, isFreeSlot, isValidMaxMonthsOfReservation } from "./reservation-utils";

export enum UserRoles {
    ADMIN = 'ADMIN',
    SUPER_USER = 'SUPER_USER',
    MEMBER_CLUB = 'MEMBER_CLUB',
    TURISTIC_OPERATOR = 'TURISTIC_OPERATOR',
    CERTIFIED_CLIENT = 'CERTIFIED_CLIENT',
    SKIPPER = 'SKIPPER',
}

export const validDateRange = (range: string) => {
    //YYYY-MM-DD
    const regex = /^[2-9][0-9][0-9][0-9]\/[0-1][0-9]\/[0-3][0-9]$/;
    return regex.test(range)
}

export const validMonthDayDateRange = (range: string) => {
    //YYYY-MM-DD
    const regex = /^[0-1][0-9]\/[0-3][0-9]$/;
    return regex.test(range)
}

export const emojiTable: any = {
    morning: '🌅',
    afternoon: '☀',
    night: '🌙'
}


export const filterUser = (user: User): any => {
    return {
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
    }
}

export const groupBy = (list: any, keyGetter: any) => {
    const map = new Map();
    list.forEach((item: any) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}


export const pad = (num: number, size: number) => {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}


export const getQueryVariable = (variable: any) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}

export const sortSlots = (slots: Slot[]) => {
    let sortSlots = slots.sort((a: Slot, b: Slot) => {
        if (+a.range.split(':')[0] > +b.range.split(':')[0]) {
            return 1
        }
        return -1
    })

    sortSlots = slots.map((slot: Slot) => {

        const asEmoji = emojiTable[slot.name]
        if (asEmoji) {
            slot.name = asEmoji
        }
        return slot
    })

    return sortSlots
}

export const getSlotsData = (user: User, date: Date, day: number, month: number, year: number, vessel: Vessel, reservations: Reservation[], holidays: Holiday[], splitSlots: SplitSlot[], waitingList: WaitingList[]) => {
    const dayDate = new Date(year, month, day)
    const dayOfWeek = getWeekday((dayDate).getDay())

    const isTodayHoliday = isHoliday(day, month, year, holidays)
    const dayType = (dayOfWeek === weekDay[0] || dayOfWeek === weekDay[6] || isTodayHoliday) ? 'WEEK_END' : 'WORKING_DAY'

    const slotTypes = {
        WORKING_DAY: sortSlots(vessel.bookingRules.beforeWeekend),
        WEEK_END: sortSlots(vessel.bookingRules.ofWeekend)
    }

    return (

        slotTypes[dayType].flatMap((slot: Slot) => {

            const isSlotSplit: SplitSlot[] = splitSlots.filter((splitSlot: SplitSlot) =>
                slot.range === splitSlot.slot &&
                splitSlot.date === `${year}-${month}-${day}` &&
                vessel.id === splitSlot.vessel.id
            )

            if (isSlotSplit.length) {
                const isSplit = [
                    {
                        id: isSlotSplit[0].id,
                        name: slot.name,
                        range: isSlotSplit[0].firstSlotRange,
                        original: slot.range,
                    },
                    {
                        id: isSlotSplit[0].id,
                        name: slot.name,
                        range: isSlotSplit[0].secondSlotRange,
                        original: slot.range,
                    }
                ]

                return isSplit.flatMap((_slot: Slot, index: number) => {
                    return getSlot(_slot, user, isSplit, date, year, month, day, vessel, reservations, waitingList, holidays)
                })
            }

            return getSlot(slot, user, null, date, year, month, day, vessel, reservations, waitingList, holidays)
        }))

}

const getSlot = (slot: Slot, user: User, isSlotSplit: any, date: Date, year: number, month: number, day: number, vessel: Vessel, reservations: Reservation[], waitingList: WaitingList[], holidays: Holiday[]) => {
    const slotData: { [k: string]: any } = {}

    slotData.date = `${year}-${month}-${day}`
    slotData.slot = { ...slot }
    slotData.vessel = vessel
    let isReserved;

    slotData.isFree = isFreeSlot(date!, slot, year, month, day)
    slotData.slot.isFree = slotData.isFree
    slotData.isSplit = isSlotSplit

    if (isSlotSplit) {
        const isReserved = reservations!.filter((reservation: Reservation) => (
            reservation.date === `${year}-${month}-${day}` &&
            (reservation.slot === isSlotSplit[0].firstSlotRange || reservation.slot === isSlotSplit[0].secondSlotRange) &&
            reservation.vessel.id === vessel.id &&
            !reservation.disable
        ))

        slotData.canRemoveSplit = isReserved.length ? false : true
    }



    const isTodayHoliday = isHoliday(day, month, year, holidays!)
    var tomorrow = new Date(year, month, day)
    tomorrow.setDate(new Date(year, month, day).getDate() + 1);

    const isTomorowHoliday = isHoliday(tomorrow.getDate(), tomorrow.getMonth(), tomorrow.getFullYear(), holidays!)
    slotData.slot.dayType = getWeekSlotsRange(year, month, day, vessel.bookingRules, slot, isTodayHoliday, isTomorowHoliday)

    isReserved = reservations!.filter((reservation: Reservation) => (
        reservation.date === `${year}-${month}-${day}` &&
        (reservation.slot === slot.range) &&
        reservation.vessel.id === vessel.id &&
        !reservation.disable
    ))


    let reservation: Reservation | null;
    if (isReserved.length) {
        reservation = isReserved[0]
        slotData.reservation = isReserved[0]

        if (waitingList) {
            const waitting = waitingList.filter((waitingList: WaitingList) => waitingList.date === reservation!.date && waitingList.slot === reservation!.slot && waitingList.user.id === user.id)
            if (waitting.length) {
                slotData.onWaitingList = waitting[0]
            }
        }
    }

    return slotData
}

export const shouldRemoveBorrow = (reservationData: any, reservations: Reservation[], holidays: Holiday[], membership: Membership) => {
    if ((reservationData.reservation.borrow && reservationData.reservation.borrow !== '') || reservationData.reservation.free)
        return

    const dateParts = reservationData.reservation.date.split('-')
    const year = +dateParts[0]
    const month = +dateParts[1]
    const day = +dateParts[2]

    const credits = avaliableCredits(reservations, year, month, reservationData.reservation.vessel.bookingRules, membership, holidays)
    const currentDate = `${year}-${month}`
    const index = credits.findIndex((month: any) => month.date === currentDate)

    if (!reservationData.reservation.dayType) {
        const isTodayHoliday = isHoliday(day, month, year, holidays!)
        var tomorrow = new Date(year, month, day)
        tomorrow.setDate(new Date(year, month, day).getDate() + 1);

        const isTomorowHoliday = isHoliday(tomorrow.getDate(), tomorrow.getMonth(), tomorrow.getFullYear(), holidays!)
        reservationData.reservation.dayType = getWeekSlotsRange(year, month, day, reservationData.reservation.vessel.bookingRules, { range: reservationData.reservation.range } as any, isTodayHoliday, isTomorowHoliday)
    }

    if (credits[index][reservationData.reservation.dayType] <= 0) {
        let borrow = borrowringReservationsInMonth(membership, reservations, year, month, holidays)
        if (borrow.length) {
            borrow = borrow.sort((a: Reservation, b: Reservation) => a.id! - b.id!)
            return borrow[0]
        }
    }
    return null
}

export const isValidReservation = (slot: any, year: number, month: number, day: number, holidays: Holiday[], reservations: Reservation[], vessel: Vessel, membership: Membership, date: Date, user: User) => {

    let borrow = ''
    let isValidReservation = false

    if ((slot.dayType === DayType.WORKING_DAY && membership.membershipType.weekSlots > 0 && slot.isFree) || (slot.dayType === DayType.WEEK_END && membership.membershipType.weekendSlots > 0 && slot.isFree)) {
        return { isValidReservation: true, borrow }
    }

    const credits = avaliableCredits(reservations!, year, month, vessel.bookingRules, membership, holidays!)
    const currentDate = `${year}-${month}`
    const index = credits.findIndex((month: any) => month.date === currentDate)

    const maxMonthsOfReservation = isValidMaxMonthsOfReservation(date!, new Date(year, month, day), vessel.bookingRules)
    if (!maxMonthsOfReservation)
        return { isValidReservation: false, borrow }

    if ((slot.dayType === DayType.WORKING_DAY && membership.membershipType.weekSlots === 0) || slot.dayType === DayType.WEEK_END && membership.membershipType.weekendSlots === 0) {
        return { isValidReservation: false, borrow }
    }

    const slotStartHour = +slot.range.split('-')[0].split(':')[0]
    const slotEndHour = +slot.range.split('-')[1].split(':')[0]
    const slotEndMinutes = +slot.range.split('-')[1].split(':')[1]
    const slotDate = new Date(year, month, day)
    slotDate.setHours(slotEndHour)
    slotDate.setMinutes(slotEndMinutes)

    if (slotEndHour < slotStartHour) {
        slotDate.setDate(slotDate.getDate() + 1)
    }

    // if (user.role !== UserRoles.ADMIN) {
    //     if (slotDate.getTime() < date.getTime()) {
    //         return {
    //             isValidReservation: false, borrow
    //         }
    //     }
    // }

    if (credits[index][slot.dayType] <= 0) {
        credits.forEach((val: any) => {
            if (val[slot.dayType] > 0 && borrow === '') {
                borrow = val.date
                isValidReservation = true

                return { isValidReservation, borrow }
            }
        })
    } else {
        isValidReservation = true
    }

    return { isValidReservation, borrow }
}


