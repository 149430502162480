import * as React from 'react';
import ListItems from './list-items';
import _ from 'lodash'

interface SwitchListProps {
    field: string;
    leftListdata: any[];
    rightListdata: any[];
    onSelectedListChange: (items: any) => void;
    listTemplate: (item: any) => any;
}

const SwitchList = ({ field, leftListdata, rightListdata, onSelectedListChange, listTemplate }: SwitchListProps) => {
    const [LeftListdata, setLeftListdata] = React.useState(_.xorBy(leftListdata, rightListdata, 'id'))
    const [RightListdata, setRightListdata] = React.useState(rightListdata ? rightListdata : [])

    const moveLeft = (selected: any) => {
        const index = RightListdata.indexOf(selected)
        RightListdata.splice(index, 1)
        onSelectedListChange({[field]: [...RightListdata]})

        setRightListdata(RightListdata)
        setLeftListdata([...LeftListdata, selected])

        
    }

    const moveRight = (selected: any) => {
        const index = LeftListdata.indexOf(selected)
        LeftListdata.splice(index, 1)
        onSelectedListChange({[field]: [...RightListdata, selected]})

        setLeftListdata(LeftListdata)
        setRightListdata([...RightListdata, selected])
        
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6">
                    <div style={{ padding: 5 }} className="btn-primary">Availabe</div>
                    <ListItems
                        list={LeftListdata}
                        onSelectItem={(item: any) => {
                            moveRight(item)
                        }}
                        listTemplate={(item: any) => (
                            <div style={{ float: 'left', fontWeight: 'bold' }}>
                                {listTemplate(item)}
                            </div>
                        )
                        }
                    />
                </div>

                <div className="col-md-6">
                    <div style={{ padding: 5 }} className="btn-primary">Selected</div>
                    <ListItems
                        list={RightListdata}
                        onSelectItem={(item: any) => {
                            moveLeft(item)
                        }}
                        listTemplate={(item: any) => (
                            <div style={{ float: 'left', fontWeight: 'bold' }}>
                                {listTemplate(item)}
                            </div>
                        )
                        }
                    />
                </div>
            </div>
        </div>
    );
}

export default SwitchList