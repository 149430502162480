import * as React from 'react';
import _ from 'lodash'
import { Slot, Reservation } from '../../models/models';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { isHoliday } from '../../utils/calendar-utils';
import { NORMAL, WORKING_DAY, AVAILABLE, FREE, RESERVED, SlotItemProps } from './slot-types/slot-type-interface';
import SlotAvailableCustomer from './slot-types/slots/customer/slot-available';
import SlotReservedCustomer from './slot-types/slots/customer/slot-reserved';

import SlotAvailableAdmin from './slot-types/slots/admin/slot-available';
import SlotReservedAdmin from './slot-types/slots/admin/slot-reserved';

import SlotAvailableTuristicOperator from './slot-types/slots/turistic-operator/slot-available';
import SlotReservedTuristicOperator from './slot-types/slots/turistic-operator/slot-reserved';

import SlotAvailableSkipper from './slot-types/slots/skipper/slot-available';
import SlotReservedSkipper from './slot-types/slots/skipper/slot-reserved';

import SlotAvailableCertifiedClient from './slot-types/slots/certified-client/slot-available';
import SlotReservedCertifiedClient from './slot-types/slots/certified-client/slot-reserved';

import { UserRoles } from '../../utils/utils';
import { isFreeSlot, getWeekSlotsRange } from '../../utils/reservation-utils';
import { Icons } from '../generic/bottom-navigation';


export const RenderSlot = ({ slot }: { slot: Slot }) => {

    const render = () => {

        const SlotIcon = Icons[slot.name]
        let element = (
            <div style={{ fontSize: 14 }}>
                {SlotIcon}
            </div>
        )

        return (
            <div>
                {element}
            </div>
        )
    }
    return render()
}

export const SplitCircle = ({ width, backgroundColor }: {  width: number, backgroundColor: any }) => {

    const render = () => {

        const circle = {
            borderRadius: '100%',
            height: width,
            width,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: backgroundColor,
            marginRight: 2,
        }

        const p1 = {
            borderTopLeftRadius: width / 2.0,
            borderBottomLeftRadius: width / 2.0,
            width: '50%',
            height: '100%',
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: backgroundColor,
            backgroundColor
        }

        return (
            <div style={circle} >
                <div style={p1} />
            </div>
        )
    }
    
    return render()
}



const SlotItem = ({ dispatch, user, vessel, membership, bookingRules, reservations, day, month, year, slot, date, answers, waitingList, holidays, canRemoveSplit }: SlotItemProps) => {
    const [slotType, setSlotType] = React.useState(NORMAL)
    const [dayType, setDayType] = React.useState(WORKING_DAY)
    const [, setAvailable] = React.useState(AVAILABLE)
    const [reserved, setReserved] = React.useState<Reservation>()

    const SlotTypes: { [k: string]: any } = {
        [UserRoles.ADMIN]: {
            [AVAILABLE]: SlotAvailableAdmin,
            [RESERVED]: SlotReservedAdmin
        },
        [UserRoles.SUPER_USER]: {
            [AVAILABLE]: SlotAvailableAdmin,
            [RESERVED]: SlotReservedAdmin
        },
        [UserRoles.MEMBER_CLUB]: {
            [AVAILABLE]: SlotAvailableCustomer,
            [RESERVED]: SlotReservedCustomer
        },
        [UserRoles.CERTIFIED_CLIENT]: {
            [AVAILABLE]: SlotAvailableCertifiedClient,
            [RESERVED]: SlotReservedCertifiedClient
        },
        [UserRoles.TURISTIC_OPERATOR]: {
            [AVAILABLE]: SlotAvailableTuristicOperator,
            [RESERVED]: SlotReservedTuristicOperator
        },
        [UserRoles.SKIPPER]: {
            [AVAILABLE]: SlotAvailableSkipper,
            [RESERVED]: SlotReservedSkipper
        }
    }



    React.useEffect(() => {

        isFreeSlot(date!, slot, year, month, day) ? setSlotType(FREE) : setSlotType(NORMAL)

        if (vessel) {
            const isReserved = reservations!.filter((reservation: Reservation) => (
                reservation.date === `${year}-${month}-${day}` &&
                reservation.slot === slot.range &&
                reservation.vessel.id === vessel.id &&
                !reservation.disable
            ))

            if (isReserved.length) {
                setReserved(isReserved[0])
                setAvailable(isReserved.length ? RESERVED : AVAILABLE)
            }

            const isTodayHoliday = isHoliday(day, month, year, holidays!)
            var tomorrow = new Date(year, month, day)
            tomorrow.setDate(new Date(year, month, day).getDate() + 1);

            const isTomorowHoliday = isHoliday(tomorrow.getDate(), tomorrow.getMonth(), tomorrow.getFullYear(), holidays!)

            let dayType = getWeekSlotsRange(year, month, day, vessel.bookingRules, slot, isTodayHoliday, isTomorowHoliday)



            setDayType(dayType)
        }


    }, [reservations, reservations!.length, vessel])

    const Render = () => {
        const SlotType = reserved ? SlotTypes[user.role!][RESERVED] : SlotTypes[user.role!][AVAILABLE]

        return <SlotType
            dispatch={dispatch}
            user={user}
            vessel={vessel}
            reservation={reserved}
            membership={membership}
            bookingRules={bookingRules}
            reservations={reservations}
            day={day}
            month={month}
            year={year}
            slot={slot}
            slotType={slotType}
            date={date}
            answers={answers}
            holidays={holidays}
            waitingList={waitingList}
            dayType={dayType}
            canRemoveSplit={canRemoveSplit}
        />

    }

    return <Render />
}

/**
 * controlles login state
 * @param state
 */
const mapStateToProps = (state: any) => {
    return {
        date: state.reservations.date,
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(SlotItem)