import * as React from 'react';


interface ItemsListProps {
    style?: any;
    list: any[];
    onSelectItem: (item: any) => void;
    listTemplate: (item: any) => any;
}

const ListItems = (props: ItemsListProps) => {

    const Render = () => {
        return (
            <div>
                {props.list && props.list.map((element: any, index: number) => {
                    return (
                        <button key={`${index}`} style={{ float: 'left' }} className="list-group-item list-group-item-action list-group-item-light" onClick={() => {
                            props.onSelectItem(element)
                        }}>
                            {props.listTemplate(element)}
                        </button>
                    )
                })}
            </div>
        )
    }

    return (
        <div>
            <Render />
        </div>
    )

}

export default ListItems;