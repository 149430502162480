



export interface ScreenListener {
    onScreenChange: (screen: string) => Promise<any>;
}

const screenList: ScreenListener[] = []


export const addScreenNotifier = (component: ScreenListener) =>{
    screenList.push(component)
}

export const notify = async (screen: string) =>{
    for (let i = 0; i < screenList.length; i++) {
        const component: ScreenListener = screenList[i];
        await component.onScreenChange(screen)
    }
}
