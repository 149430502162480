import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/css/sb-admin-2.css'
import App from './App';
import packageJson from '../package.json';
console.log('app version: ', packageJson.version);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
