import { Reservation, User, Vessel, Slot, BookingRules, MembershipType, Membership, Holiday, QuestionAnswer } from "../models/models"
import { getWeekday, weekDay, getPreviousMonth, getNextMonth, isHoliday, monthDiff } from "./calendar-utils"



export const isValidToRemoveReservation = (date: Date, reservation: Reservation, vessel: Vessel) => {

    const cancelationMaxHours = vessel.bookingRules.cancelation_hours



    const dateParts: string[] = reservation.date.split('-')
    const slotParts: string[] = reservation.slot.split('-')
    const dayDate = new Date(+dateParts[0], +dateParts[1], +dateParts[2], +slotParts[0].split(':')[0])

    const diff = (dayDate.getTime() - date!.getTime()) / 3600000;
    return diff < cancelationMaxHours ? false : true
}


export const isFreeSlot = (date: Date, slot: Slot, year: number, month: number, day: number) => {

    const slotStartHour = +slot.range.split('-')[0].split(':')[0]
    const slotStartMinutes = slot.original ? +slot.original.split('-')[0].split(':')[1] : +slot.range.split('-')[0].split(':')[1]

    const slotEndHour = slot.original ? +slot.original.split('-')[1].split(':')[0] : +slot.range.split('-')[1].split(':')[0]
    const slotEndMinutes = slot.original ? +slot.original.split('-')[1].split(':')[1] : +slot.range.split('-')[1].split(':')[1]

    const startDate = new Date(year, month, day)
    startDate.setHours(slotStartHour)
    startDate.setMinutes(slotStartMinutes)

    const endDate = new Date(year, month, day)
    endDate.setHours(slotEndHour)
    endDate.setMinutes(slotEndMinutes)


    if (slotEndHour < slotStartHour) {
        endDate.setDate(startDate.getDate() + 1)
    }

    const diff = (startDate.getTime() - date!.getTime()) / 3600000;
    const diff2 = (endDate.getTime() - date!.getTime()) / 3600000;

    if ((diff > 0 && diff < 24) || (diff2 > 0 && diff2 < 24)) {
        return true
    }
    return false
}


export const checkIfSlotInRange = (date: Date, slot: Slot) => {

    if (!slot || !slot.range) {
        return false
    }
    const slotStartHour = +slot.range.split('-')[0].split(':')[0]
    const slotStartMinutes = +slot.range.split('-')[0].split(':')[1]

    const slotEndHour = +slot.range.split('-')[1].split(':')[0]
    const slotEndMinutes = +slot.range.split('-')[1].split(':')[1]

    const startDate = new Date(date.getTime())
    startDate.setHours(slotStartHour)
    startDate.setMinutes(slotStartMinutes)

    const endDate = new Date(date.getTime())
    endDate.setHours(slotEndHour)
    endDate.setMinutes(slotEndMinutes)


    if (slotEndHour < slotStartHour) {
        endDate.setDate(startDate.getDate() + 1)
    }

    if (date.getTime() >= startDate.getTime() && date.getTime() < endDate.getTime()) {
        return true
    }
    return false
}




export const reservationsInMonth = (membership: Membership, reservations: Reservation[], year: number, month: number, holidays: Holiday[]) => {
    return reservations.filter((reservation: Reservation | any) => {
        const dateParts = reservation.date.split('-')
        if (
            (reservation.membership.id === membership.id &&
                year === +dateParts[0] &&
                month === +dateParts[1] &&
                reservation.free === false) &&
            (reservation.borrow === '' || !reservation.borrow)
        ) {

            const isTodayHoliday = isHoliday(+dateParts[2], +dateParts[1], +dateParts[0], holidays)

            var tomorrow = new Date(+dateParts[0], +dateParts[1], +dateParts[2])
            tomorrow.setDate(new Date(+dateParts[0], +dateParts[1], +dateParts[2]).getDate() + 1);

            const isTomorowHoliday = isHoliday(tomorrow.getDate(), tomorrow.getMonth(), tomorrow.getFullYear(), holidays)

            const slot: Slot = {
                name: '',
                range: reservation.slot,
                original: reservation.original,
            }

            let dayType = getWeekSlotsRange(+dateParts[0], +dateParts[1], +dateParts[2], reservation.vessel.bookingRules, slot, isTodayHoliday, isTomorowHoliday)

            reservation['dayType'] = dayType
            return reservation
        }
    })
}

export const borrowReservationsInMonth = (membership: Membership, reservations: Reservation[], year: number, month: number, holidays: Holiday[]) => {
    return reservations.filter((reservation: Reservation | any) => {
        const dateParts = reservation.date.split('-')
        const borrowDate = reservation.borrow.split('-')
        if (
            (reservation.membership.id === membership.id &&
                year === +borrowDate[0] &&
                month === +borrowDate[1] &&
                reservation.free === false) &&
            (reservation.borrow !== '')
        ) {

            const isTodayHoliday = isHoliday(+dateParts[2], +dateParts[1], +dateParts[0], holidays)

            var tomorrow = new Date(+dateParts[0], +dateParts[1], +dateParts[2])
            tomorrow.setDate(new Date(+dateParts[0], +dateParts[1], +dateParts[2]).getDate() + 1);

            const isTomorowHoliday = isHoliday(tomorrow.getDate(), tomorrow.getMonth(), tomorrow.getFullYear(), holidays)

            const slot: Slot = {
                name: '',
                range: reservation.slot,
            }

            let dayType = getWeekSlotsRange(+dateParts[0], +dateParts[1], +dateParts[2], reservation.vessel.bookingRules, slot, isTodayHoliday, isTomorowHoliday)

            reservation['dayType'] = dayType
            return reservation
        }
    })
}


export const borrowringReservationsInMonth = (membership: Membership, reservations: Reservation[], year: number, month: number, holidays: Holiday[]) => {
    return reservations.filter((reservation: Reservation | any) => {
        const dateParts = reservation.date.split('-')
        if (
            (reservation.membership.id === membership.id &&
                year === +dateParts[0] &&
                month === +dateParts[1] &&
                reservation.free === false) &&
            (reservation.borrow !== '')
        ) {

            const isTodayHoliday = isHoliday(+dateParts[2], +dateParts[1], +dateParts[0], holidays)

            var tomorrow = new Date(+dateParts[0], +dateParts[1], +dateParts[2])
            tomorrow.setDate(new Date(+dateParts[0], +dateParts[1], +dateParts[2]).getDate() + 1);

            const isTomorowHoliday = isHoliday(tomorrow.getDate(), tomorrow.getMonth(), tomorrow.getFullYear(), holidays)

            const slot: Slot = {
                name: '',
                range: reservation.slot,
            }

            let dayType = getWeekSlotsRange(+dateParts[0], +dateParts[1], +dateParts[2], reservation.vessel.bookingRules, slot, isTodayHoliday, isTomorowHoliday)

            reservation['dayType'] = dayType
            return reservation
        }
    })
}

export const reservationsInMonths = (reservations: Reservation[], year: number, month: number, bookingRules: BookingRules, membership: Membership, holidays: Holiday[]) => {
    const previousMonth = bookingRules.borrow_previous
    const nextMonth = bookingRules.borrow_next

    const creationDateParts = membership.creationDate!.split('-')
    let endDateParts: any = null

    if (membership.endDate && membership.endDate !== '') {
        endDateParts = membership.endDate?.split('-')
    }

    let initialDate = {
        month,
        year
    }

    let membershipReservations: { [k: string]: any } = {}

    // previous months

    for (let i = 0; i < previousMonth; i++) {
        initialDate = getPreviousMonth(initialDate)
        if (
            (initialDate.year < +creationDateParts[0]) || (initialDate.year === +creationDateParts[0] // dont go before creation date
                && initialDate.month < +creationDateParts[1] - 1)
        ) {
            break;
        }

        const reservationInMonth = reservationsInMonth(membership, reservations, initialDate.year, initialDate.month, holidays)
        if (reservationInMonth.length)
            membershipReservations[`${initialDate.year}-${initialDate.month}`] = reservationInMonth
        else
            membershipReservations[`${initialDate.year}-${initialDate.month}`] = []
    }

    initialDate = {
        month,
        year
    }

    // actual month
    const reservationInMonth = reservationsInMonth(membership, reservations, initialDate.year, initialDate.month, holidays)
    if (reservationInMonth.length)
        membershipReservations[`${initialDate.year}-${initialDate.month}`] = reservationInMonth
    else
        membershipReservations[`${initialDate.year}-${initialDate.month}`] = []


    // next months
    for (let i = 0; i < nextMonth; i++) {
        initialDate = getNextMonth(initialDate)

        if (
            endDateParts &&
            ((initialDate.year > +endDateParts[0]) ||
                (initialDate.year === +endDateParts![0] && initialDate.month > +endDateParts[1] - 1)) // dont go after creation date
        ) {
            break;
        }

        const reservationInMonth = reservationsInMonth(membership, reservations, initialDate.year, initialDate.month, holidays)
        if (reservationInMonth.length)
            membershipReservations[`${initialDate.year}-${initialDate.month}`] = reservationInMonth
        else
            membershipReservations[`${initialDate.year}-${initialDate.month}`] = []
    }

    Object.keys(membershipReservations).forEach((key: string) => {

        const borrowDateParts = key.split('-')
        const borrowReservationInMonth = borrowReservationsInMonth(membership, reservations, +borrowDateParts[0], +borrowDateParts[1], holidays)
        membershipReservations[key] = [...membershipReservations[key], ...borrowReservationInMonth]
    })


    return membershipReservations

}


let lastAvailableCredits = ''
let avaliableCreditsData: any;

export const avaliableCredits = (reservations: Reservation[], year: number, month: number, bookingRules: BookingRules, membership: Membership, holidays: Holiday[]) => {

    // const hash = `${year}-${month}-${JSON.stringify(membership)}-${JSON.stringify(bookingRules)}-${JSON.stringify(holidays)}-${JSON.stringify(reservations)}`
    // if(hash === lastAvailableCredits){
    //     return avaliableCreditsData
    // }
    // lastAvailableCredits = hash

    const weekSlots = membership.membershipType.weekSlots
    const weekendSlots = membership.membershipType.weekendSlots

    const _reservationsInMonths = reservationsInMonths(reservations, year, month, bookingRules, membership, holidays)
    const availableSlots: any[] = []

    Object.keys(_reservationsInMonths).map((key: string) => {
        const reservations = _reservationsInMonths[key]

        const filter = reservations.filter((reservation: Reservation | any) => reservation['dayType'] === DayType.WEEK_END)

        const weekEndCount = weekendSlots - filter.length >= 0 ? weekendSlots - filter.length : 0
        const weekCount = weekSlots - (reservations.length - filter.length) >= 0 ? weekSlots - (reservations.length - filter.length) : 0

        availableSlots.push({
            date: key,
            [DayType.WEEK_END]: weekEndCount,
            [DayType.WORKING_DAY]: weekCount,
            reservationsInMonth: _reservationsInMonths[key]
        })

    })

    avaliableCreditsData = availableSlots
    return availableSlots
}


export const borrowCredits = (reservations: Reservation[], year: number, month: number, bookingRules: BookingRules, membership: Membership, holidays: Holiday[]) => {
    const weekSlots = membership.membershipType.weekSlots
    const weekendSlots = membership.membershipType.weekendSlots

    const _reservationsInMonths = reservationsBorrowInMonths(reservations, year, month, bookingRules, membership, holidays)
    const availableSlots: any[] = []

    Object.keys(_reservationsInMonths).map((key: string) => {
        const reservations = _reservationsInMonths[key]
        const filter = reservations.filter((reservation: Reservation | any) => reservation['dayType'] === DayType.WEEK_END)
        availableSlots.push({
            date: key,
            [DayType.WEEK_END]: filter.length,
            [DayType.WORKING_DAY]: (reservations.length - filter.length),
        })

    })

    return availableSlots
}

export const reservationsBorrowInMonths = (reservations: Reservation[], year: number, month: number, bookingRules: BookingRules, membership: Membership, holidays: Holiday[]) => {
    const previousMonth = bookingRules.borrow_previous
    const nextMonth = bookingRules.borrow_next

    const creationDateParts = membership.creationDate!.split('-')

    let initialDate = {
        month,
        year
    }

    let membershipReservations: { [k: string]: any } = {}

    // previous months

    for (let i = 0; i < previousMonth; i++) {
        initialDate = getPreviousMonth(initialDate)
        if (
            (initialDate.year < +creationDateParts[0]) || (initialDate.year === +creationDateParts[0] // dont go before creation date
                && initialDate.month < +creationDateParts[1])
        ) {
            break;
        }

        const reservationInMonth = reservationsInMonth(membership, reservations, initialDate.year, initialDate.month, holidays)
        if (reservationInMonth.length)
            membershipReservations[`${initialDate.year}-${initialDate.month}`] = reservationInMonth
        else
            membershipReservations[`${initialDate.year}-${initialDate.month}`] = []
    }

    initialDate = {
        month,
        year
    }

    // actual month
    const reservationInMonth = reservationsInMonth(membership, reservations, initialDate.year, initialDate.month, holidays)
    if (reservationInMonth.length)
        membershipReservations[`${initialDate.year}-${initialDate.month}`] = reservationInMonth
    else
        membershipReservations[`${initialDate.year}-${initialDate.month}`] = []


    // next months
    for (let i = 0; i < nextMonth; i++) {
        initialDate = getNextMonth(initialDate)

        const reservationInMonth = reservationsInMonth(membership, reservations, initialDate.year, initialDate.month, holidays)
        if (reservationInMonth.length)
            membershipReservations[`${initialDate.year}-${initialDate.month}`] = reservationInMonth
        else
            membershipReservations[`${initialDate.year}-${initialDate.month}`] = []
    }

    Object.keys(membershipReservations).forEach((key: string) => {

        const borrowDateParts = key.split('-')
        const borrowReservationInMonth = borrowReservationsInMonth(membership, reservations, +borrowDateParts[0], +borrowDateParts[1], holidays)
        membershipReservations[key] = [...borrowReservationInMonth]
    })


    return membershipReservations

}


export const GetTable = ({ _availableSlots }: { _availableSlots: any[] }) => {
    let weekendSum = 0
    let workingdaySum = 0

    let weekendString = '('
    let workingdayString = '('

    for (let index = 0; index < _availableSlots.length; index++) {
        const element = _availableSlots[index];
        if (index > 0) {
            weekendString = weekendString + '+'
            workingdayString = workingdayString + '+'
        }
        weekendString = weekendString + element[DayType.WEEK_END]
        workingdayString = workingdayString + element[DayType.WORKING_DAY]

        weekendSum = weekendSum + +element[DayType.WEEK_END]
        workingdaySum = workingdaySum + +element[DayType.WORKING_DAY]
    }

    weekendString = weekendString + ')'
    workingdayString = workingdayString + ')'

}

export const DayType = {
    WEEK_END: 'WEEK_END',
    WORKING_DAY: 'WORKING_DAY'
}

export const getWeekDayType = (year: number, month: number, day: number) => {
    const dayDate = new Date(year, month, day)
    const dayOfWeek = getWeekday((dayDate).getDay())
    const dayType = (dayOfWeek === weekDay[0] || dayOfWeek === weekDay[6]) ? DayType.WEEK_END : DayType.WORKING_DAY

    return dayType
}


export const getDayType = (reservation: Reservation, holidays: Holiday[]) => {

    const dateParts = reservation.date.split('-')

    const year = +dateParts[0]
    const month = +dateParts[1]
    const day = +dateParts[2]
    const isTodayHoliday = isHoliday(day, month, year, holidays!)
    var tomorrow = new Date(year, month, day)
    tomorrow.setDate(new Date(year, month, day).getDate() + 1);

    const isTomorowHoliday = isHoliday(tomorrow.getDate(), tomorrow.getMonth(), tomorrow.getFullYear(), holidays!)
    const slot = { name: '', range: reservation.slot }

    return getWeekSlotsRange(year, month, day, reservation.vessel.bookingRules, slot, isTodayHoliday, isTomorowHoliday)
}



export const getWeekSlotsRange = (year: number, month: number, day: number, bookingRules: BookingRules, slot: Slot, isTodayHoliday: boolean, isTomorowHoliday: boolean) => {
    const dayDate = new Date(year, month, day)
    const dayOfWeek = (dayDate).getDay()

    //console.log(year, month, day, dayOfWeek, isTodayHoliday, isTomorowHoliday, bookingRules.weekStartSlot.range)

    if (dayOfWeek === 6) {
        return DayType.WEEK_END // saturday
    } else if ((dayOfWeek === 5 && !isTodayHoliday) || (!isTodayHoliday && isTomorowHoliday && dayOfWeek !== 0)) { // friday or tomorow is holiday
        if (!bookingRules.weekendStartSlot) {
            return DayType.WORKING_DAY
        }

        const weekendStartDate = new Date(year, month, day, +bookingRules.weekendStartSlot.range.split(':')[0])
        const slotDate = new Date(year, month, day, (slot.original && slot.original !== null) ? +slot.original.split(':')[0] : +slot.range.split(':')[0])

        if (slotDate.getTime() >= weekendStartDate.getTime()) {
            // is weekend
            return DayType.WEEK_END
        } else {
            return DayType.WORKING_DAY
        }
    } else if (dayOfWeek === 0 || isTodayHoliday) { // sunday
        if (!bookingRules.weekStartSlot) {
            return DayType.WEEK_END
        }
        const weekStartDate = new Date(year, month, day, +bookingRules.weekStartSlot.range.split(':')[0])
        const slotDate = new Date(year, month, day, (slot.original && slot.original !== null) ? +slot.original.split(':')[0] : +slot.range.split(':')[0])

        if (slotDate.getTime() >= weekStartDate.getTime() && !(isTomorowHoliday || dayOfWeek === 5)) {
            // is week
            return DayType.WORKING_DAY
        } else {
            return DayType.WEEK_END
        }
    } else { // all other working days
        return DayType.WORKING_DAY
    }

}



export const isValidMaxMonthsOfReservation = (currentDate: Date, reservationDate: Date, bookingRules: BookingRules) => {

    const diffMonths = monthDiff(currentDate, reservationDate)
    if (diffMonths <= bookingRules.max_months_future_booking) {
        return true
    }

    return false

}


export const disableCheck = (disable: [string] | undefined, component: any, key: string) => {
    if (!disable || disable?.indexOf(key) < 0) {
        return component
    }
    return null
}

export const isCheckInOutFill = (answers: QuestionAnswer[], type: "checkIn" | "checkOut", reservations: Reservation[]) => {
    const res = answers.filter((answer: QuestionAnswer) => {
        return answer.type === type && answer.reservation && answer.reservation.filter((reservation: Reservation) => reservation.id === reservations[0].id).length
    })

    return res.length > 0
}

export const sortReservations = (a: Reservation, b: Reservation) => {
    return getReservationStartDate(b).getTime() - getReservationStartDate(a).getTime()
}

const getReservationStartDate = (resservation: Reservation) => {
    const dateParts = resservation.date.split('-')
    const year = +dateParts[0]
    const month = +dateParts[1]
    const day = +dateParts[2]
    const slotHour = +resservation.slot.split('-')[0].split(':')[0]
    const slotMinutes = +resservation.slot.split('-')[0].split(':')[1]
    const date = new Date(year, month, day)
    date.setHours(slotHour)
    date.setMinutes(slotMinutes)

    if (resservation.original && resservation.original !== '') {
        const originalHour = +resservation.original.split('-')[0].split(':')[0]
        const originalMinutes = +resservation.original.split('-')[0].split(':')[1]
        const originalDate = new Date(year, month, day)
        originalDate.setHours(originalHour)
        originalDate.setMinutes(originalMinutes)
        if (date.getTime() < originalDate.getTime()) {
            date.setDate(date.getDate() + 1)
        }
    }

    return date
}