export enum ACTIONS_DL {
    ADD_DLS_SUCCESS = 'ADD_DLS_SUCCESS',
    ADD_DLS_FAILURE = 'ADD_DLS_FAILURE',

    GET_DLS_SUCCESS = 'GET_DLS_SUCCESS',
    GET_DLS_FAILURE = 'GET_DLS_FAILURE',

    DELETE_DLS_SUCCESS = 'DELETE_DLS_SUCCESS',
    DELETE_DLS_FAILURE = 'DELETE_DLS_FAILURE',
}

export const ADD_DLS_SUCCESS = (data:any) => ({
    type: ACTIONS_DL.ADD_DLS_SUCCESS,
    data
})

export const ADD_DLS_FAILURE = (e:any) => ({
    type: ACTIONS_DL.ADD_DLS_FAILURE,
    error: e
})

export const DELETE_DLS_SUCCESS = (data:any) => ({
    type: ACTIONS_DL.DELETE_DLS_SUCCESS,
    data
})

export const DELETE_DLS_FAILURE = (e:any) => ({
    type: ACTIONS_DL.DELETE_DLS_FAILURE,
    error: e
})


export const GET_DLS_SUCCESS = (data:any) => ({
    type: ACTIONS_DL.GET_DLS_SUCCESS,
    data
})

export const GET_DLS_FAILURE = (e:any) => ({
    type: ACTIONS_DL.GET_DLS_FAILURE,
    error: e
})