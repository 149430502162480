export enum ACTIONS_VESSEL {
    ADD_VESSELS_SUCCESS = 'ADD_VESSELS_SUCCESS',
    ADD_VESSELS_FAILURE = 'ADD_VESSELS_FAILURE',

    GET_VESSELS_SUCCESS = 'GET_VESSELS_SUCCESS',
    GET_VESSELS_FAILURE = 'GET_VESSELS_FAILURE',

    DELETE_VESSELS_SUCCESS = 'DELETE_VESSELS_SUCCESS',
    DELETE_VESSELS_FAILURE = 'DELETE_VESSELS_FAILURE',
}

export const ADD_VESSELS_SUCCESS = (data:any) => ({
    type: ACTIONS_VESSEL.ADD_VESSELS_SUCCESS,
    data
})

export const ADD_VESSELS_FAILURE = (e:any) => ({
    type: ACTIONS_VESSEL.ADD_VESSELS_FAILURE,
    error: e
})

export const DELETE_VESSELS_SUCCESS = (data:any) => ({
    type: ACTIONS_VESSEL.DELETE_VESSELS_SUCCESS,
    data
})

export const DELETE_VESSELS_FAILURE = (e:any) => ({
    type: ACTIONS_VESSEL.DELETE_VESSELS_FAILURE,
    error: e
})


export const GET_VESSELS_SUCCESS = (data:any) => ({
    type: ACTIONS_VESSEL.GET_VESSELS_SUCCESS,
    data
})

export const GET_VESSELS_FAILURE = (e:any) => ({
    type: ACTIONS_VESSEL.GET_VESSELS_FAILURE,
    error: e
})