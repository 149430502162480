import * as React from 'react';
import { RemoteActions } from '../../remote/remote-actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux'
import ItemsList from '../../components/generic/items-list';
import { DL } from '../../models/models';
import DynamicForm from '../../components/generic/dynamic-form/dynamic-form';
import { Container, Row, Col } from 'react-bootstrap';


export enum TAG {
    RESERVATION,
}

interface DistributionListScreenProps {
    dispatch: Dispatch,
    dl: DL[];
    refesh: string;
}

const DistributionListScreen: React.FC<DistributionListScreenProps> = ({ dispatch, dl, refesh }: DistributionListScreenProps) => {

    React.useEffect(() => {
        RemoteActions.getDL(dispatch)
    }, [])


    return (
        <div className="container-fluid">
            <h1>Distribution List</h1>
            <div style={{ opacity: 0.0 }}>{refesh}</div>



            <ItemsList
                style={{}}
                onSelectItem={() => { }}
                onEditItem={(dl: DL) => {
                    return RemoteActions.setDL(dl, dispatch)
                }}
                dataTemplate={{
                    email: '',
                    tag: TAG.RESERVATION
                }}
                onDeleteItem={(dl: DL) => {
                    return RemoteActions.deleteDL(dl.id, dispatch)
                }}
                listTemplate={(dl: DL) => {
                    return (
                        <div>
                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Email</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${dl.email}`}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Tag</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${TAG[+dl.tag]}`}</span>
                                </div>
                            </div>
                        </div>
                    )
                }}
                newItemFormTemplate={({ item, editing, onChange }: { item: DL, editing: boolean, onChange: any }) => {
                    const labels: DL = {
                        email: 'Email',
                        tag: 'Tag'
                    }

                    return (
                        <Col>
                            <DynamicForm
                                exclude={{ tag: '' }}
                                data={item}
                                labels={labels}
                                editable={editing}
                                onDataChange={(data: any) => {
                                    onChange(data)
                                }}
                            />
                            <Row style={{ marginLeft: 10 }}>
                                <Col>
                                    TAG
                                </Col>
                                <Col>
                                    <select id="combo" onChange={(event: any) => {
                                        item.tag = event.target.value
                                        onChange(item)
                                    }}>
                                        <option value={TAG.RESERVATION}>RESERVATION TAG</option>
                                    </select>
                                </Col>
                            </Row>


                        </Col>
                    )
                }}
                list={dl} />
        </div>
    );
};


const mapStateToProps = (state: any) => {
    const reducer = {
        dl: state.dls.dls,
        refesh: `${Date.now()}`,
    }
    return reducer
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(DistributionListScreen);

const styles = ({
    container: {}
});
