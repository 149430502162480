import { ACTIONS_HOLIDAYS } from "../actions/holidays"
import { Holiday } from "../../models/models"

interface IState {
    holiday: Holiday[],
    error: string
}

export const initialState: IState = { holiday: [], error: '' }

export const holiday = (state = initialState, action: any) => {
    switch (action.type) {
        case ACTIONS_HOLIDAYS.ADD_HOLIDAYS_SUCCESS:
            
            const exists = state.holiday.findIndex(x => x.id===action.data.id)
            if(exists > -1){
                state.holiday[exists] = action.data as Holiday
            }else{
                state.holiday.push(action.data as Holiday)
            }

            return {
                ...state,
            }
        case ACTIONS_HOLIDAYS.ADD_HOLIDAYS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_HOLIDAYS.DELETE_HOLIDAYS_SUCCESS:
            const holiday = state.holiday.filter((holiday: Holiday)=> holiday.id !== action.data.id)
            return {
                ...state,
                holiday
            }
        case ACTIONS_HOLIDAYS.DELETE_HOLIDAYS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_HOLIDAYS.GET_HOLIDAYS_SUCCESS:
            return {
                ...state,
                holiday: action.data
            }
        case ACTIONS_HOLIDAYS.GET_HOLIDAYS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        default:
            return state
    }
}

export default holiday