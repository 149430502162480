import { TypeString } from "./type-string";
import { TypeBoolean } from "./type-boolean";
import { TypeArray } from "./type-array";
import { TypeNumber } from "./type-number";


export const FormTypes: any = {
    'string': TypeString,
    'boolean': TypeBoolean,
    //'array': TypeArray, 
    'number': TypeNumber,
}