import * as React from 'react';
import { lang, selctedLang } from '../../lang';
import RemoteActions from '../../remote/remote-actions';
import SelectionModal from './Modal';
import { Dispatch } from 'redux';
import ButtonWithFeedback from '../generic/buttonWithFeedback';
import { Message } from '../../models/models';
import { connect } from 'react-redux';

interface AddMessageModalProps {
    show: boolean;
    handleClose?: () => void;
    dispatch: Dispatch;
    message: Message;
    date: Date;
}

interface AddMessageModalState {
    preview: { file: any, img?: string }
    messageValue: string
}

class AddMessageModal extends React.Component<AddMessageModalProps, AddMessageModalState>{
    constructor(props: AddMessageModalProps) {
        super(props);
        this.state = {
            preview: { file: null, img: '' },
            messageValue: ''
        }
    }

    inputOpenFileRef: any = React.createRef()

    showOpenFileDlg = () => {
        this.inputOpenFileRef.current.click()
    }

    selectedFile = (input: any) => {
        let file = input.target.files[0]
        
        if (!file)
        return
        
        const img = URL.createObjectURL(file)
        this.setState({ preview: { file, img }, messageValue: this.props.message.message })
    }

    setMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.props.message.message = event.target.value
    }

    renderText = () => {
        if(this.props.message.message !== ''){
            return (<textarea id="exampleForm" rows={3} defaultValue={this.state.messageValue} onChange={this.setMessage}></textarea>)
        }
        
        return (<textarea id="exampleForm" rows={3} onChange={this.setMessage}></textarea>)
    }


    render = () => {
        if(this.state.messageValue !== ''){
            this.props.message.message = this.state.messageValue
        }

        return (
            <div style={{}}>

                <SelectionModal handleClose={this.props.handleClose} show={this.props.show} Content={() => {
                    return (
                        <div style={{ display: 'flex', marginBottom: 8, flexDirection: 'column', padding: 5, paddingTop: 20 }}>

                            <div style={{ textAlign: 'center', paddingBottom: 20 }}>
                                {lang[selctedLang].newMessage}
                                <div style={{ display: 'flex', flexDirection: 'column' }}>


                                    <label>{lang[selctedLang].image}</label>
                                    <input ref={this.inputOpenFileRef} onChange={this.selectedFile} type="file" style={{ display: "none" }} />
                                    <div style={{ width: '100%', height: 200, display: 'flex', flexDirection: 'row', justifyContent: 'center' }} onClick={this.showOpenFileDlg}>
                                        <img style={{ height: 200 }} src={this.state.preview.img ?
                                            this.state.preview.img :
                                            "https://www.victorromero.com.br/wp-content/uploads/2019/03/image-placeholder-350x350.png"}
                                            className="rounded mx-auto d-block" alt="..." />
                                    </div>
                                    {this.renderText()}
                                    <label>{lang[selctedLang].message}</label>

                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 5, }}>
                                <div className='btHorizontal btCANCEL' onClick={() => {
                                    this.setState({})
                                    this.props.handleClose!()
                                }}
                                >
                                    {lang[selctedLang].cancel}
                                </div>

                                <ButtonWithFeedback
                                    onPress={async () => {
                                        return new Promise<void>(async (resolve, reject) => {
                                            try {

                                                if (this.state.preview.file) {
                                                    const _data = new FormData()
                                                    _data.append('file', this.state.preview.file, this.state.preview.file.name)
                                                    const image: any = await RemoteActions.uploadFile(_data, () => { })
                                                    this.props.message.image = image.data
                                                }
                                                const url = window.location.href
                                                const arr = url.split("/");
                                                const host = arr[0] + "//" + arr[2]

                                                this.props.message.date = `${this.props.date.getFullYear()}-${this.props.date.getMonth()}-${this.props.date.getDate()}, ${this.props.date.getHours()}:${this.props.date.getMinutes()}:${this.props.date.getSeconds()}`
                                                const newMessage = {...this.props.message, host}
                                                await RemoteActions.setMessage(newMessage, this.props.dispatch)
                                                this.setState({ preview: { file: null } })
                                                this.props.handleClose!()
                                                resolve()
                                            } catch (error) {
                                                reject()
                                            }
                                        })
                                    }}
                                    text={lang[selctedLang].submit}
                                    className='btHorizontal btOK'
                                />
                            </div>
                        </div>
                    )
                }} />
            </div>
        )
    };
};

const mapStateToProps = (state: any) => {
    const reducer = {
        date: state.reservations.date,
    }
    return reducer
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(AddMessageModal);

