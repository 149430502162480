import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareLeft, faCaretSquareRight } from '@fortawesome/free-solid-svg-icons';
import { Vessel, BookingRules, Reservation, Slot, Membership, User, Holiday, SkipperAvailability, SkipperAccount } from '../models/models';
import RemoteActions from '../remote/remote-actions';
import { sortSlots } from '../utils/utils';

import { Table, Container, Row, Col } from 'react-bootstrap';
import { months, weekDay, daysInMonth, isHoliday, getPreviousMonth, getNextMonth } from '../utils/calendar-utils';




interface SkipperCalendarProps {
    user: User;
    skipper: SkipperAccount;
    reservations: Reservation[];
    dispatch: Dispatch;
    refesh: string;
    date: Date;
    holidays: Holiday[];
    vessels: Vessel[];
    skipperAvailability: SkipperAvailability[];
    getSlots: (day: number, month: number, year: number, bookingRules: BookingRules, vessel: Vessel, skipperAvailability: SkipperAvailability[], reservations: Reservation[]) => any;
}

const SELECTED_VESSEL = 'SELECTED_VESSEL'

const SkipperCalendar = ({ vessels, user, skipper, reservations, dispatch, refesh, getSlots, date, holidays, skipperAvailability }: SkipperCalendarProps) => {

    const getSelectedVessel = () => {
        const selectedVessel = localStorage.getItem(SELECTED_VESSEL)

        if (selectedVessel) {
            const stored = JSON.parse(selectedVessel!)
            const vessel = vessels!.find((_vessel: Vessel) => _vessel.id === stored.id)
            if (vessel)
                return vessel!
        }

        if (vessels)
            return vessels[0]
        else
            return undefined
    }

    const [viewDate, setViewDate] = React.useState({ month: date.getMonth(), year: date.getFullYear() })
    const [monthName, setMonthName] = React.useState(months[date.getMonth()])
    const [vessel, setVessel] = React.useState<Vessel | undefined>(getSelectedVessel())

    const filterReservations = (_reservations: Reservation[]) => {
        return _reservations.filter((reservation: Reservation) => {
            return (
                reservation.skippers?.filter((_skipper: SkipperAccount) => _skipper.id === skipper.id).length
            )
        })

    }

    const filterSkipperAvailability = (_skipperAvailability: SkipperAvailability[]) => {
        return _skipperAvailability.filter((reservation: SkipperAvailability) => {
            return (
                reservation.skipper.id === skipper.id
            )
        })
    }

    React.useEffect(() => {
        setViewDate({ month: date.getMonth(), year: date.getFullYear() })
        setMonthName(months[date.getMonth()])
    }, [date])

    React.useEffect(() => {
        RemoteActions.getServerDate(dispatch)
        RemoteActions.getReservation(dispatch)
        RemoteActions.getBookingRules(dispatch)
    }, [])

    React.useEffect(() => {
        const _vessel = getSelectedVessel()
        setVessel(_vessel)
    }, [vessels])

    const DrawSkipperCalendarHeader = () => {
        const CHeader = weekDay.map((day: string) => {
            return (
                <th key={`${day}`}>
                    {day}
                </th>
            )
        })

        return (
            <tr className="weekdays">
                {CHeader}
            </tr>
        )
    }

    const ShowSkipperCalendar = ({ month, year, bookingRules }: { month: number, year: number, bookingRules: BookingRules }) => {

        let firstDay = (new Date(year, month)).getDay() - 1;
        const _document = []

        let _date = 1;
        for (let i = 0; i < 6; i++) {
            let row = [];
            for (let j = 0; j < 7; j++) {
                if (i === 0 && j < firstDay) {
                    const cell = <td className="day" key={`${i}-${j}`}></td>
                    row.push(cell)
                }
                else if (_date > daysInMonth(month + 1, year)) {
                    const cell = <td className="day" key={`${i}-${j}`}></td>
                    row.push(cell)
                    break;
                }
                else {
                    const holiday = isHoliday(_date, month, year, holidays)
                    const isToday = (_date === date.getDate()) && (month === date.getMonth()) && (year === date.getFullYear())
                    let style = {}
                    if (holiday) {
                        style = { backgroundColor: 'rgba(223, 242, 192, 1)' }
                    }

                    if (isToday) {
                        style = { borderColor: 'white', borderWidth: 2, ...style }
                    }

                    const cell = (
                        <td key={`${i}-${j}`} style={style}>
                            <div className="date" style={{ textAlign: 'right', fontWeight: 'bold' }}>{`${_date}`}</div>
                            {getSlots(_date, month, year, bookingRules, vessel!, filterSkipperAvailability(skipperAvailability), filterReservations(reservations))}
                        </td>
                    )
                    row.push(cell)
                    _date++;
                }
            }
            _document.push((<tr className="days" key={`${i}`}>{row}</tr>))
        }
        return (
            <tbody>
                {_document}
            </tbody>
        )
    }


    return (
        <Container >
            <Row>
                <Col md='1' style={{ textAlign: 'start' }}>Vessel</Col>
                <Col>

                    <select id="combo" onChange={(event) => {
                        const _vessel: Vessel = JSON.parse(event.target.value)
                        setVessel(_vessel)
                        localStorage.setItem(SELECTED_VESSEL, event.target.value)
                    }}>
                        {vessels.map((element: any, index: number) => {
                            const isSelected = vessel?.id === element.id
                            return isSelected
                                ?
                                <option key={`${index}`} selected value={JSON.stringify(element)}>{element.name}</option>
                                :
                                <option key={`${index}`} value={JSON.stringify(element)}>{element.name}</option>
                        })}
                    </select>

                </Col>
            </Row>


            <Row>
                <Col>
                    <div style={{ margin: 20, float: 'right', borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid' }}>
                        <button style={{ float: 'left' }} onClick={() => {
                            const newData = getPreviousMonth(viewDate)
                            setMonthName(months[newData.month])
                            setViewDate(newData)
                        }}>
                            <FontAwesomeIcon icon={faCaretSquareLeft} />
                        </button>
                        <div style={{ float: 'left', width: 80, textAlign: 'center' }}>{monthName}</div>
                        <div style={{ float: 'left', textAlign: 'center', marginRight: 15 }}>{viewDate.year}</div>
                        <button style={{ float: 'left' }} onClick={() => {
                            const newData = getNextMonth(viewDate)
                            setMonthName(months[newData.month])
                            setViewDate(newData)
                        }}>
                            <FontAwesomeIcon icon={faCaretSquareRight} />
                        </button>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Table id="Skippercalendar" bordered variant="dark">

                        <DrawSkipperCalendarHeader />

                        {vessel && <ShowSkipperCalendar month={viewDate.month} year={viewDate.year} bookingRules={vessel!.bookingRules} />}

                    </Table>
                </Col>
            </Row>

            <div style={{ opacity: 0.0 }}>{refesh}</div>
        </Container>
    );
};


/**
 * controlles login state
 * @param state
 */
const mapStateToProps = (state: any) => {
    return {
        reservations: state.reservations.reservations,
        refesh: `${Date.now()}`,
        date: state.reservations.date,
        holidays: state.holiday.holiday,
        vessels: state.vessels.vessels,
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(SkipperCalendar);

