import * as React from 'react';
import { RemoteActions } from '../../remote/remote-actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux'
import ItemsList from '../../components/generic/items-list';
import { Slot, Question, QuestionGroup } from '../../models/models';
import DynamicForm from '../../components/generic/dynamic-form/dynamic-form';




interface questionScreenProps {
    dispatch: Dispatch,
    question: Question[];
    questionGroup: QuestionGroup[];
    refesh: string;
}

const QuestionScreen: React.FC<any> = ({ dispatch, question, questionGroup, refesh }: questionScreenProps) => {

    React.useEffect(() => {
        RemoteActions.getQuestionGroup(dispatch)
        RemoteActions.getQuestion(dispatch)
    }, [])


    return (
        <div className="container-fluid">
            <h1>Question</h1>
            <div style={{ opacity: 0.0 }}>{refesh}</div>
            <ItemsList
                style={{}}
                onSelectItem={() => { }}
                onEditItem={(question: Question) => {
                    return RemoteActions.setQuestion(question, dispatch)
                }}
                dataTemplate={{
                    question: '',
                }}
                onDeleteItem={(question: Question) => {
                    return RemoteActions.deleteQuestion(question.id, dispatch)
                }}
                listTemplate={(question: Question) => {
                    return (
                        <div>
                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Question</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${question.question}`}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Group</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${question.questionGroup.group}`}</span>
                                </div>
                            </div>
                        </div>
                    )
                }}
                newItemFormTemplate={({ item, editing, onChange }: { item: Question, editing: boolean, onChange: any }) => {
                    const labels: any = {
                        question: 'Question Text',
                        questionGroup: 'Question Group Name',
                        responseType: 'Response Type'
                    }
                    return (
                        <div>
                            <DynamicForm
                                data={item}
                                labels={labels}
                                editable={editing}
                                onDataChange={(data: any) => {
                                    onChange(data)
                                }}
                            />
                            <div style={{ borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid', padding: 10, margin: 10 }}>
                                <div style={{ fontWeight: 'bold', padding: 15 }}>Question Group</div>
                                {questionGroup.length && !item.questionGroup ? onChange({ questionGroup: questionGroup[0] }) : null}
                                <select id="combo" value={item.questionGroup ? JSON.stringify(item.questionGroup) : undefined} onChange={(event) => {
                                    onChange({ questionGroup: JSON.parse(event.target.value) })
                                }}>
                                    {questionGroup.map((element: QuestionGroup, index: number) => {
                                        return <option key={`${index}`} value={JSON.stringify(element)}>{element.group}</option>
                                    })}
                                </select>
                            </div>

                            <div style={{ borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid', padding: 10, margin: 10 }}>
                                <div style={{ fontWeight: 'bold', padding: 15 }}>Question Response Type</div>
                                {!item.responseType ? onChange({ responseType: 'text' }) : null}
                                <select id="combo2" value={item.responseType} onChange={(event) => {
                                    onChange({ responseType: event.target.value })
                                }}>
                                    <option value={'text'}>Text</option>
                                    <option value={'check'}>Check</option>
                                </select>
                            </div>


                        </div>
                    )
                }}
                list={question} />
        </div>
    );
};


const mapStateToProps = (state: any) => {
    const reducer = {
        question: state.question.question,
        questionGroup: state.questionGroup.questionGroup,
        refesh: `${Date.now()}`,
    }
    return reducer
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(QuestionScreen);

const styles = ({
    container: {}
});
