import * as React from 'react';
import { lang, selctedLang } from '../../lang';
import RemoteActions from '../../remote/remote-actions';
import SelectionModal from './Modal';
import { Dispatch } from 'redux';
import ButtonWithFeedback from '../generic/buttonWithFeedback';
import { Message } from '../../models/models';

interface RemoveModalProps {
    show: boolean;
    message: Message;
    handleClose?: () => void;
    dispatch: Dispatch;
}

const RemoveMessageModal = ({ dispatch, show, message, handleClose }: RemoveModalProps) => {

    const Render = () => {

        return (
            <div style={{}}>

                <SelectionModal handleClose={handleClose} show={show} Content={() => {
                    return (
                        <div style={{ display: 'flex', marginBottom: 8, flexDirection: 'column', padding: 5, paddingTop: 20 }}>

                            <div style={{ textAlign: 'center', paddingBottom: 20 }}>
                                {lang[selctedLang].removeConfirmation}
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 5, }}>
                                <div className='btHorizontal btCANCEL' onClick={handleClose}>
                                    {lang[selctedLang].no}
                                </div>

                                <ButtonWithFeedback
                                    onPress={async () => {
                                        return new Promise(async (resolve, reject) => {
                                            try {
                                                await RemoteActions.deleteMessage(message, dispatch)
                                                handleClose!()
                                                resolve()
                                            } catch (error) {
                                                reject()
                                            }
                                        })
                                    }}
                                    text={lang[selctedLang].yes}
                                    className='btHorizontal btREMOVE'
                                />
                            </div>
                        </div>
                    )
                }} />
            </div>
        )
    };


    return Render()
};

export default (RemoveMessageModal);

