
import { QuestionAnswer } from "../../../models/models"
import { ACTIONS_ANSWER } from "../../actions/check-form/answer"

interface IState {
    answer: QuestionAnswer[],
    error: string
}

export const initialState: IState = { answer: [], error: '' }

export const answer = (state = initialState, action: any) => {
    switch (action.type) {
        case ACTIONS_ANSWER.ADD_ANSWER_SUCCESS:
            
            const exists = state.answer.findIndex(x => x.id===action.data.id)
            if(exists > -1){
                state.answer[exists] = action.data as QuestionAnswer
            }else{
                state.answer.push(action.data as QuestionAnswer)
            }

            return {
                ...state,
            }
        case ACTIONS_ANSWER.ADD_ANSWER_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_ANSWER.DELETE_ANSWER_SUCCESS:
            const answer = state.answer.filter((answer: QuestionAnswer)=> answer.id !== action.data.id)
            return {
                ...state,
                answer
            }
        case ACTIONS_ANSWER.DELETE_ANSWER_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_ANSWER.GET_ANSWER_SUCCESS:
            return {
                ...state,
                answer: action.data
            }
        case ACTIONS_ANSWER.GET_ANSWER_FAILURE:
            return {
                ...state,
                error: action.data
            }
        default:
            return state
    }
}

export default answer