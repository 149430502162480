import { ACTIONS_MESSAGES } from "../actions/messages"
import { Message, Vessel } from "../../models/models"

interface IState {
    messages: Message[],
    error: string
}

export const initialState: IState = {
    messages: [],
    error: ''
}

export const messages = (state = initialState, action: any) => {
    const message = action.data as Message

    switch (action.type) {
        case ACTIONS_MESSAGES.ADD_MESSAGES_SUCCESS:

            if (message.parent) {
                const exists = state.messages.findIndex(x => x.id === message.parent!.id) // parent index
                const parent = state.messages[exists]

                if (parent.replays) {
                    parent.replays.push(action.data as Message)
                } else {
                    parent.replays = [action.data as Message]
                }

            } else {

                const exists = state.messages.findIndex(x => x.id === action.data.id)
                if (exists > -1) {
                    state.messages[exists] = action.data as Message
                } else {
                    state.messages.push(action.data as Message)
                }

            }
            return {
                ...state,
            }
        case ACTIONS_MESSAGES.ADD_MESSAGES_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_MESSAGES.DELETE_MESSAGES_SUCCESS:

            if (message.parent) {
                const exists = state.messages.findIndex(x => x.id === message.parent!.id) // parent index
                const parent = state.messages[exists]
                parent.replays = parent.replays!.filter((message: Message) => message.id !== action.data.id)
            } else {
                const messages = state.messages.filter((message: Message) => message.id !== action.data.id)
                return {
                    ...state,
                    messages
                }
            }
        case ACTIONS_MESSAGES.DELETE_MESSAGES_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_MESSAGES.GET_MESSAGES_SUCCESS:
            return {
                ...state,
                messages: action.data
            }
        case ACTIONS_MESSAGES.GET_MESSAGES_FAILURE:
            return {
                ...state,
                error: action.data
            }
        default:
            return state
    }
}

export default messages