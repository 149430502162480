import * as React from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux'
import { Message, User } from '../../../models/models';
import RemoteActions from '../../../remote/remote-actions';
import ItemsList from '../../../components/generic/items-list';
import DynamicForm from '../../../components/generic/dynamic-form/dynamic-form';




interface MessagesScreenProps {
    dispatch: Dispatch,
    user: User,
    messages: Message[],
    date: Date,
    refesh: string,
}

const MessagesScreen: React.FC<any> = ({ dispatch, messages, refesh, date, user }: MessagesScreenProps) => {

    React.useEffect(() => {
        RemoteActions.getMessages(dispatch)
    }, [])


    return (
        <div className="container-fluid">
            <h1>Message Board</h1>
            <div style={{ opacity: 0.0 }}>{refesh}</div>
            <ItemsList
                style={{}}
                onSelectItem={() => { }}
                onEditItem={(message: Message) => {
                    message.date = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}, ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
                    message.user = user
                    return RemoteActions.setMessage(message, dispatch)
                }}
                dataTemplate={{
                    message: '',
                }}
                onDeleteItem={(message: Message) => {
                    return RemoteActions.deleteMessage(message, dispatch)
                }}
                listTemplate={(message: Message) => {
                    return (
                        <div>
                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Name</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${message.user.firstName} ${message.user.lastName}`}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">date</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${message.date}`}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Message</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${message.message}`}</span>
                                </div>
                            </div>
                        </div>
                    )
                }}
                newItemFormTemplate={({ item, editing, onChange }: { item: Message, editing: boolean, onChange: any }) => {
                    const labels: any = {
                        user: 'User',
                        date: 'Date',
                        message: 'Message',
                    }
                    return (
                        <DynamicForm
                            exclude={{date:''}}
                            data={item}
                            labels={labels}
                            editable={editing}
                            onDataChange={(data: any) => {
                                onChange(data)
                            }}
                        />
                    )
                }}
                list={messages} />
        </div>
    );
};


const mapStateToProps = (state: any) => {
    const reducer = {
        messages: state.messages.messages,
        user: state.login.user,
        date: state.reservations.date,
        refesh: `${Date.now()}`,
    }
    return reducer
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(MessagesScreen);

const styles = ({
    container: {}
});
