import { ACTIONS_USERS } from "../actions/users"
import { User } from "../../models/models"

interface IState {
    users: User[],
    error: string
}

export const initialState: IState = { users: [], error: '' }

export const users = (state = initialState, action: any) => {
    switch (action.type) {
        case ACTIONS_USERS.ADD_USERS_SUCCESS:

            const exists = state.users.findIndex(x => x.id === action.data.id)
            if (exists > -1) {
                state.users[exists] = action.data as User
            } else {
                state.users.push(action.data as User)
            }

            return {
                ...state,
            }
        case ACTIONS_USERS.ADD_USERS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_USERS.DELETE_USERS_SUCCESS:
            const users = state.users.filter((users: User) => users.id !== action.data.id)
            return {
                ...state,
                users
            }
        case ACTIONS_USERS.DELETE_USERS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case ACTIONS_USERS.GET_USERS_SUCCESS:
            return {
                ...state,
                users: action.data
            }
        case ACTIONS_USERS.GET_USERS_FAILURE:
            return {
                ...state,
                error: action.data
            }
        default:
            return state
    }
}

export default users