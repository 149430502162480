import * as React from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux'
import { Message, User } from '../../../models/models';
import RemoteActions from '../../../remote/remote-actions';
import ItemsList from '../../../components/generic/items-list';
import DynamicForm from '../../../components/generic/dynamic-form/dynamic-form';
import { Row, Col, Container, Image, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import { themeColor } from '../../../utils/calendar-utils';
import RemoveMessageModal from '../../../components/modal/RemoveMessageModal';
import AddMessageModal from '../../../components/modal/AddMessageModal';
import { getImage } from '../../../remote/api';
import { UserRoles } from '../../../utils/utils';


interface UserMessagesScreenProps {
    dispatch: Dispatch,
    user: User,
    messages: Message[],
    date: Date,
    refesh: string,
}

const UserMessagesScreen: React.FC<any> = ({ dispatch, messages, refesh, date, user }: UserMessagesScreenProps) => {
    const [selectedMessage, setSMessage] = React.useState<Message>()
    const [showRemove, setShowRemove] = React.useState<boolean>(false)
    const [showAdd, setShowAdd] = React.useState<boolean>(false)

    React.useEffect(() => {
        RemoteActions.getMessages(dispatch)
    }, [])

    const NewlineText = (text: string) => {
        const newText = text.split('\n').map(str => <div>{str}</div>);
        return newText;
    }

    const MessagesList = (list: Message[]) => {
        return list.map((message: Message) => {
            return (
                <div key={`comment-${message.id}`} className="card" style={{ marginTop: 30, maxWidth: 600, marginLeft: 'auto', marginRight: 'auto' }}>
                    <div className="card-header" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div style={{ color: themeColor, fontWeight: 'bold' }}>{`${message.user.firstName.toUpperCase()} ${message.user.lastName.toUpperCase()}`}</div>
                        <div style={{ color: 'gray', fontSize: 10, }}>{`${message.date}`}</div>
                    </div>

                    <div className="card-body" >
                        {message.image && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Image style={{ height: '60%', maxWidth: '100%' }} src={getImage(message.image.url)} rounded />
                        </div>}
                        <div>{NewlineText(message.message)}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>

                        {(message.user.id === user.id || user.role === UserRoles.ADMIN) && (<Button style={{ backgroundColor: 'transparent', width: 30, display: 'flex', justifyContent: 'center', margin: 5, borderColor: 'transparent' }} onClick={() => {
                            setSMessage(message)
                            setShowRemove(true)
                        }}>
                            <FontAwesomeIcon icon={faTrashAlt} style={{ color: 'red' }} />
                        </Button>)}

                        <Button style={{ backgroundColor: 'transparent', width: 30, display: 'flex', justifyContent: 'center', margin: 5, borderColor: 'transparent' }} onClick={() => {
                            setSMessage({
                                message: '',
                                user,
                                date: '',
                                likes: 0,
                                parent: message
                            })
                            setShowAdd(true)
                        }}>
                            <FontAwesomeIcon icon={faComment} style={{ color: themeColor }} />
                        </Button>
                    </div>

                    {message.replays && message.replays.map((comment: Message) => (
                        <div key={`comment-${message.id}-reply-${comment.id}`} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', margin: 5, }}>
                            <div className="card" key={comment.id!} style={{ maxWidth: 450, borderColor: 'transparent' }}>
                                <div className="card-header" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', borderColor: 'transparent', backgroundColor: 'transparent' }}>
                                    <div style={{ color: themeColor, fontWeight: 'bold', fontSize: 10, }}>{`${comment.user.firstName.toUpperCase()} ${comment.user.lastName.toUpperCase()}`}</div>
                                    <div style={{ color: 'gray', fontSize: 10, }}>{`${comment.date}`}</div>
                                </div>

                                <div className="card-body" >
                                    {comment.image && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                        <Image style={{ height: 350 }} src={getImage(comment.image.url)} />
                                    </div>}
                                    <div>{NewlineText(comment.message)}</div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                    {(comment.user.id === user.id || user.role === UserRoles.ADMIN) && (<Button style={{ backgroundColor: 'transparent', width: 30, display: 'flex', justifyContent: 'center', margin: 5, borderColor: 'transparent' }} onClick={() => {
                                        setSMessage(comment)
                                        setShowRemove(true)
                                    }}>
                                        <FontAwesomeIcon icon={faTrashAlt} style={{ color: 'red' }} />
                                    </Button>)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )
        })
    }

    return (
        <Container style={styles.container}>
            <div style={{ position: 'fixed', bottom: 60, right: 0, zIndex: 1000 }}>
                <Button style={{ width: 40, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5, borderColor: themeColor, backgroundColor: 'lightgrey' }} onClick={() => {
                    setSMessage({
                        message: '',
                        user,
                        date: '',
                        likes: 0,
                    })
                    setShowAdd(true)
                }}>
                    <FontAwesomeIcon icon={faComment} style={{ color: themeColor, fontSize: 20 }} />
                </Button>
            </div>
            {MessagesList(messages)}
            {selectedMessage && <RemoveMessageModal message={selectedMessage} show={showRemove} dispatch={dispatch} handleClose={() => {
                setShowRemove(false)
            }} />}

            <AddMessageModal show={showAdd} message={selectedMessage!} dispatch={dispatch} handleClose={() => {
                setShowAdd(false)
            }} />
        </Container>
    );
};


const mapStateToProps = (state: any) => {
    const reducer = {
        messages: state.messages.messages,
        user: state.login.user,
        date: state.reservations.date,
        refesh: `${Date.now()}`,
    }
    return reducer
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(UserMessagesScreen);

const styles = ({
    container: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: 20,
        paddingRight: 20,
    }
});
