export enum ACTIONS_SKIPPER_ACCOUNT {
    ADD_SKIPPER_ACCOUNT_SUCCESS = 'ADD_SKIPPER_ACCOUNT_SUCCESS',
    ADD_SKIPPER_ACCOUNT_FAILURE = 'ADD_SKIPPER_ACCOUNT_FAILURE',

    GET_SKIPPER_ACCOUNT_SUCCESS = 'GET_SKIPPER_ACCOUNT_SUCCESS',
    GET_SKIPPER_ACCOUNT_FAILURE = 'GET_SKIPPER_ACCOUNT_FAILURE',

    DELETE_SKIPPER_ACCOUNT_SUCCESS = 'DELETE_SKIPPER_ACCOUNT_SUCCESS',
    DELETE_SKIPPER_ACCOUNT_FAILURE = 'DELETE_SKIPPER_ACCOUNT_FAILURE',
}

export const ADD_SKIPPER_ACCOUNT_SUCCESS = (data:any) => ({
    type: ACTIONS_SKIPPER_ACCOUNT.ADD_SKIPPER_ACCOUNT_SUCCESS,
    data
})

export const ADD_SKIPPER_ACCOUNT_FAILURE = (e:any) => ({
    type: ACTIONS_SKIPPER_ACCOUNT.ADD_SKIPPER_ACCOUNT_FAILURE,
    error: e
})

export const DELETE_SKIPPER_ACCOUNT_SUCCESS = (data:any) => ({
    type: ACTIONS_SKIPPER_ACCOUNT.DELETE_SKIPPER_ACCOUNT_SUCCESS,
    data
})

export const DELETE_SKIPPER_ACCOUNT_FAILURE = (e:any) => ({
    type: ACTIONS_SKIPPER_ACCOUNT.DELETE_SKIPPER_ACCOUNT_FAILURE,
    error: e
})


export const GET_SKIPPER_ACCOUNT_SUCCESS = (data:any) => ({
    type: ACTIONS_SKIPPER_ACCOUNT.GET_SKIPPER_ACCOUNT_SUCCESS,
    data
})

export const GET_SKIPPER_ACCOUNT_FAILURE = (e:any) => ({
    type: ACTIONS_SKIPPER_ACCOUNT.GET_SKIPPER_ACCOUNT_FAILURE,
    error: e
})