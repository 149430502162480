import * as React from 'react';
import { RemoteActions } from '../../remote/remote-actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux'
import ItemsList from '../../components/generic/items-list';
import { Slot, QuestionGroup } from '../../models/models';
import DynamicForm from '../../components/generic/dynamic-form/dynamic-form';




interface QuestionGroupScreenProps {
    dispatch: Dispatch,
    questionGroup: QuestionGroup[];
    refesh: string;
}

const QuestionGroupScreen: React.FC<any> = ({ dispatch, questionGroup, refesh }: QuestionGroupScreenProps) => {

    React.useEffect(() => {
        RemoteActions.getQuestionGroup(dispatch)
    }, [])


    return (
        <div className="container-fluid">
            <h1>Question Group</h1>
            <div style={{ opacity: 0.0 }}>{refesh}</div>
            <ItemsList
                style={{}}
                onSelectItem={() => { }}
                onEditItem={(questionGroup: QuestionGroup) => {
                    return RemoteActions.setQuestionGroup(questionGroup, dispatch)
                }}
                dataTemplate={{
                    group: '',
                }}
                onDeleteItem={(questionGroup: QuestionGroup) => {
                    return RemoteActions.deleteQuestionGroup(questionGroup.id, dispatch)
                }}
                listTemplate={(questionGroup: QuestionGroup) => {
                    return (
                        <div>
                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Group</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${questionGroup.group}`}</span>
                                </div>
                            </div>
                        </div>
                    )
                }}
                newItemFormTemplate={({ item, editing, onChange }: { item: QuestionGroup, editing: boolean, onChange: any }) => {
                    const labels: QuestionGroup = {
                        group: 'Question Group Name',
                    }
                    return (
                        <DynamicForm
                            data={item}
                            labels={labels}
                            editable={editing}
                            onDataChange={(data: any) => {
                                onChange(data)
                            }}
                        />
                    )
                }}
                list={questionGroup} />
        </div>
    );
};


const mapStateToProps = (state: any) => {
    const reducer = {
        questionGroup: state.questionGroup.questionGroup,
        refesh: `${Date.now()}`,
    }
    return reducer
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(QuestionGroupScreen);

const styles = ({
    container: {}
});
