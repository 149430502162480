export enum ACTIONS_HOLIDAYS {
    ADD_HOLIDAYS_SUCCESS = 'ADD_HOLIDAYS_SUCCESS',
    ADD_HOLIDAYS_FAILURE = 'ADD_HOLIDAYS_FAILURE',

    GET_HOLIDAYS_SUCCESS = 'GET_HOLIDAYS_SUCCESS',
    GET_HOLIDAYS_FAILURE = 'GET_HOLIDAYS_FAILURE',

    DELETE_HOLIDAYS_SUCCESS = 'DELETE_HOLIDAYS_SUCCESS',
    DELETE_HOLIDAYS_FAILURE = 'DELETE_HOLIDAYS_FAILURE',
}

export const ADD_HOLIDAYS_SUCCESS = (data:any) => ({
    type: ACTIONS_HOLIDAYS.ADD_HOLIDAYS_SUCCESS,
    data
})

export const ADD_HOLIDAYS_FAILURE = (e:any) => ({
    type: ACTIONS_HOLIDAYS.ADD_HOLIDAYS_FAILURE,
    error: e
})

export const DELETE_HOLIDAYS_SUCCESS = (data:any) => ({
    type: ACTIONS_HOLIDAYS.DELETE_HOLIDAYS_SUCCESS,
    data
})

export const DELETE_HOLIDAYS_FAILURE = (e:any) => ({
    type: ACTIONS_HOLIDAYS.DELETE_HOLIDAYS_FAILURE,
    error: e
})


export const GET_HOLIDAYS_SUCCESS = (data:any) => ({
    type: ACTIONS_HOLIDAYS.GET_HOLIDAYS_SUCCESS,
    data
})

export const GET_HOLIDAYS_FAILURE = (e:any) => ({
    type: ACTIONS_HOLIDAYS.GET_HOLIDAYS_FAILURE,
    error: e
})