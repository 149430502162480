import * as React from 'react';
import { RemoteActions } from '../../remote/remote-actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux'
import ItemsList from '../../components/generic/items-list';
import { Holiday } from '../../models/models';
import DynamicForm from '../../components/generic/dynamic-form/dynamic-form';
import { validDateRange, validMonthDayDateRange } from '../../utils/utils';




interface HolidaysScreenProps {
    dispatch: Dispatch,
    holidays: Holiday[];
    refesh: string;
}

const HolidaysScreen: React.FC<any> = ({ dispatch, holidays, refesh }: HolidaysScreenProps) => {

    React.useEffect(() => {
        //RemoteActions.getHoliday(dispatch)
    }, [])

    return (
        <div className="container-fluid">
            <h1>Holidays</h1>
            <div style={{ opacity: 0.0 }}>{refesh}</div>
            <ItemsList
                style={{}}
                onSelectItem={() => { }}
                onEditItem={(holiday: Holiday) => {
                    return RemoteActions.setHoliday(holiday, dispatch)
                }}
                dataTemplate={{
                    name: '',
                    date: '',
                    description: '',
                    allYears: false,
                }}
                onDeleteItem={(holiday: Holiday) => {
                    return RemoteActions.deleteHoliday(holiday.id, dispatch)
                }}
                listTemplate={(holiday: Holiday) => {
                    return (
                        <div>
                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Name</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${holiday.name}`}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Date</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${holiday.date}`}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-2">
                                    <span className="font-weight-bold">Description</span>
                                </div>
                                <div className="col-md-10">
                                    <span className="">{`${holiday.description}`}</span>
                                </div>
                            </div>

                        </div>
                    )
                }}
                newItemFormTemplate={({ item, editing, onChange }: { item: Holiday, editing: boolean, onChange: any }) => {
                    const labels: any = {
                        name: 'Name',
                        date: 'Date',
                        description: 'description',
                        allYears: 'Holiday on same date all years'
                    }
                    let error: any;
                    if (item.date) {
                        const validDate = item.allYears ? validMonthDayDateRange(item.date) : validDateRange(item.date)
                        if (!validDate) {
                            error = item.allYears ? 'Invalid date. format "MM/DD"' : 'Invalid date. format "YYYY/MM/DD"'
                        } else {
                            error = ''
                        }
                    }
                    return (
                        <div>

                            <DynamicForm
                                data={item}
                                labels={labels}
                                editable={editing}
                                onDataChange={(data: any) => {
                                    onChange(data)
                                }}
                            />
                            {error && error !== '' && <div className="alert alert-danger" role="alert">
                                {error}
                            </div>}
                        </div>
                    )
                }}
                list={holidays} />
        </div>
    );
};


const mapStateToProps = (state: any) => {
    const reducer = {
        holidays: state.holiday.holiday,
        refesh: `${Date.now()}`,
    }
    return reducer
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(HolidaysScreen);

const styles = ({
    container: {}
});
