export enum ACTIONS_SPLIT_SLOTS {
    ADD_SPLIT_SLOTS_SUCCESS = 'ADD_SPLIT_SLOTS_SUCCESS',
    ADD_SPLIT_SLOTS_FAILURE = 'ADD_SPLIT_SLOTS_FAILURE',

    GET_SPLIT_SLOTS_SUCCESS = 'GET_SPLIT_SLOTS_SUCCESS',
    GET_SPLIT_SLOTS_FAILURE = 'GET_SPLIT_SLOTS_FAILURE',

    DELETE_SPLIT_SLOTS_SUCCESS = 'DELETE_SPLIT_SLOTS_SUCCESS',
    DELETE_SPLIT_SLOTS_FAILURE = 'DELETE_SPLIT_SLOTS_FAILURE',
}

export const ADD_SPLIT_SLOTS_SUCCESS = (data:any) => ({
    type: ACTIONS_SPLIT_SLOTS.ADD_SPLIT_SLOTS_SUCCESS,
    data
})

export const ADD_SPLIT_SLOTS_FAILURE = (e:any) => ({
    type: ACTIONS_SPLIT_SLOTS.ADD_SPLIT_SLOTS_FAILURE,
    error: e
})

export const DELETE_SPLIT_SLOTS_SUCCESS = (data:any) => ({
    type: ACTIONS_SPLIT_SLOTS.DELETE_SPLIT_SLOTS_SUCCESS,
    data
})

export const DELETE_SPLIT_SLOTS_FAILURE = (e:any) => ({
    type: ACTIONS_SPLIT_SLOTS.DELETE_SPLIT_SLOTS_FAILURE,
    error: e
})


export const GET_SPLIT_SLOTS_SUCCESS = (data:any) => ({
    type: ACTIONS_SPLIT_SLOTS.GET_SPLIT_SLOTS_SUCCESS,
    data
})

export const GET_SPLIT_SLOTS_FAILURE = (e:any) => ({
    type: ACTIONS_SPLIT_SLOTS.GET_SPLIT_SLOTS_FAILURE,
    error: e
})