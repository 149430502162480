export enum ACTIONS_ANSWER {
    ADD_ANSWER_SUCCESS = 'ADD_ANSWER_SUCCESS',
    ADD_ANSWER_FAILURE = 'ADD_ANSWER_FAILURE',

    GET_ANSWER_SUCCESS = 'GET_ANSWER_SUCCESS',
    GET_ANSWER_FAILURE = 'GET_ANSWER_FAILURE',

    DELETE_ANSWER_SUCCESS = 'DELETE_ANSWER_SUCCESS',
    DELETE_ANSWER_FAILURE = 'DELETE_ANSWER_FAILURE',
}

export const ADD_ANSWER_SUCCESS = (data:any) => ({
    type: ACTIONS_ANSWER.ADD_ANSWER_SUCCESS,
    data
})

export const ADD_ANSWER_FAILURE = (e:any) => ({
    type: ACTIONS_ANSWER.ADD_ANSWER_FAILURE,
    error: e
})

export const DELETE_ANSWER_SUCCESS = (data:any) => ({
    type: ACTIONS_ANSWER.DELETE_ANSWER_SUCCESS,
    data
})

export const DELETE_ANSWER_FAILURE = (e:any) => ({
    type: ACTIONS_ANSWER.DELETE_ANSWER_FAILURE,
    error: e
})


export const GET_ANSWER_SUCCESS = (data:any) => ({
    type: ACTIONS_ANSWER.GET_ANSWER_SUCCESS,
    data
})

export const GET_ANSWER_FAILURE = (e:any) => ({
    type: ACTIONS_ANSWER.GET_ANSWER_FAILURE,
    error: e
})