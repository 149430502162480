import * as React from 'react';
import { RemoteActions } from '../../remote/remote-actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux'
import ItemsList from '../../components/generic/items-list';
import { SkipperAccount, Slot, User } from '../../models/models';
import DynamicForm from '../../components/generic/dynamic-form/dynamic-form';
import SwitchList from '../../components/generic/switch-list';
import { UserRoles } from '../../utils/utils';
import { Col, Row } from 'react-bootstrap';
import skipperAccounts from '../../redux/reduceres/skipper-account';

interface SkipperAccountScreenProps {
    dispatch: Dispatch;
    skippers: SkipperAccount[];
    users: User[];
    refesh: string;
}


const SkipperAccountScreen: React.FC<SkipperAccountScreenProps> = ({ dispatch, skippers, users, refesh }: SkipperAccountScreenProps) => {

    React.useEffect(() => {
        RemoteActions.getSkipperAccount(dispatch)
        RemoteActions.getUsers(dispatch)
    }, [])

    const handleChange = ({ user, skipper }: { user: User, skipper: SkipperAccount | undefined }) => {
        if (skipper) {
            RemoteActions.deleteSkipperAccount(skipper.id, dispatch)
        } else {
            RemoteActions.setSkipperAccount(user, dispatch)
        }
    }

    const row = ({ skipper, user }: { skipper: SkipperAccount[], user: User }) => (
        <Row key={`${user.id}`}>
            <Col md={3}>{user.email}</Col>
            <Col><input type="checkbox" checked={skipper.length > 0} onChange={(event: any) => handleChange({ user, skipper: skipper.length ? skipper[0] : undefined })} /></Col>
        </Row>
    )

    const Render = ({ skippers, users }: { skippers: SkipperAccount[], users: User[] }) => {

        const accounts = users.map((user: User) =>
            row({ user, skipper: skippers.filter((skipper: SkipperAccount) => skipper.user.id === user.id) })
        )
        return (<Col style={{marginLeft: 30}}>{accounts}</Col>)
    }

    return (<Render skippers={skippers} users={users} />);

};


const mapStateToProps = (state: any) => {
    const users = state.users.users.filter((user: User) => user.role === UserRoles.SKIPPER)
    const reducer = {
        skippers: state.skipperAccounts.skipperAccounts,
        users,
        refesh: `${Date.now()}`,
    }

    return reducer
}

export default connect(mapStateToProps)(SkipperAccountScreen);

const styles = ({
    container: {}
});
