import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { User, BookingRules, Slot, Vessel, Reservation, QuestionAnswer, WaitingList, SplitSlot, Holiday, SkipperAvailability, SkipperAccount } from '../../models/models';
import { Row, Container, Col } from 'react-bootstrap';
import { getWeekday, weekDay, isHoliday } from '../../utils/calendar-utils';
import { sortSlots } from '../../utils/utils';
import RemoteActions from '../../remote/remote-actions';
import SkipperCalendar from '../skipperCalendar';
import { AVAILABLE, RESERVED } from '../../components/calendar/slot-types/slot-type-interface';

import SlotAvailableSkipper from '../../components/calendar/slot-types/slots/skipper/slot-available';
import SlotReservedSkipper from '../../components/calendar/slot-types/slots/skipper/slot-reserved';


interface SkipperCalendarScreenProps {
    user: User;
    answers: QuestionAnswer[];
    waitingList: WaitingList[];
    splitSlots: SplitSlot[];
    holidays: Holiday[],
    skipperAvailability: SkipperAvailability[];
    skippers: SkipperAccount[];
    dispatch: Dispatch;
}

const slotsTypes: { [k: string]: any } = {
    [AVAILABLE]: SlotAvailableSkipper,
    [RESERVED]: SlotReservedSkipper
}

class SkipperCalendarScreen extends React.Component<SkipperCalendarScreenProps, { [k: string]: any }> {

    constructor(props: SkipperCalendarScreenProps) {
        super(props)

        this.state = {
            membership: undefined,
        }
    }

    currentYear = new Date().getFullYear()

    componentDidMount() {
        RemoteActions.getVessel(this.props.dispatch)
        RemoteActions.getSplitSlot(this.props.dispatch)
        RemoteActions.getAnswer(this.props.dispatch)
        RemoteActions.getHoliday(this.props.dispatch, this.currentYear)
        RemoteActions.getSkipperAccount(this.props.dispatch)
        RemoteActions.getSkipperAvailability(this.props.dispatch)
    }



    render() {

        const skipper: SkipperAccount = this.props.skippers.filter((skipper: SkipperAccount) => skipper.user.id === this.props.user.id)[0]


        return (
            <Container>

                {/* 
                {!this.state.membership &&
                    <div className="d-flex justify-content-center" style={{ textAlign: 'center' }}>
                        <Row>
                            <h3>Loading</h3>
                        </Row>

                        <Row style={{ marginLeft: 25 }}>
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </Row>
                    </div>} */}



                <Row>
                    <Col>


                        {skipper && <SkipperCalendar
                            skipper={skipper}
                            skipperAvailability={this.props.skipperAvailability}
                            user={this.props.user}
                            getSlots={(day: number, month: number, year: number, bookingRules: BookingRules, vessel: Vessel, skipperAvailability: SkipperAvailability[], reservations: Reservation[]) => {


                                if (!bookingRules)
                                    return

                                if (this.currentYear !== year) {
                                    this.currentYear = year

                                    RemoteActions.getHoliday(this.props.dispatch, this.currentYear)
                                }

                                const dayDate = new Date(year, month, day)
                                const dayOfWeek = getWeekday((dayDate).getDay())

                                const isTodayHoliday = isHoliday(day, month, year, this.props.holidays)
                                const dayType = (dayOfWeek === weekDay[0] || dayOfWeek === weekDay[6] || isTodayHoliday) ? 'WEEK_END' : 'WORKING_DAY'
                                const slotTypes = {
                                    WORKING_DAY: sortSlots(bookingRules.beforeWeekend),
                                    WEEK_END: sortSlots(bookingRules.ofWeekend)
                                }

                                return (

                                    slotTypes[dayType].flatMap((slot: Slot) => {

                                        const isSlotSplit: SplitSlot[] = this.props.splitSlots.filter((splitSlot: SplitSlot) => slot.range === splitSlot.slot && splitSlot.date === `${year}-${month}-${day}` && vessel.id === splitSlot.vessel.id)

                                        if (isSlotSplit.length) {
                                            const splitSlot: Slot[] = [
                                                {
                                                    id: isSlotSplit[0].id,
                                                    name: slot.name,
                                                    range: isSlotSplit[0].firstSlotRange,
                                                    original: slot.range,
                                                },
                                                {
                                                    id: isSlotSplit[0].id,
                                                    name: slot.name,
                                                    range: isSlotSplit[0].secondSlotRange,
                                                    original: slot.range,
                                                }
                                            ]
                                            const isAvailable = skipperAvailability!.filter((reservation: SkipperAvailability) => {

                                                return (
                                                    skipper && reservation.skipper.id === skipper.id &&
                                                    reservation.date === `${year}-${month}-${day}` &&
                                                    (reservation.slot === isSlotSplit[0].firstSlotRange || reservation.slot === isSlotSplit[0].secondSlotRange) &&
                                                    reservation.vessel.id === vessel.id
                                                )
                                            })

                                            const isReserved = reservations!.filter((reservation: Reservation) => (
                                                reservation.date === `${year}-${month}-${day}` &&
                                                (reservation.slot === isSlotSplit[0].firstSlotRange || reservation.slot === isSlotSplit[0].secondSlotRange) &&
                                                reservation.vessel.id === vessel.id &&
                                                !reservation.disable
                                            ))

                                            let SlotUI = slotsTypes[AVAILABLE]
                                            if (isReserved.length || isAvailable.length) {
                                                SlotUI = slotsTypes[RESERVED]
                                            }

                                            return splitSlot.flatMap((_slot: Slot, index: number) => (
                                                <SlotUI
                                                    key={`${slot.id}-${year}-${month}-${day}-${index}`}
                                                    user={this.props.user}
                                                    vessel={vessel!}
                                                    membership={this.state.membership}
                                                    slot={_slot}
                                                    day={day}
                                                    month={month}
                                                    year={year}
                                                    holidays={this.props.holidays}
                                                    bookingRules={bookingRules}
                                                    answers={this.props.answers}
                                                    waitingList={this.props.waitingList}
                                                    canRemoveSplit={!isReserved.length}
                                                    dayType={dayType}
                                                    skipper={skipper}
                                                    skipperAvailability={isAvailable[0]}
                                                    reservation={isReserved[0]}
                                                    dispatch={this.props.dispatch}
                                                />
                                            ))
                                        }
                                        const isAvailable = skipperAvailability!.filter((reservation: SkipperAvailability) => {

                                            return (
                                                skipper && reservation.skipper.id === skipper.id &&
                                                reservation.date === `${year}-${month}-${day}` &&
                                                (reservation.slot === slot.range) &&
                                                reservation.vessel.id === vessel.id
                                            )
                                        })

                                        const isReserved = reservations!.filter((reservation: Reservation) => (
                                            reservation.date === `${year}-${month}-${day}` &&
                                            (reservation.slot === slot.range) &&
                                            reservation.vessel.id === vessel.id &&
                                            !reservation.disable
                                        ))

                                        let SlotUI = slotsTypes[AVAILABLE]
                                        if (isReserved.length || isAvailable.length) {
                                            SlotUI = slotsTypes[RESERVED]
                                        }

                                        return (
                                            <SlotUI
                                                key={`${slot.id}-${year}-${month}-${day}`}
                                                user={this.props.user}
                                                vessel={vessel!}
                                                membership={this.state.membership}
                                                slot={slot}
                                                day={day}
                                                month={month}
                                                year={year}
                                                holidays={this.props.holidays}
                                                bookingRules={bookingRules}
                                                answers={this.props.answers}
                                                waitingList={this.props.waitingList}
                                                dayType={dayType}
                                                skipper={skipper}
                                                skipperAvailability={isAvailable[0]}
                                                reservation={isReserved[0]}
                                                dispatch={this.props.dispatch}
                                            />
                                        )
                                    })
                                )
                            }}
                        />}

                    </Col>
                </Row>

            </Container >
        );
    }
};


/**
 * controlles login state
 * @param state 
 */
const mapStateToProps = (state: any) => {
    return {
        user: state.login.user,
        answers: state.answer.answer,
        waitingList: state.waitingList.waitingList,
        splitSlots: state.splitSlot.splitSlot,
        holidays: state.holiday.holiday,
        skippers: state.skipperAccounts.skipperAccounts,
        skipperAvailability: state.skipperAvailability.skipperAvailability,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(SkipperCalendarScreen);

