export enum ACTIONS_CHECK_FORM {
    ADD_CHECK_FORM_SUCCESS = 'ADD_CHECK_FORM_SUCCESS',
    ADD_CHECK_FORM_FAILURE = 'ADD_CHECK_FORM_FAILURE',

    GET_CHECK_FORM_SUCCESS = 'GET_CHECK_FORM_SUCCESS',
    GET_CHECK_FORM_FAILURE = 'GET_CHECK_FORM_FAILURE',

    DELETE_CHECK_FORM_SUCCESS = 'DELETE_CHECK_FORM_SUCCESS',
    DELETE_CHECK_FORM_FAILURE = 'DELETE_CHECK_FORM_FAILURE',
}

export const ADD_CHECK_FORM_SUCCESS = (data:any) => ({
    type: ACTIONS_CHECK_FORM.ADD_CHECK_FORM_SUCCESS,
    data
})

export const ADD_CHECK_FORM_FAILURE = (e:any) => ({
    type: ACTIONS_CHECK_FORM.ADD_CHECK_FORM_FAILURE,
    error: e
})

export const DELETE_CHECK_FORM_SUCCESS = (data:any) => ({
    type: ACTIONS_CHECK_FORM.DELETE_CHECK_FORM_SUCCESS,
    data
})

export const DELETE_CHECK_FORM_FAILURE = (e:any) => ({
    type: ACTIONS_CHECK_FORM.DELETE_CHECK_FORM_FAILURE,
    error: e
})


export const GET_CHECK_FORM_SUCCESS = (data:any) => ({
    type: ACTIONS_CHECK_FORM.GET_CHECK_FORM_SUCCESS,
    data
})

export const GET_CHECK_FORM_FAILURE = (e:any) => ({
    type: ACTIONS_CHECK_FORM.GET_CHECK_FORM_FAILURE,
    error: e
})